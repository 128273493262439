import { Typography, styled } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { FaArrowRight, FaDatabase } from 'react-icons/fa';
import { Position } from 'reactflow';
import { NodeContainer, StyledSourceHandle } from '../../styles/nodeStyles';
import { theme } from '../../styles/theme';
import { InputDatasetNodeData } from '../../types/nodes';
import DeleteNodeButton from './DeleteNodeButton';

// TODO: update this position stuff there's probably a better way
const NodeNameTypography = styled(Typography)({
  position: 'absolute',
  top: 40,
  left: -38,
  textAlign: 'center',
  width: '8rem',
  overflowWrap: 'break-word',
  display: 'inline',
}) as typeof Typography;

export default memo((props: { data: InputDatasetNodeData }) => {
  const [datasetName, setDatasetName] = useState(
    props.data?.inputDatasetName || 'Input Dataset',
  );

  const isConnectable = useMemo(
    () =>
      datasetName != null &&
      datasetName !== '' &&
      datasetName !== 'Input Dataset',
    [datasetName],
  );

  useEffect(() => {
    setDatasetName(props.data?.inputDatasetName || 'Input Dataset');
  }, [props]);

  return (
    <div>
      <NodeContainer>
        <DeleteNodeButton nodeId={props.data.objId} />

        <FaArrowRight size="0.5rem" color={theme.palette.text.primary} />
        <FaDatabase size="2rem" color={theme.palette.text.primary} />

        <StyledSourceHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />

        <NodeNameTypography variant="caption">{datasetName}</NodeNameTypography>
      </NodeContainer>
    </div>
  );
});
