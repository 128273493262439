import {
  Box,
  Button,
  ButtonOwnProps,
  Modal,
  Typography,
  styled,
} from '@mui/material';
import React, { useMemo } from 'react';
import { BasicModalContainer } from '../../styles/commonStyles';
import { TwoMarginTypography } from '../../styles/textStyles';
import { theme } from '../../styles/theme';

const FlexEndContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
}) as typeof Box;

interface ConfirmationModalProps {
  displayText: string;
  showModal: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
  confirmButtonIcon?: React.ReactNode;
  confirmButtonText?: string;
  confirmButtonColor?: ButtonOwnProps['color'];
  cancelButtonText?: string;
  cancelButtonColor?: ButtonOwnProps['color'];
  cancelButtonIcon?: React.ReactNode;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  titleText?: string;
}

export default function ConfirmationModal({
  displayText,
  showModal,
  confirmButtonColor,
  confirmButtonText,
  confirmButtonIcon,
  cancelButtonColor,
  cancelButtonText,
  cancelButtonIcon,
  ariaDescribedBy,
  ariaLabelledBy,
  titleText,
  onClose,
  handleCancel,
  handleConfirm,
}: ConfirmationModalProps) {
  const modalIsOpen = useMemo(() => showModal, [showModal]);

  return (
    <Modal
      aria-labelledby={`${ariaLabelledBy ?? 'default-modal-label'}`}
      aria-describedby={`${ariaDescribedBy ?? 'default-modal-description'}`}
      open={modalIsOpen}
      onClose={onClose}
    >
      <BasicModalContainer>
        {titleText != null && (
          <Typography gutterBottom variant="h5">
            {titleText}
          </Typography>
        )}
        <TwoMarginTypography>{displayText}</TwoMarginTypography>

        <FlexEndContainer>
          <Button
            onClick={handleCancel}
            color={cancelButtonColor ?? 'primary'}
            startIcon={cancelButtonIcon ?? <></>}
          >
            {cancelButtonText ?? 'Cancel'}
          </Button>
          <Button
            variant="contained"
            color={confirmButtonColor ?? 'success'}
            onClick={handleConfirm}
            startIcon={confirmButtonIcon ?? <></>}
            sx={{ marginLeft: theme.spacing(1) }}
          >
            {confirmButtonText ?? 'Confirm'}
          </Button>
        </FlexEndContainer>
      </BasicModalContainer>
    </Modal>
  );
}
