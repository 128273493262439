import { Typography, styled } from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import { useRef } from 'react';
import { IconType } from 'react-icons/lib';
import { theme } from '../../styles/theme';

const height = 1.5;
const padding = 0.5;

const SwipeOutButton = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  overflow: 'hidden',

  width: 'auto',
  height: `${height}rem`,
  maxWidth: `${height}rem`,
  padding: `${padding}rem`,

  backgroundColor: theme.palette.secondary.main,
  borderRadius: `${height * 2}rem`,

  WebkitTransition: 'all 300ms',
  transition: 'all 300ms ease-in-out',
  cursor: 'pointer',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',

  '&:hover': {
    maxWidth: '20rem',
    borderRadius: theme.borderRadiusRem,
    backgroundColor: theme.palette.secondary.dark,
    paddingRight: 0,
  },
});

const SwipeOutButtonText = styled(Typography)({
  whiteSpace: 'nowrap',
  color: theme.palette.secondary.contrastText,
  fontSize: '0.875rem',
  paddingRight: `${padding}rem`,
});

interface SwipeOutButtonProps {
  label: string;
  children?: React.ReactNode;
  Icon: IconType;
  onClick: () => void;
}

const StyledTouchRipple = styled(TouchRipple)({
  height: `${height}rem`,
  position: 'relative',
  overflow: 'visible',
});

export default function SwipeOutIconButton({
  label,
  children,
  Icon,
  onClick,
}: SwipeOutButtonProps) {
  const rippleRef = useRef(null);

  const onRippleStart = (e: any) => {
    //@ts-ignore
    rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: any) => {
    //@ts-ignore
    rippleRef?.current?.stop(e);
  };

  return (
    <SwipeOutButton
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      onClick={onClick}
    >
      <Icon
        // need to to inline styles for this one since it's imported via props
        size={20}
        color={theme.palette.secondary.contrastText}
        style={{
          overflow: 'visible',
          paddingLeft: `${padding / 4}rem`,
          marginRight: `${padding * 1.25}rem`,
        }}
      />
      <SwipeOutButtonText>{label.toUpperCase()}</SwipeOutButtonText>
      <StyledTouchRipple ref={rippleRef} center={false} />
      {children != null && children}
    </SwipeOutButton>
  );
}
