import { Edge, XYPosition } from 'reactflow';
import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  MultiCovariantAnalysisNodeType,
  CustomNodeTypes,
  InputDatasetNodeType,
  OutputDatasetNodeType,
  FunctionNodeType,
  KnowledgeGraphNodeType,
  NodeTypeNames,
  SelectCategoriesNodeType,
  FilterNodeType,
  JoinNodeType,
  GroupByNodeType,
  SelectColumnsNodeType,
  AppendTablesNodeType,
} from '../../types/nodes';

import { v4 as uuidv4 } from 'uuid';

export const getNodeType = (
  node: CustomNodeTypes | undefined,
): NodeTypeNames => {
  if (node == null) {
    return NodeTypeNames.unknownNodeType;
  }

  switch (node.type) {
    case NodeTypeNames.inputDatasetNode:
      return NodeTypeNames.inputDatasetNode;
    case NodeTypeNames.outputDatasetNode:
      return NodeTypeNames.outputDatasetNode;
    case NodeTypeNames.knowledgeGraphNode:
      return NodeTypeNames.knowledgeGraphNode;
    case NodeTypeNames.selectCategoriesNode:
      return NodeTypeNames.selectCategoriesNode;
    case NodeTypeNames.selectColumnsNode:
      return NodeTypeNames.selectColumnsNode;
    case NodeTypeNames.multiCovariantAnalysisNode:
      return NodeTypeNames.multiCovariantAnalysisNode;
    case NodeTypeNames.addConstantNode:
      return NodeTypeNames.addConstantNode;
    case NodeTypeNames.functionNode:
      return NodeTypeNames.functionNode;
    case NodeTypeNames.alterColumnsNode:
      return NodeTypeNames.alterColumnsNode;
    case NodeTypeNames.filterNode:
      return NodeTypeNames.filterNode;
    case NodeTypeNames.joinNode:
      return NodeTypeNames.joinNode;
    case NodeTypeNames.groupByNode:
      return NodeTypeNames.groupByNode;
    case NodeTypeNames.appendTablesNode:
      return NodeTypeNames.appendTablesNode;
    default:
      return NodeTypeNames.unknownNodeType;
  }
};

export const updateNodesWithNewProcessFlowName = (
  nodes: CustomNodeTypes[],
  processFlowName: string,
): CustomNodeTypes[] => {
  return nodes.map(node => {
    let updatedNode = { ...node };

    if (node.data?.PK != null) {
      const pkSplit = node.data.PK.split('#');
      // PK's will always be of the structure: {clientName}#PFNAME#{processFlowName}
      // the clientName and PFNAME parts are assigned on generation so we just need to make sure we overwrite the existing process flow name at the end
      const newPK = `${pkSplit[0]}#${pkSplit[1]}#${processFlowName}`;

      updatedNode.data = {
        ...updatedNode.data,
        PK: newPK,
      };
    }

    return updatedNode;
  });
};

export const getDownstreamEdges = (nodeId: string, edges: Edge[]): Edge[] => {
  let downstreamEdges: Edge[] = [];
  // We are given the id of the node that was updated or deleted
  // Find it's edges (edge.source === nodeId)
  const connectedEdges = edges.filter((edge: Edge) => edge.source === nodeId);

  // then do this recursively for each target node affected
  connectedEdges.forEach((edge: Edge) => {
    downstreamEdges.push(edge);
    downstreamEdges = downstreamEdges.concat(
      getDownstreamEdges(edge.target, edges),
    );
  });

  return downstreamEdges;
};

export const createCustomNode = (
  nodeType: NodeTypeNames,
  position: XYPosition,
  clientName: string,
  processFlowName?: string,
) => {
  let newNode: CustomNodeTypes | undefined = undefined;
  const id = uuidv4();
  const uppercaseClientName = clientName.toUpperCase();
  const PK = `${uppercaseClientName}#PFNAME#${processFlowName ?? ''}`;

  switch (nodeType) {
    case NodeTypeNames.inputDatasetNode: {
      (newNode as unknown as InputDatasetNodeType) = {
        id,
        position,
        type: NodeTypeNames.inputDatasetNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#INPUTOBJ#${id}`,
          inputDatasetName: undefined,
          outputDatasetName: `df_${id}`,
          parquetLocation: undefined,
          fields: undefined,
          processId: 0,
          objId: id,
          nodeName: 'Input Dataset',
        },
      };
      break;
    }
    case NodeTypeNames.outputDatasetNode: {
      (newNode as unknown as OutputDatasetNodeType) = {
        id,
        position,
        type: NodeTypeNames.outputDatasetNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#OUTPUTOBJ#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          outputDatasetName: `df_${id}`,
          parquetLocation: undefined,
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Output Dataset',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.multiCovariantAnalysisNode: {
      (newNode as unknown as MultiCovariantAnalysisNodeType) = {
        id,
        position,
        type: NodeTypeNames.multiCovariantAnalysisNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#MULTICOVARIANTOBJ#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          outputDatasetName: `df_${id}`,
          parquetLocation: undefined,
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Multi-Covariant Analysis',
          inputNodeName: undefined,
          correlateTo: undefined,
          factors: [],
        },
      };
      break;
    }
    case NodeTypeNames.knowledgeGraphNode: {
      (newNode as unknown as KnowledgeGraphNodeType) = {
        id,
        position,
        type: NodeTypeNames.knowledgeGraphNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#KNOWLEDGEGRAPHOBJ#${id}`,
          inputDatasetNames: [],
          inputDatasetNodes: [],
          triples: [],
          outputDatasetName: `df_${id}`,
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Knowledge Graph',
        },
      };
      break;
    }
    case NodeTypeNames.selectCategoriesNode: {
      (newNode as unknown as SelectCategoriesNodeType) = {
        id,
        position,
        type: NodeTypeNames.selectCategoriesNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#SELECTCATEGORIESOBJ#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          selectedCategories: undefined,
          outputDatasetName: `df_${id}`,
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Select Categories',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.selectColumnsNode: {
      (newNode as unknown as SelectColumnsNodeType) = {
        id,
        position,
        type: NodeTypeNames.selectColumnsNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#SELECTCOLUMNSOBJ#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          selectedColumns: undefined,
          outputDatasetName: `df_${id}`,
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Select Columns',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.addConstantNode: {
      (newNode as unknown as AddConstantNodeType) = {
        id,
        position,
        type: NodeTypeNames.addConstantNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#CONSTANTCOLUMNOBJECT#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          fields: undefined,
          outputColumnNames: undefined,
          columnsToAdd: [],
          outputDatasetName: `df_${id}`,
          processId: -1,
          objId: id,
          nodeName: 'Add Constant',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.functionNode: {
      (newNode as unknown as FunctionNodeType) = {
        id,
        position,
        type: NodeTypeNames.functionNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#FUNCTIONOBJECT#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          outputDatasetName: `df_${id}`,
          functions: [],
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Function Node',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.alterColumnsNode: {
      (newNode as unknown as AlterColumnsNodeType) = {
        id,
        position,
        type: NodeTypeNames.alterColumnsNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#ALTERCOLUMNOBJECT#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          outputDatasetName: `df_${id}`,
          alterColumns: [],
          fields: undefined,
          outputFields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Alter Columns',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.filterNode: {
      (newNode as unknown as FilterNodeType) = {
        id,
        position,
        type: NodeTypeNames.filterNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#FILTEROBJECT#${id}`,
          inputDatasetName: undefined,
          inputObjId: undefined,
          outputDatasetName: `df_${id}`,
          filterRules: [],
          fields: undefined,
          processId: -1,
          objId: id,
          nodeName: 'Filter',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.joinNode: {
      (newNode as unknown as JoinNodeType) = {
        id,
        position,
        type: NodeTypeNames.joinNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#JOINOBJECT#${id}`,
          outputDatasetName: `df_${id}`,
          fields: undefined,
          processId: -1,
          objId: id,
          leftTableNameObjId: undefined,
          rightTableNameObjId: undefined,
          leftTableName: undefined,
          rightTableName: undefined,
          joinType: undefined,
          leftTableKeyFields: undefined,
          rightTableKeyFields: undefined,
          leftTableFieldList: undefined,
          rightTableFieldList: undefined,
          tableToFix: undefined,
          columnsToFix: [],
          outputFieldList: [],
          nodeName: 'Join',
        },
      };
      break;
    }
    case NodeTypeNames.groupByNode: {
      (newNode as unknown as GroupByNodeType) = {
        id,
        position,
        type: NodeTypeNames.groupByNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#GROUPBYOBJECT#${id}`,
          outputDatasetName: `df_${id}`,
          fields: undefined,
          processId: -1,
          objId: id,
          groupByCalcs: [],
          groupByCols: [],
          outputFields: [],
          inputObjId: undefined,
          nodeName: 'Group By',
          inputNodeName: undefined,
        },
      };
      break;
    }
    case NodeTypeNames.appendTablesNode: {
      (newNode as unknown as AppendTablesNodeType) = {
        id,
        position,
        type: NodeTypeNames.appendTablesNode,
        data: {
          PK,
          SK: `${uppercaseClientName}#APPENDOBJECT#${id}`,
          outputDatasetName: `df_${id}`,
          fields: undefined,
          processId: -1,
          objId: id,
          inputsMatch: false,
          inputDatasetObjIds: [],
          inputs: [],
          nodeName: 'Append Tables',
        },
      };
      break;
    }
    default: {
      newNode = undefined;
      break;
    }
  }

  return newNode;
};
