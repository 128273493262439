import { CircularProgress, Toolbar, Typography } from '@mui/material';
import { memo } from 'react';
import { SiMusicbrainz } from 'react-icons/si';
import {
  FlexGrowCenteredColumn,
  FullSizeColumnFlex,
  StyledAppBar,
} from '../styles/commonStyles';
import { theme } from '../styles/theme';

export interface LoadingScreenProps {
  loadingText: string;
}

export default memo(({ loadingText }: LoadingScreenProps) => {
  return (
    <FullSizeColumnFlex>
      <StyledAppBar position="static">
        <Toolbar sx={{ justifyContent: 'start' }}>
          <Typography color={theme.palette.primary.contrastText}>
            RYAILITI
          </Typography>
          <SiMusicbrainz />
        </Toolbar>
      </StyledAppBar>
      <FlexGrowCenteredColumn>
        <CircularProgress
          color="primary"
          size="6rem"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <Typography variant="h4" color="primary" fontWeight={500}>
          {loadingText}
        </Typography>
      </FlexGrowCenteredColumn>
    </FullSizeColumnFlex>
  );
});
