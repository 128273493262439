import {
  IconButton,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  FormHelperText,
  styled,
  Box,
} from '@mui/material';
import { memo, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { theme } from '../../../styles/theme';

const StyledContainer = styled(Box)({
  gridTemplateColumns: 'repeat(3, minmax(9.375rem, 1fr)) 3rem',
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

const StyledTextField = styled(TextField)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof TextField;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  minWidth: '2rem',
  maxWidth: '3rem',
}) as typeof IconButton;

const getHelperText = (columnFormat: string): string => {
  switch (columnFormat) {
    case 'string':
      return 'Ex: abcd';
    case 'integer':
      return 'Ex: 1, 2, 45, 177';
    case 'decimal':
      return '1.23, 658.2, 25.75';
    case 'date':
      return 'Format: MM-DD-YYYY Ex: 07-02-1996';
    default:
      return 'Pick a format first';
  }
};

export interface AddColumnConstantFormProps {
  name: string;
  value: string;
  format: string;
  id: string;
  handleUpdate: (
    id: string,
    type: 'name' | 'format' | 'value',
    newValue: string,
  ) => void;
  handleDelete: (id: string) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({
    name,
    value,
    format,
    id,
    handleUpdate,
    handleDelete,
  }: AddColumnConstantFormProps) => {
    const columnName = useMemo(() => name, [name]);
    const columnFormat = useMemo(() => format, [format]);
    const columnValue = useMemo(() => value, [value]);
    const valueHelperText = useMemo(
      () => getHelperText(columnFormat),
      [columnFormat],
    );

    return (
      <StyledContainer>
        <StyledTextField
          variant="outlined"
          label="Column Name"
          placeholder="Column Name"
          value={columnName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleUpdate(id, 'name', event.target.value);
          }}
        />

        <StyledFormControl>
          <InputLabel id="column-format-select-label">Column Format</InputLabel>
          <Select
            labelId="column-format-select-label"
            id="column-format-select"
            label="Column Format"
            value={columnFormat}
            onChange={(event: SelectChangeEvent) => {
              handleUpdate(id, 'format', event.target.value);
            }}
          >
            <MenuItem value={'string'}>String</MenuItem>
            <MenuItem value={'integer'}>Integer</MenuItem>
            <MenuItem value={'decimal'}>Decimal</MenuItem>
            <MenuItem value={'date'}>Date</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <TextField
            variant="outlined"
            label="Column Value"
            placeholder="Column Value"
            value={columnValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdate(id, 'value', event.target.value);
            }}
          />
          <FormHelperText>{valueHelperText}</FormHelperText>
        </StyledFormControl>

        <StyledIconButton
          aria-label="delete"
          className="delete-node-button"
          onClick={() => {
            handleDelete(id);
          }}
        >
          <FaTrash
            size="2rem"
            color={theme.palette.error.main}
            style={{ display: 'inherit' }}
          />
        </StyledIconButton>
      </StyledContainer>
    );
  },
);
