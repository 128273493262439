import { Box, styled } from '@mui/material';
import DatalakeHeader from '../components/datalake/header';
import DatalakeTable from '../components/datalake/table';
import { theme } from '../styles/theme';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDatalakeState } from '../state/datalakeState';
import { useReportsState } from '../state/reportsState';
import DatalakeFolders from '../components/datalake/folders';

const DatalakePageContainer = styled(Box)({
  paddingTop: theme.defaultPaddingRem,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
}) as typeof Box;

const DatalakePageBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}) as typeof Box;

const DatalakePage = () => {
  let location = useLocation();
  const { datalakeTableQueryParams, setDatalakeTableQueryParams } =
    useDatalakeState();
  const { clearReportsState } = useReportsState();
  const [searchParams, setSearchParams] = useSearchParams();

  // save the currently applied table filters (query params) to the datalake state so we can apply them when the user navigates back here
  useEffect(() => {
    setDatalakeTableQueryParams(location.search);
  }, [location, setDatalakeTableQueryParams]);

  // apply any saved table filters (via query params) on page load
  useEffect(() => {
    // if there are no saved datalakeTableQueryParams, check if there are any query params in the url (searchParams) and apply those instead
    setSearchParams(
      datalakeTableQueryParams === '' ? searchParams : datalakeTableQueryParams,
    );
    clearReportsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatalakePageContainer>
      <DatalakePageBody>
        <DatalakeHeader />
        <DatalakeFolders />
        <DatalakeTable />
      </DatalakePageBody>
    </DatalakePageContainer>
  );
};

export default DatalakePage;
