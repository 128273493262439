import { Box, Button, TextField, styled } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModelingState } from '../../state/modelingState';
import { useUserState } from '../../state/userState';
import { ColumnFlex, FlexBox } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import { MarginRightButton } from '../../styles/inputStyles';

const MenuContainer = styled(Box)({
  display: 'flex',
  backgroundColor: theme.palette.surface.default,
  width: `calc(100% - ${theme.defaultPaddingRem})`,
  height: 'fit-content',
  padding: theme.defaultPaddingRem,
  justifyContent: 'space-between',
  alignItems: 'center',
}) as typeof Box;

export default function ProcessFlowMenu() {
  const { onProcessFlowSave, selectedProcessFlow, setSelectedProcessFlow } =
    useModelingState();
  const { userProfile } = useUserState();
  const navigate = useNavigate();

  const [processFlowName, setProcessFlowName] = useState(
    selectedProcessFlow?.name || '',
  );

  useEffect(() => {
    if (selectedProcessFlow?.name != null) {
      setProcessFlowName(selectedProcessFlow.name);
    }
  }, [selectedProcessFlow]);

  const handleCancelClick = useCallback(() => {
    setSelectedProcessFlow(undefined);
    navigate('/modeling');
  }, [setSelectedProcessFlow, navigate]);

  const handleProcessFlowSaveClick = useCallback(
    (runProcessFlow: boolean) => {
      if (
        selectedProcessFlow != null &&
        userProfile != null &&
        processFlowName.length > 0
      ) {
        onProcessFlowSave(
          selectedProcessFlow,
          processFlowName,
          userProfile.username,
          runProcessFlow,
        );

        if (runProcessFlow) {
          navigate('/modeling');
        }
      }
    },
    [
      selectedProcessFlow,
      processFlowName,
      onProcessFlowSave,
      userProfile,
      navigate,
    ],
  );

  return (
    <MenuContainer>
      <ColumnFlex>
        <TextField
          variant="filled"
          size="medium"
          label="Process Flow Name"
          value={processFlowName}
          placeholder="New Process Flow"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setProcessFlowName(event.target.value);
          }}
        />
      </ColumnFlex>
      <FlexBox>
        <MarginRightButton onClick={handleCancelClick}>
          Cancel
        </MarginRightButton>
        <MarginRightButton
          color="success"
          variant="contained"
          onClick={() => handleProcessFlowSaveClick(false)}
        >
          Save
        </MarginRightButton>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleProcessFlowSaveClick(true)}
        >
          Save & Run
        </Button>
      </FlexBox>
    </MenuContainer>
  );
}
