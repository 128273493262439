import { MultiCovariantAnalysisNodeType } from '../../types/nodes';

// ====================== BIOMIMETIC ENGINE AS TARGET ======================

export const disconnectNodeFromMultiCovariantAnalysis = <Type>(
  sourceNode: Type,
  targetNode: MultiCovariantAnalysisNodeType,
): [Type, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
    fields: undefined,
  };

  return [sourceNode, updatedTargetNode];
};
