import { FormLabel, Typography, styled } from '@mui/material';
import { theme } from './theme';

export const TwoMarginTypography = styled(Typography)({
  marginBottom: theme.spacing(2),
}) as typeof Typography;

export const GutteredFormLabel = styled(FormLabel)({
  marginBottom: theme.spacing(1),
}) as typeof FormLabel;
