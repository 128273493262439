import { Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useModelingState } from '../../../state/modelingState';
import { theme } from '../../../styles/theme';
import { Edge, KnowledgeGraphNodeType, Triple } from '../../../types/nodes';
import AddTripleForm from './AddTripleForm';

import { MdAddCircle } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../../styles/nodeStyles';
import {
  FitContentButton,
  MarginRightButton,
} from '../../../styles/inputStyles';

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedKnowledgeGraphNode = useMemo(
    () => selectedNode as KnowledgeGraphNodeType | undefined,
    [selectedNode],
  );
  const availableDatasetNodes = useMemo(
    () =>
      selectedKnowledgeGraphNode != null &&
      selectedKnowledgeGraphNode.data?.inputDatasetNodes != null
        ? selectedKnowledgeGraphNode.data.inputDatasetNodes
        : undefined,
    [selectedKnowledgeGraphNode],
  );

  const [nodeName, setNodeName] = useState(
    selectedKnowledgeGraphNode != null
      ? selectedKnowledgeGraphNode.data.nodeName
      : '',
  );
  const [triples, setTriples] = useState<Triple[]>(
    selectedKnowledgeGraphNode != null
      ? selectedKnowledgeGraphNode.data.triples
      : [],
  );

  const handleOnSave = useCallback(
    (savedSelectedNode: KnowledgeGraphNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === savedSelectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          //only grab triples that are valid
          const updatedTriples = triples.filter(
            triple =>
              triple.entityA.trim() !== '' &&
              triple.entityB.trim() !== '' &&
              triple.edges.length > 0,
          );

          let updatedSelectedNode = { ...savedSelectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            triples: [...updatedTriples],
            nodeName: nodeName.trim(),
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(savedSelectedNode.id);
      setSelectedNode(undefined);
      setTriples([]);
      setNodeName('');
    },
    [
      setSelectedNode,
      setNodes,
      onNodeSave,
      setTriples,
      setNodeName,
      nodes,
      triples,
      nodeName,
    ],
  );

  const handleUpdateEntity = useCallback(
    (id: string, type: 'entityA' | 'entityB', newValue: string) => {
      let updatedTriples = [...triples];
      const indexToUpdate = updatedTriples.findIndex(
        (triple: Triple) => triple.id === id,
      );

      if (indexToUpdate >= 0) {
        let tripleToUpdate = updatedTriples[indexToUpdate];

        if (type === 'entityA') {
          tripleToUpdate.entityA = newValue;
        }
        if (type === 'entityB') {
          tripleToUpdate.entityB = newValue;
        }

        updatedTriples[indexToUpdate] = tripleToUpdate;
      }
      setTriples([...updatedTriples]);
    },
    [triples, setTriples],
  );

  const handleUpdateEdge = useCallback(
    (
      tripleId: string,
      edgeId: string,
      type: 'columnA' | 'columnB',
      newValue: string,
    ) => {
      let updatedTriples = [...triples];
      const tripleIndexToUpdate = updatedTriples.findIndex(
        (triple: Triple) => triple.id === tripleId,
      );

      if (tripleIndexToUpdate >= 0) {
        let tripleToUpdate = triples[tripleIndexToUpdate];
        const edgeIndexToUpdate = tripleToUpdate.edges.findIndex(
          edge => edge.id === edgeId,
        );

        if (edgeIndexToUpdate >= 0) {
          let edgeToUpdate = tripleToUpdate.edges[edgeIndexToUpdate];

          if (type === 'columnA') {
            edgeToUpdate.columnAName = newValue;
          }
          if (type === 'columnB') {
            edgeToUpdate.columnBName = newValue;
          }

          tripleToUpdate.edges[edgeIndexToUpdate] = edgeToUpdate;
        }

        updatedTriples[tripleIndexToUpdate] = tripleToUpdate;
      }
      setTriples([...updatedTriples]);
    },
    [triples, setTriples],
  );

  const handleDeleteTriple = useCallback(
    (id: string) => {
      const indexToDelete = triples.findIndex(triple => triple.id === id);

      if (indexToDelete >= 0) {
        let updatedTriples = [...triples];
        updatedTriples.splice(indexToDelete, 1);
        setTriples([...updatedTriples]);
      }
    },
    [triples, setTriples],
  );

  const handleDeleteEdge = useCallback(
    (tripleId: string, edgeId: string) => {
      const tripleIndex = triples.findIndex(triple => triple.id === tripleId);

      if (tripleIndex >= 0) {
        const selectedTriple = triples[tripleIndex];
        const edgeIndexToDelete = selectedTriple.edges.findIndex(
          edge => edge.id === edgeId,
        );

        if (edgeIndexToDelete >= 0) {
          let updatedTriples = [...triples];
          let updatedEdges = [...selectedTriple.edges];

          updatedEdges.splice(edgeIndexToDelete, 1);
          updatedTriples[tripleIndex].edges = [...updatedEdges];

          setTriples([...updatedTriples]);
        }
      }
    },
    [triples, setTriples],
  );

  const handleAddNewTriple = useCallback(() => {
    const newTriple: Triple = {
      id: uuidv4(),
      entityA: '',
      entityB: '',
      edges: [],
    };
    setTriples([...triples].concat(newTriple));
  }, [triples, setTriples]);

  const handleAddNewEdge = useCallback(
    (tripleId: string) => {
      const tripleIndex = triples.findIndex(triple => triple.id === tripleId);

      if (tripleIndex >= 0) {
        let updatedTriples = [...triples];
        const newEdge: Edge = {
          id: uuidv4(),
          columnAName: '',
          columnBName: '',
        };

        updatedTriples[tripleIndex].edges.push(newEdge);
        setTriples([...updatedTriples]);
      }
    },
    [triples, setTriples],
  );

  // If there are no triples, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedKnowledgeGraphNode != null && triples.length === 0) {
      handleAddNewTriple();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKnowledgeGraphNode, triples]);

  if (
    selectedKnowledgeGraphNode != null &&
    availableDatasetNodes != null &&
    availableDatasetNodes.length > 1
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Create Knowledge Graph
          </Typography>

          <NodeNameTextField
            variant="outlined"
            label="Node Name"
            placeholder="Node Name"
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <Typography variant="h6">Triples:</Typography>
        <ScrollableContentContainer>
          {triples.map(triple => {
            return (
              <AddTripleForm
                {...triple}
                availableDatasets={availableDatasetNodes}
                handleDelete={handleDeleteTriple}
                handleUpdateEntity={handleUpdateEntity}
                handleDeleteEdge={handleDeleteEdge}
                handleUpdateEdge={handleUpdateEdge}
                handleAddNewEdge={handleAddNewEdge}
                key={`${triple.id}`}
              />
            );
          })}

          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewTriple}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
          >
            Add Triple
          </FitContentButton>
        </ScrollableContentContainer>

        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedKnowledgeGraphNode)}
          >
            Save
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    return (
      <NodeMenuErrorContainer>
        {(availableDatasetNodes == null ||
          availableDatasetNodes.length <= 0) && (
          <Typography variant="h5" gutterBottom>
            There are no available datasets to select. Did you connect an input
            node?
          </Typography>
        )}
        {availableDatasetNodes != null &&
          availableDatasetNodes.length > 0 &&
          availableDatasetNodes.length <= 1 && (
            <Typography variant="h5" gutterBottom>
              You must have at least two input datasets connected.
            </Typography>
          )}
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
