import { format } from 'date-fns';

export const removeTimezoneFromDate = (date: string | Date): Date => {
  const newDate = new Date(date);
  const tzoffset = newDate.getTimezoneOffset() * 60000; //offset in milliseconds
  const withoutTimezone = new Date(newDate.valueOf() + tzoffset);

  return withoutTimezone;
};

export const formateDateWithoutTimezone = (
  date: string | Date,
  formatString: string,
): string => {
  const withoutTimezone = removeTimezoneFromDate(date);

  return format(withoutTimezone, formatString);
};
