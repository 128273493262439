import Plot from '@observablehq/plot';
import { encodeString, QueryParamConfig } from 'use-query-params';
import { Report } from './reports';

export type PlotTypes = 'bar' | 'dot' | 'line' | 'area';

export const PLOT_TYPES_ARRAY: PlotTypes[] = ['bar', 'dot', 'line', 'area'];

export type GroupByType = Plot.Transformed<{
  x: string;
  y: string;
  fill: string;
}>;

export type AxesDataType = { x: string; y: string; fill: string };

export const setUpQueryParams = (report: Report): string => {
  const { xAxis, yAxis, name } = report;

  const usesXAxisReducer = report.xAxisReducer;
  const usesYAxisReducer = report.yAxisReducer;

  // the axis is using a reducer so assign the axis to the reducer variable
  const xAxisReducer = usesXAxisReducer ? xAxis : '';
  const yAxisReducer = usesYAxisReducer ? yAxis : '';

  // the axis is not using a reducer so assign the axis to the field name varialbe
  const xAxisFieldName = usesXAxisReducer ? '' : xAxis;
  const yAxisFieldName = usesYAxisReducer ? '' : yAxis;

  return `?xAxis=${xAxis}&yAxis=${yAxis}&xAxisReducer=${xAxisReducer}&yAxisReducer=${yAxisReducer}&xAxisFieldName=${xAxisFieldName}&yAxisFieldName=${yAxisFieldName}&name=${name}`;
};

// custom function telling use-query-params how to decode the axis param so typescript is happy
function decodePlotQueryParam(
  axis: string | (string | null)[] | null | undefined,
): string {
  if (axis == null) return '';

  if (typeof axis === typeof [''] || typeof axis === typeof [null]) return '';

  if (typeof axis === typeof '') return axis as string;

  return '';
}

export const PlotQueryParam: QueryParamConfig<string> = {
  encode: (axis: string | null | undefined) => encodeString(axis),
  decode: (axis: string | (string | null)[] | null | undefined) =>
    decodePlotQueryParam(axis),
};
