import { ScaleOptions } from '@observablehq/plot';
import { theme } from './theme';

export const PLOT_LEFT_MARGIN = 96;

export const plotColorRange = [
  theme.palette.primary.main,
  theme.palette.secondary.main,
  theme.palette.success.main,
  theme.palette.info.main,
  theme.palette.warning.main,
  theme.palette.primary.light,
  theme.palette.secondary.light,
  theme.palette.success.light,
  theme.palette.info.light,
  theme.palette.warning.light,
  theme.palette.primary.dark,
  theme.palette.secondary.dark,
  theme.palette.success.dark,
  theme.palette.info.dark,
  theme.palette.warning.dark,
];

export const standardPlotColor: ScaleOptions = {
  legend: true,
  type: 'categorical',
  range: plotColorRange,
};
