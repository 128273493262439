import { Box, Typography, alpha, styled } from '@mui/material';
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';
import DataGridHeader from '../../components/dataGrid/header';
import { useReportsState } from '../../state/reportsState';
import { StyledSkeleton, WarningMessageBox } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import BreadCrumbNavigator from '../Navigation/breadcrumbNav';

const numberOfToolbarSkeletons = 4;
const toolbarSkeletonWidth = '6vw';

const DataGridBodyContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}) as typeof Box;

const SkeletonsContainer = styled(Box)({
  display: 'grid',
  height: 'fit-content',
  width: '100%',
  rowGap: theme.spacing(1),
}) as typeof Box;

const ToolbarSkeletonsContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: `repeat(${numberOfToolbarSkeletons}, ${toolbarSkeletonWidth})`,
  columnGap: theme.spacing(3),
  width: '100%',
  height: 'fit-content',
}) as typeof Box;

const ODD_OPACITY = 0.2;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  width: 'inherit',
  overflow: 'scroll',
  [`& .${gridClasses.row}.even`]: { backgroundColor: theme.palette.grey[200] },
  [`& .${gridClasses.row}.even, & .${gridClasses.row}.odd`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

export default function DataGridPageBody() {
  // these are all set in the reports body component and used here if the user navigates to the datagrid
  const { selectedData, selectedDataColumns, selectedFileName } =
    useReportsState();

  const updateDataGridState = useCallback((): number => {
    if (
      selectedData == null ||
      selectedDataColumns == null ||
      selectedFileName == null
    ) {
      return 0;
    }

    if (selectedDataColumns != null && selectedData != null) {
      // clean up the nav state once the page data loads
      window.history.replaceState({}, '');
      return selectedDataColumns.length > 0 ? 1 : 2;
    }

    return -1;
  }, [selectedData, selectedDataColumns, selectedFileName]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const dataGridState = useMemo(
    () => updateDataGridState(),
    [updateDataGridState],
  );

  const dataGridSkeletons = Array.from({ length: 15 }, (_, index) => (
    <StyledSkeleton
      key={index}
      variant="rectangular"
      animation="wave"
      height="2.5rem"
    />
  ));

  const dataGridToolbarSkeletons = Array.from(
    { length: numberOfToolbarSkeletons },
    (_, index) => (
      <StyledSkeleton
        key={index}
        variant="rectangular"
        animation="wave"
        height="1.5rem"
        width={toolbarSkeletonWidth}
      />
    ),
  );

  // Loading
  if (dataGridState === 0) {
    return (
      <DataGridBodyContainer>
        <SkeletonsContainer>
          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="4rem"
            width="55vw"
          />
          <ToolbarSkeletonsContainer>
            {dataGridToolbarSkeletons}
          </ToolbarSkeletonsContainer>
          {dataGridSkeletons}
        </SkeletonsContainer>
      </DataGridBodyContainer>
    );
  }

  // Good data
  if (
    selectedDataColumns != null &&
    selectedData != null &&
    selectedFileName != null &&
    dataGridState === 1
  )
    return (
      <DataGridBodyContainer>
        <BreadCrumbNavigator
          breadcrumbs={[
            { text: 'Datalake', href: '/datalake' },
            { text: 'Reports', href: `/datalake/${selectedFileName}/reports` },
            { text: 'Data Grid', href: '' },
          ]}
        />
        <DataGridHeader fileName={selectedFileName} />
        <StyledDataGrid
          rows={selectedData}
          columns={selectedDataColumns}
          slots={{ toolbar: GridToolbar }}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
        />
      </DataGridBodyContainer>
    );

  // Data is valid but empty
  if (dataGridState === 2) {
    return (
      <WarningMessageBox>
        <Typography
          variant="h5"
          textAlign="center"
          color={theme.palette.primary.main}
        >
          There was no data found for this dataset.
        </Typography>
      </WarningMessageBox>
    );
  }

  // Error with the data
  return (
    <WarningMessageBox>
      <Typography
        variant="h5"
        textAlign="center"
        color={theme.palette.primary.main}
      >
        There was an error getting your data. Refresh to try again.
      </Typography>
    </WarningMessageBox>
  );
}
