import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { theme } from '../../styles/theme';

const StyledList = styled(List)({
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr 1fr',
  height: 'fit-content',
  paddingRight: theme.defaultPaddingRem,
  paddingLeft: theme.defaultPaddingRem,
  alignItems: 'center',
}) as typeof List;

export interface SidebarSectionProps {
  label: string;
  children: React.ReactNode;
}

export default function SidebarSection(props: SidebarSectionProps) {
  const [expanded, setExpanded] = useState(true);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={props.label} />
        {expanded ? (
          <FaChevronUp color={theme.palette.primary.main} />
        ) : (
          <FaChevronDown color={theme.palette.primary.main} />
        )}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <StyledList component="div" disablePadding>
          {props.children}
        </StyledList>
      </Collapse>
    </>
  );
}
