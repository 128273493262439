import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useModelingState } from '../../../state/modelingState';
import { theme } from '../../../styles/theme';
import { ColumnFixType, JoinNodeType } from '../../../types/nodes';

import {
  MdJoinFull,
  MdJoinInner,
  MdJoinLeft,
  MdJoinRight,
} from 'react-icons/md';
import { ColumnFlex } from '../../../styles/commonStyles';
import FixColumnForm from './FixColumnForm';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../../styles/nodeStyles';
import { MarginRightButton } from '../../../styles/inputStyles';

const TableNamesContainer = styled(Box)({
  gridTemplateColumns: 'repeat(3, minmax(9.375rem, 1fr))',
  display: 'grid',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const WordBreakTypography = styled(Typography)({
  wordBreak: 'break-all',
}) as typeof Typography;

const FlexFormControl = styled(FormControl)({
  display: 'flex',
}) as typeof FormControl;

const FullFlexFormControl = styled(FlexFormControl)({
  flex: 1,
}) as typeof FlexFormControl;

const SelectButtonsContainer = styled(Box)({
  display: 'flex',
  width: 'inherit',
  gap: theme.spacing(1),
}) as typeof Box;

const FixColumnsContainer = styled(Box)({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}) as typeof Box;

const ColumnsToFixContainer = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(1),
}) as typeof Box;

const SelectKeyColumnsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.defaultPaddingRem,
  height: 'inherit',
  width: 'inherit',
  overflowY: 'scroll',
}) as typeof Box;

const ColumnsSelectContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1),
  flex: 1,
}) as typeof Box;

const SelectKeyColumnsContent = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '49.5% 49.5%',
  maxWidth: '100%',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}) as typeof Box;

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedJoinNode = useMemo(
    () => selectedNode as JoinNodeType | undefined,
    [selectedNode],
  );
  const [joinType, setJoinType] = useState(
    selectedJoinNode?.data?.joinType ?? 'FULL OUTER JOIN',
  );
  const [selectedLeftColumns, setSelectedLeftColumns] = useState(
    selectedJoinNode?.data?.leftTableKeyFields ?? [],
  );
  const [selectedRightColumns, setSelectedRightColumns] = useState(
    selectedJoinNode?.data?.rightTableKeyFields ?? [],
  );
  const [tableToFix, setTableToFix] = useState(
    selectedJoinNode?.data?.tableToFix ?? 'rightTable',
  );
  const [columnsToFix, setColumnsToFix] = useState<ColumnFixType[]>(
    selectedJoinNode?.data?.columnsToFix ?? [],
  );
  const [leftExclusiveFields, setLeftExclusiveFields] = useState<string[]>([]);
  const [rightExclusiveFields, setRightExclusiveFields] = useState<string[]>(
    [],
  );
  const [matchingFields, setMatchingFields] = useState<string[]>([]);
  const [nodeName, setNodeName] = useState(
    selectedJoinNode?.data.nodeName || '',
  );

  const leftInputValid = useMemo(
    () =>
      selectedJoinNode != null &&
      selectedJoinNode.data?.leftTableNameObjId != null &&
      selectedJoinNode.data?.leftTableName != null &&
      selectedJoinNode.data?.leftTableFieldList != null &&
      selectedJoinNode.data?.leftTableKeyFields != null,

    [selectedJoinNode],
  );
  const rightInputValid = useMemo(
    () =>
      selectedJoinNode != null &&
      selectedJoinNode.data?.rightTableNameObjId != null &&
      selectedJoinNode.data?.rightTableName != null &&
      selectedJoinNode.data?.rightTableFieldList != null &&
      selectedJoinNode.data?.rightTableKeyFields != null,

    [selectedJoinNode],
  );
  // this is essentially an Exclusive OR (XOR)
  const onlyOneInputValid = useMemo(
    () =>
      (leftInputValid && !rightInputValid) ||
      (!leftInputValid && rightInputValid),
    [leftInputValid, rightInputValid],
  );

  // splits the array into two arrays based on a pass/fail function
  const partition = (array: string[], isValid: (arg0: string) => any) => {
    return array.reduce(
      ([pass, fail]: string[][], elem) => {
        return isValid(elem)
          ? [[...pass, elem], fail]
          : [pass, [...fail, elem]];
      },
      [[], []],
    );
  };

  const handleOnSave = useCallback(
    (savedSelectedNode: JoinNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === savedSelectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          let updatedSelectedNode = { ...savedSelectedNode };
          let outputColumns = [...matchingFields];

          columnsToFix.forEach(columnToFix => {
            // if there are any renamed columns we want to add those to the output
            if (columnToFix.fixType === 'rename') {
              outputColumns.push(columnToFix.columnRename);
            }
          });

          // inner join is the only join type that doesn't use the exclusive fields
          if (joinType !== 'INNER JOIN') {
            outputColumns = outputColumns
              .concat(leftExclusiveFields)
              .concat(rightExclusiveFields);
          }

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            joinType,
            leftTableKeyFields: [...selectedLeftColumns],
            rightTableKeyFields: [...selectedRightColumns],
            tableToFix,
            columnsToFix: [...columnsToFix],
            outputFieldList: [...outputColumns],
            nodeName: nodeName.trim(),
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;
          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(savedSelectedNode.id);
      setSelectedNode(undefined);
      setSelectedLeftColumns([]);
      setSelectedRightColumns([]);
      setLeftExclusiveFields([]);
      setRightExclusiveFields([]);
      setMatchingFields([]);
      setColumnsToFix([]);
      setJoinType('FULL OUTER JOIN');
      setNodeName('');
    },
    [
      nodes,
      onNodeSave,
      setSelectedNode,
      matchingFields,
      columnsToFix,
      joinType,
      selectedLeftColumns,
      selectedRightColumns,
      tableToFix,
      nodeName,
      setNodes,
      leftExclusiveFields,
      rightExclusiveFields,
    ],
  );

  const handleUpdateColumnToFix = useCallback(
    (columnName: string, type: 'rename' | 'type', newValue: string) => {
      let updatedColumns = [...columnsToFix];
      const columnIndexToUpdate = updatedColumns.findIndex(
        (fixedColumn: ColumnFixType) => fixedColumn.columnName === columnName,
      );

      if (columnIndexToUpdate >= 0) {
        let columnToUpdate = columnsToFix[columnIndexToUpdate];

        if (type === 'rename') {
          columnToUpdate.columnRename = newValue;
        }
        if (type === 'type') {
          const newFixType = newValue as 'drop' | 'rename';
          columnToUpdate.fixType = newFixType;

          // if we're dropping this column, reset the rename to the original column name
          if (newFixType === 'drop') {
            columnToUpdate.columnRename = columnToUpdate.columnName;
          } else {
            //if we're renaming the column, append the table (left or right) to the start of the column name
            columnToUpdate.columnRename = `${
              tableToFix === 'leftTable' ? 'LEFT' : 'RIGHT'
            }_${columnToUpdate.columnName}`;
          }
        }

        updatedColumns[columnIndexToUpdate] = columnToUpdate;
      }
      setColumnsToFix([...updatedColumns]);
    },
    [columnsToFix, tableToFix, setColumnsToFix],
  );

  const createColumnToFix = (columnName: string): ColumnFixType => {
    return { columnName, columnRename: columnName, fixType: 'drop' };
  };

  const updateColumnsToFix = useCallback(
    (fields: string[]) => {
      let newColumnsToFix: ColumnFixType[] = [];

      fields.forEach(field => {
        // if the columnName is not already in the columnsToFix
        if (
          columnsToFix.findIndex(
            columnToFix => columnToFix.columnName === field,
          ) < 0
        ) {
          newColumnsToFix.push(createColumnToFix(field));
        }
      });

      setColumnsToFix(columnsToFix.concat(newColumnsToFix));
    },
    [columnsToFix],
  );

  // filter the columns on each table to be used later for the join operations
  useEffect(() => {
    if (
      selectedJoinNode != null &&
      selectedJoinNode.data?.leftTableFieldList != null &&
      selectedJoinNode.data?.rightTableFieldList != null
    ) {
      const leftColumns = [...selectedJoinNode.data.leftTableFieldList];
      const rightColumns = [...selectedJoinNode.data.rightTableFieldList];

      const [leftExclusive, matching] = partition(
        leftColumns,
        leftColumnName =>
          rightColumns.findIndex(
            rightColumnName => leftColumnName === rightColumnName,
          ) < 0,
      );

      const rightExclusive = rightColumns.filter(
        rightColumnName =>
          leftColumns.findIndex(
            leftColumnName => rightColumnName === leftColumnName,
          ) < 0,
      );

      setLeftExclusiveFields(leftExclusive);
      setRightExclusiveFields(rightExclusive);
      setMatchingFields(matching);
      updateColumnsToFix(matching);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJoinNode]);

  const handleRightSelectAll = useCallback(
    (availableRightFields: string[]) => {
      setSelectedRightColumns([...availableRightFields]);
    },
    [setSelectedRightColumns],
  );

  const handleRightDeselectAll = useCallback(() => {
    setSelectedRightColumns([]);
  }, [setSelectedRightColumns]);

  const handleLeftSelectAll = useCallback(
    (availableLeftFields: string[]) => {
      setSelectedLeftColumns([...availableLeftFields]);
    },
    [setSelectedLeftColumns],
  );

  const handleLeftDeselectAll = useCallback(() => {
    setSelectedLeftColumns([]);
  }, [setSelectedLeftColumns]);

  const handleRenameAll = useCallback(() => {
    columnsToFix.forEach(columnToFix =>
      handleUpdateColumnToFix(columnToFix.columnName, 'type', 'rename'),
    );
  }, [columnsToFix, handleUpdateColumnToFix]);

  const handleDropAll = useCallback(() => {
    columnsToFix.forEach(columnToFix =>
      handleUpdateColumnToFix(columnToFix.columnName, 'type', 'drop'),
    );
  }, [columnsToFix, handleUpdateColumnToFix]);

  const handleUpdateColumnRenames = useCallback(() => {
    columnsToFix.forEach(columnToFix => {
      // only update columns that are set to rename AND have a rename value that has not been changed
      if (
        columnToFix.fixType === 'rename' &&
        (columnToFix.columnRename === `LEFT_${columnToFix.columnName}` ||
          columnToFix.columnRename === `RIGHT_${columnToFix.columnName}`)
      ) {
        handleUpdateColumnToFix(columnToFix.columnName, 'type', 'rename');
      }
    });
  }, [columnsToFix, handleUpdateColumnToFix]);

  useEffect(() => {
    // if the table to fix changes, we want to make sure to update the default column rename value
    handleUpdateColumnRenames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableToFix]);

  if (
    selectedJoinNode != null &&
    leftInputValid &&
    rightInputValid &&
    selectedJoinNode.data?.leftTableFieldList != null &&
    selectedJoinNode.data?.rightTableFieldList != null
  ) {
    return (
      <NodeMenuContainer>
        {/* =============== HEADER =============== */}
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Join Data
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        {/* =============== TABLE NAMES =============== */}
        <TableNamesContainer>
          {/* =============== LEFT TABLE NAME =============== */}
          <ColumnFlex>
            {selectedJoinNode.data.leftTableName != null && (
              <Typography variant="subtitle1" flexWrap="wrap" fontWeight="bold">
                Left Table Name:
              </Typography>
            )}
            {selectedJoinNode.data.leftTableName != null && (
              <WordBreakTypography variant="subtitle1" flexWrap="wrap">
                {selectedJoinNode.data.leftTableName}
              </WordBreakTypography>
            )}
          </ColumnFlex>
          {/* =============== SELECT JOIN =============== */}
          <FlexFormControl>
            <InputLabel id="join-type-select-label">Join Type</InputLabel>
            <Select
              labelId="join-type-select-label"
              id="join-type-select"
              label="Join Type"
              value={joinType}
              renderValue={selected => selected}
              onChange={(event: SelectChangeEvent<typeof joinType>) => {
                setJoinType(event.target.value as typeof joinType);
              }}
            >
              <MenuItem value={'FULL OUTER JOIN'}>
                <MdJoinFull
                  size="2rem"
                  color={theme.palette.text.primary}
                  style={{ marginRight: theme.spacing(1) }}
                />
                <ListItemText primary="Full Outer" />
              </MenuItem>

              <MenuItem value={'INNER JOIN'}>
                <MdJoinInner
                  size="2rem"
                  color={theme.palette.text.primary}
                  style={{ marginRight: theme.spacing(1) }}
                />
                <ListItemText primary="Inner" />
              </MenuItem>

              <MenuItem value={'LEFT OUTER JOIN'}>
                <MdJoinLeft
                  size="2rem"
                  color={theme.palette.text.primary}
                  style={{ marginRight: theme.spacing(1) }}
                />
                <ListItemText primary="Left Outer" />
              </MenuItem>
              <MenuItem value={'RIGHT OUTER JOIN'}>
                <MdJoinRight
                  size="2rem"
                  color={theme.palette.text.primary}
                  style={{ marginRight: theme.spacing(1) }}
                />
                <ListItemText primary="Right Outer" />
              </MenuItem>
            </Select>
          </FlexFormControl>
          {/* =============== RIGHT TABLE NAME =============== */}
          <ColumnFlex>
            {selectedJoinNode.data.rightTableName != null && (
              <Typography variant="subtitle1" flexWrap="wrap" fontWeight="bold">
                Right Table Name:
              </Typography>
            )}
            {selectedJoinNode.data.rightTableName != null && (
              <WordBreakTypography variant="subtitle1" flexWrap="wrap">
                {selectedJoinNode.data.rightTableName}
              </WordBreakTypography>
            )}
          </ColumnFlex>
        </TableNamesContainer>

        {/* =============== SELECT KEY COLUMNS =============== */}
        <SelectKeyColumnsContainer>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Select Key Columns:
          </Typography>
          <SelectKeyColumnsContent>
            {/* =============== LEFT COLUMNS SELECT =============== */}
            <ColumnsSelectContainer>
              <FullFlexFormControl>
                <InputLabel id="left-columns-select-label">
                  Left Columns
                </InputLabel>
                <Select
                  labelId="left-columns-select-label"
                  id="left-columns-select"
                  label="Left Columns"
                  multiple
                  value={selectedLeftColumns}
                  renderValue={selected => selected.join(', ')}
                  onChange={(
                    event: SelectChangeEvent<typeof selectedLeftColumns>,
                  ) => {
                    const {
                      target: { value },
                    } = event;
                    setSelectedLeftColumns(
                      typeof value === 'string' ? value.split(',') : value,
                    );
                  }}
                >
                  {selectedJoinNode.data.leftTableFieldList.map(columnName => (
                    <MenuItem value={columnName}>
                      <Checkbox
                        checked={selectedLeftColumns.indexOf(columnName) > -1}
                      />
                      <ListItemText primary={columnName} />
                    </MenuItem>
                  ))}
                </Select>
              </FullFlexFormControl>

              <SelectButtonsContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleLeftSelectAll(
                      selectedJoinNode?.data?.leftTableFieldList ?? [],
                    )
                  }
                >
                  Select All
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleLeftDeselectAll}
                >
                  Deselect All
                </Button>
              </SelectButtonsContainer>
            </ColumnsSelectContainer>

            {/* =============== RIGHT COLUMNS SELECT =============== */}
            <ColumnsSelectContainer>
              <FullFlexFormControl>
                <InputLabel id="right-columns-select-label">
                  Right Columns
                </InputLabel>
                <Select
                  labelId="right-columns-select-label"
                  id="right-columns-select"
                  label="Right Columns"
                  multiple
                  value={selectedRightColumns}
                  renderValue={selected => selected.join(', ')}
                  onChange={(
                    event: SelectChangeEvent<typeof selectedRightColumns>,
                  ) => {
                    const {
                      target: { value },
                    } = event;
                    setSelectedRightColumns(
                      typeof value === 'string' ? value.split(',') : value,
                    );
                  }}
                >
                  {selectedJoinNode.data.rightTableFieldList.map(columnName => (
                    <MenuItem value={columnName}>
                      <Checkbox
                        checked={selectedRightColumns.indexOf(columnName) > -1}
                      />
                      <ListItemText primary={columnName} />
                    </MenuItem>
                  ))}
                </Select>
              </FullFlexFormControl>

              <SelectButtonsContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleRightSelectAll(
                      selectedJoinNode?.data?.rightTableFieldList ?? [],
                    )
                  }
                >
                  Select All
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleRightDeselectAll}
                >
                  Deselect All
                </Button>
              </SelectButtonsContainer>
            </ColumnsSelectContainer>
          </SelectKeyColumnsContent>

          <FixColumnsContainer>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Fix Columns:
            </Typography>
            <FlexFormControl>
              <InputLabel id="table-to-fix-select-label">
                Table To Fix
              </InputLabel>
              <Select
                labelId="table-to-fix-select-label"
                id="table-to-fix-select"
                label="Table To Fix"
                value={tableToFix}
                onChange={(event: SelectChangeEvent<typeof tableToFix>) => {
                  const {
                    target: { value },
                  } = event;

                  setTableToFix(value as 'leftTable' | 'rightTable');
                }}
              >
                <MenuItem value={'leftTable'}>Left Table</MenuItem>
                <MenuItem value={'rightTable'}>Right Table</MenuItem>
              </Select>
            </FlexFormControl>

            <Button
              variant="contained"
              color="secondary"
              onClick={handleRenameAll}
            >
              Rename All
            </Button>
            <Button variant="outlined" color="primary" onClick={handleDropAll}>
              Drop All
            </Button>
          </FixColumnsContainer>

          <ColumnsToFixContainer>
            {columnsToFix.map(columnToFix => (
              <FixColumnForm
                name={columnToFix.columnName}
                rename={columnToFix.columnRename}
                fixType={columnToFix.fixType}
                handleUpdate={handleUpdateColumnToFix}
              />
            ))}
          </ColumnsToFixContainer>
        </SelectKeyColumnsContainer>

        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedJoinNode)}
          >
            Save
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    return (
      <NodeMenuErrorContainer>
        {!leftInputValid && !rightInputValid && (
          <Typography variant="h5" gutterBottom>
            There are no available datasets to select. Did you connect an input
            node?
          </Typography>
        )}
        {onlyOneInputValid && (
          <Typography variant="h5" gutterBottom>
            You must have two input nodes connected.
          </Typography>
        )}
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
