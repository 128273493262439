import { Node, XYPosition } from 'reactflow';
import AddConstantNode from '../components/nodes/AddConstantNode';
import AlterColumnsNode from '../components/nodes/AlterColumnsNode';
import MultiCovariantAnalysisNode from '../components/nodes/MultiCovariantAnalysisNode';
import FunctionNode from '../components/nodes/FunctionNode';
import InputDatasetNode from '../components/nodes/InputDatasetNode';
import KnowledgeGraphNode from '../components/nodes/KnowledgeGraphNode';
import OutputDatasetNode from '../components/nodes/OutputDatasetNode';
import SelectCategoriesNode from '../components/nodes/SelectCategoriesNode';
import FilterNode from '../components/nodes/FilterNode';
import JoinNode from '../components/nodes/JoinNode';
import GroupByNode from '../components/nodes/GroupByNode';
import SelectColumnsNode from '../components/nodes/SelectColumnsNode';
import AppendTablesNode from '../components/nodes/AppendTablesNode';

export type StandardNodeData = {
  PK: string | undefined;
  SK: string | undefined;
  outputDatasetName: string;
  fields: string[] | undefined; // array of column names in the dataset
  processId: number;
  objId: string;
  nodeName: string;
};

export interface InputDatasetNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  parquetLocation: string | undefined;
}

export interface InputDatasetNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: InputDatasetNodeData;
}

export interface OutputDatasetNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputObjId: string | undefined;
  inputNodeName: string | undefined;
  parquetLocation: string | undefined;
}

export interface OutputDatasetNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: OutputDatasetNodeData;
}

export interface MultiCovariantAnalysisNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputObjId: string | undefined;
  parquetLocation: string | undefined;
  inputNodeName: string | undefined;
  correlateTo: string | undefined;
  factors: string[];
}

export interface MultiCovariantAnalysisNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: MultiCovariantAnalysisNodeData;
}

// not to be confused with the Edge type from ReactFlow
export interface Edge {
  id: string;
  columnAName: string;
  columnBName: string;
}

export interface Triple {
  id: string;
  entityA: string;
  entityB: string;
  edges: Edge[];
}

export interface KnowledgeGraphNodeData extends StandardNodeData {
  inputDatasetNames: string[];
  inputDatasetNodes: InputDatasetNodeType[];
  triples: Triple[];
}

export interface KnowledgeGraphNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: KnowledgeGraphNodeData;
}

export interface SelectCategoriesNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputNodeName: string | undefined;
  inputObjId: string | undefined;
  selectedCategories: string[] | undefined; //array of column names
}

export interface SelectCategoriesNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: SelectCategoriesNodeData;
}

export interface SelectColumnsNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputNodeName: string | undefined;
  inputObjId: string | undefined;
  selectedColumns: string[] | undefined; //array of column names
}

export interface SelectColumnsNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: SelectColumnsNodeData;
}

export type ConstantColumnFormat = {
  columnName: string;
  columnFormat: string;
  columnValue: string;
  id: string;
};

export interface AddConstantNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputObjId: string | undefined;
  inputNodeName: string | undefined;
  outputColumnNames: string[] | undefined; //array of column names after the added constants
  columnsToAdd: ConstantColumnFormat[];
}

export interface AddConstantNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: AddConstantNodeData;
}

export type NodeFunctionType = {
  columnName: string;
  functionRules: string;
  id: string;
};

export interface FunctionNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputObjId: string | undefined;
  functions: NodeFunctionType[];
  inputNodeName: string | undefined;
}

export interface FunctionNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: FunctionNodeData;
}

export type AlterColumnsType = {
  id: string;
  columnName: string;
  columnRename: string;
  columnFormat: string;
};

export interface AlterColumnsNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputObjId: string | undefined;
  alterColumns: AlterColumnsType[];
  outputFields: string[] | undefined;
  inputNodeName: string | undefined;
}

export interface AlterColumnsNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: AlterColumnsNodeData;
}

export interface NodeFilterType {
  id: string;
  filterRule: string;
}

export interface FilterNodeData extends StandardNodeData {
  inputDatasetName: string | undefined;
  inputObjId: string | undefined;
  filterRules: NodeFilterType[];
  inputNodeName: string | undefined;
}

export interface FilterNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: FilterNodeData;
}

export interface ColumnFixType {
  columnName: string;
  fixType: 'drop' | 'rename';
  columnRename: string;
}

export interface JoinNodeData extends StandardNodeData {
  leftTableNameObjId: string | undefined;
  rightTableNameObjId: string | undefined;
  leftTableName: string | undefined;
  rightTableName: string | undefined;
  joinType:
    | 'FULL OUTER JOIN'
    | 'LEFT OUTER JOIN'
    | 'RIGHT OUTER JOIN'
    | 'INNER JOIN'
    | undefined;
  leftTableKeyFields: string[] | undefined;
  rightTableKeyFields: string[] | undefined;
  leftTableFieldList: string[] | undefined;
  rightTableFieldList: string[] | undefined;
  tableToFix: 'rightTable' | 'leftTable' | undefined;
  columnsToFix: ColumnFixType[];
  outputFieldList: string[];
}

export interface JoinNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: JoinNodeData;
}

export interface GroupByCalcType {
  columnName: string;
  newColumnName: string;
  calculationType: 'count' | 'count distinct' | 'sum' | 'average' | string;
  id: string;
}

export interface GroupByNodeData extends StandardNodeData {
  inputObjId: string | undefined;
  inputNodeName: string | undefined;
  groupByCols: string[];
  groupByCalcs: GroupByCalcType[];
  outputFields: string[];
}

export interface GroupByNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: GroupByNodeData;
}

export interface AppendTablesInput {
  inputDatasetObjId: string;
  fields: string[];
}

export interface AppendTablesNodeData extends StandardNodeData {
  inputDatasetObjIds: string[];
  inputs: AppendTablesInput[];
  inputsMatch: boolean;
}

export interface AppendTablesNodeType extends Node {
  id: string;
  position: XYPosition;
  type: NodeTypeNames;
  data: AppendTablesNodeData;
}

// all of the types of custom nodes allowed. This is passed into the ReactFlow Component which allows it to render these custom nodes
export const nodeTypes = {
  inputDatasetNode: InputDatasetNode,
  outputDatasetNode: OutputDatasetNode,
  knowledgeGraphNode: KnowledgeGraphNode,
  selectCategoriesNode: SelectCategoriesNode,
  selectColumnsNode: SelectColumnsNode,
  multiCovariantAnalysisNode: MultiCovariantAnalysisNode,
  addConstantNode: AddConstantNode,
  functionNode: FunctionNode,
  alterColumnsNode: AlterColumnsNode,
  joinNode: JoinNode,
  filterNode: FilterNode,
  groupByNode: GroupByNode,
  appendTablesNode: AppendTablesNode,
};

// this is used for covering all node types in some functions
export type CustomNodeTypes =
  | SelectCategoriesNodeType
  | SelectColumnsNodeType
  | KnowledgeGraphNodeType
  | OutputDatasetNodeType
  | InputDatasetNodeType
  | MultiCovariantAnalysisNodeType
  | AddConstantNodeType
  | FunctionNodeType
  | AlterColumnsNodeType
  | FilterNodeType
  | JoinNodeType
  | GroupByNodeType
  | AppendTablesNodeType;

export enum NodeTypeNames {
  inputDatasetNode = 'inputDatasetNode',
  outputDatasetNode = 'outputDatasetNode',
  knowledgeGraphNode = 'knowledgeGraphNode',
  selectCategoriesNode = 'selectCategoriesNode',
  selectColumnsNode = 'selectColumnsNode',
  multiCovariantAnalysisNode = 'multiCovariantAnalysisNode',
  addConstantNode = 'addConstantNode',
  functionNode = 'functionNode',
  alterColumnsNode = 'alterColumnsNode',
  filterNode = 'filterNode',
  joinNode = 'joinNode',
  groupByNode = 'groupByNode',
  appendTablesNode = 'appendTablesNode',
  unknownNodeType = 'unknownNodeType',
}
