import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  MultiCovariantAnalysisNodeType,
  CustomNodeTypes,
  InputDatasetNodeType,
  FunctionNodeType,
  KnowledgeGraphNodeType,
  NodeTypeNames,
  SelectCategoriesNodeType,
  OutputDatasetNodeType,
  FilterNodeType,
  GroupByNodeType,
  JoinNodeType,
  SelectColumnsNodeType,
  AppendTablesNodeType,
} from '../../types/nodes';
import * as inputDatasetNodeFunctions from './inputDatasetNodeFunctions';
import * as knowledgeGraphNodeFunctions from './knowledgeGraphNodeFunctions';
import * as selectCategoriesNodeFunctions from './selectCategoriesNodeFunctions';
import * as selectColumnsNodeFunctions from './selectColumnsNodeFunctions';
import * as addConstantNodeFunctions from './addConstantNodeFunctions';
import * as functionNodeFunctions from './functionNodeFunctions';
import * as alterColumnsNodeFunctions from './alterColumnsNodeFunctions';
import * as filterNodeFunctions from './filterNodeFunctions';
import * as groupByNodeFunctions from './groupByNodeFunctions';
import * as joinNodeFunctions from './joinNodeFunctions';
import * as appendTablesNodeFunctions from './appendTablesNodeFunctions';
import { getNodeType } from './generalNodeFunctions';
import { disconnectNodeFromMultiCovariantAnalysis } from './multiCovariantAnalysisNodeFunctions';
import {
  disconnectNodeFromOutputDataset,
  connectOutputDatasetToMultiCovariantAnalysis,
} from './outputDatasetNodeFunctions';

export const isValidConnectionFromType = (
  sourceNodeType: NodeTypeNames,
  targetNodeType: NodeTypeNames,
): boolean => {
  // database input node as source valid connections
  if (sourceNodeType === NodeTypeNames.inputDatasetNode) {
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // output dataset node as source valid connections
  else if (sourceNodeType === NodeTypeNames.outputDatasetNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      return true;
    }

    return false;
  } // knowledge graph node as source valid connections
  else if (sourceNodeType === NodeTypeNames.knowledgeGraphNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      return true;
    }

    return false;
  } // select categories node as source valid connections
  else if (sourceNodeType === NodeTypeNames.selectCategoriesNode) {
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // select columns node as source valid connections
  else if (sourceNodeType === NodeTypeNames.selectColumnsNode) {
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // add constant node as source valid connections
  else if (sourceNodeType === NodeTypeNames.addConstantNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // function node as source valid connections
  else if (sourceNodeType === NodeTypeNames.functionNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // alter columns node as source valid connections
  else if (sourceNodeType === NodeTypeNames.alterColumnsNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // filter node as source valid connections
  else if (sourceNodeType === NodeTypeNames.filterNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // group by node as source valid connections
  else if (sourceNodeType === NodeTypeNames.groupByNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }

    return false;
  } // join node as source valid connections
  else if (sourceNodeType === NodeTypeNames.joinNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.appendTablesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }

    return false;
  } // append tables node as source valid connections
  else if (sourceNodeType === NodeTypeNames.appendTablesNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.addConstantNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.functionNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.filterNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.groupByNode) {
      return true;
    }
    if (targetNodeType === NodeTypeNames.joinNode) {
      return true;
    }

    return false;
  }

  return false;
};

export const handleNodesConnection = (
  sourceNode: CustomNodeTypes,
  targetNode: CustomNodeTypes,
  isConnection: boolean, //handles whether this is a connection or a disconnection (edge delete)
): [CustomNodeTypes, CustomNodeTypes] => {
  let updatedSourceNode: CustomNodeTypes;
  let updatedTargetNode: CustomNodeTypes;
  const sourceNodeType = getNodeType(sourceNode);
  const targetNodeType = getNodeType(targetNode);

  if (sourceNodeType === NodeTypeNames.inputDatasetNode) {
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToKnowledgeGraph(
            sourceNode as InputDatasetNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as InputDatasetNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToAddConstant(
            sourceNode as InputDatasetNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToSelectCategories(
            sourceNode as InputDatasetNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToSelectColumns(
            sourceNode as InputDatasetNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToFunctionNode(
            sourceNode as InputDatasetNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToAlterColumns(
            sourceNode as InputDatasetNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToFilter(
            sourceNode as InputDatasetNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToGroupBy(
            sourceNode as InputDatasetNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy<InputDatasetNodeType>(
            sourceNode as InputDatasetNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToJoin(
            sourceNode as InputDatasetNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as InputDatasetNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? inputDatasetNodeFunctions.connectInputDatasetToAppendTables(
            sourceNode as InputDatasetNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as InputDatasetNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // output dataset node as the source node
  else if (sourceNodeType === NodeTypeNames.outputDatasetNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? connectOutputDatasetToMultiCovariantAnalysis(
            sourceNode as OutputDatasetNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<OutputDatasetNodeType>(
            sourceNode as OutputDatasetNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } //knowledge graph node as the source node
  else if (sourceNodeType === NodeTypeNames.knowledgeGraphNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? knowledgeGraphNodeFunctions.connectKnowledgeGraphToMultiCovariantAnalysis(
            sourceNode as KnowledgeGraphNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<KnowledgeGraphNodeType>(
            sourceNode as KnowledgeGraphNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } //select categories node as the source node
  else if (sourceNodeType === NodeTypeNames.selectCategoriesNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToMultiCovariantAnalysis(
            sourceNode as SelectCategoriesNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<SelectCategoriesNodeType>(
            sourceNode as SelectCategoriesNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToAddConstant(
            sourceNode as SelectCategoriesNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<SelectCategoriesNodeType>(
            sourceNode as SelectCategoriesNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToFunctionNode(
            sourceNode as SelectCategoriesNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<SelectCategoriesNodeType>(
            sourceNode as SelectCategoriesNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToAlterColumns(
            sourceNode as SelectCategoriesNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<SelectCategoriesNodeType>(
            sourceNode as SelectCategoriesNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToOutputDataset(
            sourceNode as SelectCategoriesNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<SelectCategoriesNodeType>(
            sourceNode as SelectCategoriesNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToKnowledgeGraph(
            sourceNode as SelectCategoriesNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as SelectCategoriesNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToFilter(
            sourceNode as SelectCategoriesNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter(
            sourceNode as SelectCategoriesNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToGroupBy(
            sourceNode as SelectCategoriesNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy(
            sourceNode as SelectCategoriesNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToJoin(
            sourceNode as SelectCategoriesNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as SelectCategoriesNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectCategoriesNodeFunctions.connectSelectCategoriesToAppendTables(
            sourceNode as SelectCategoriesNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as SelectCategoriesNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } //select columns node as the source node
  else if (sourceNodeType === NodeTypeNames.selectColumnsNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToMultiCovariantAnalysis(
            sourceNode as SelectColumnsNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<SelectColumnsNodeType>(
            sourceNode as SelectColumnsNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToAddConstant(
            sourceNode as SelectColumnsNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<SelectColumnsNodeType>(
            sourceNode as SelectColumnsNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToFunctionNode(
            sourceNode as SelectColumnsNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<SelectColumnsNodeType>(
            sourceNode as SelectColumnsNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToAlterColumns(
            sourceNode as SelectColumnsNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<SelectColumnsNodeType>(
            sourceNode as SelectColumnsNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToOutputDataset(
            sourceNode as SelectColumnsNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<SelectColumnsNodeType>(
            sourceNode as SelectColumnsNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToKnowledgeGraph(
            sourceNode as SelectColumnsNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as SelectColumnsNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToFilter(
            sourceNode as SelectColumnsNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter(
            sourceNode as SelectColumnsNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToGroupBy(
            sourceNode as SelectColumnsNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy(
            sourceNode as SelectColumnsNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToJoin(
            sourceNode as SelectColumnsNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as SelectColumnsNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? selectColumnsNodeFunctions.connectSelectColumnsToAppendTables(
            sourceNode as SelectColumnsNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as SelectColumnsNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // add constant node as the source node
  else if (sourceNodeType === NodeTypeNames.addConstantNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToSelectCategories(
            sourceNode as AddConstantNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToSelectColumns(
            sourceNode as AddConstantNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToKnowledgeGraph(
            sourceNode as AddConstantNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as AddConstantNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToMultiCovariantAnalysis(
            sourceNode as AddConstantNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToFunctionNode(
            sourceNode as AddConstantNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToAlterColumns(
            sourceNode as AddConstantNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToOutputDataset(
            sourceNode as AddConstantNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToFilter(
            sourceNode as AddConstantNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToGroupBy(
            sourceNode as AddConstantNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy<AddConstantNodeType>(
            sourceNode as AddConstantNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToJoin(
            sourceNode as AddConstantNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as AddConstantNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? addConstantNodeFunctions.connectAddConstantToAppendTables(
            sourceNode as AddConstantNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as AddConstantNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // function node as the source node
  else if (sourceNodeType === NodeTypeNames.functionNode) {
    if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToMultiCovariantAnalysis(
            sourceNode as FunctionNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToSelectCategories(
            sourceNode as FunctionNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToSelectColumns(
            sourceNode as FunctionNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToKnowledgeGraph(
            sourceNode as FunctionNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as FunctionNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToAddConstant(
            sourceNode as FunctionNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToAlterColumns(
            sourceNode as FunctionNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToOutputDataset(
            sourceNode as FunctionNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToFilter(
            sourceNode as FunctionNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToGroupBy(
            sourceNode as FunctionNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy<FunctionNodeType>(
            sourceNode as FunctionNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToJoin(
            sourceNode as FunctionNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as FunctionNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? functionNodeFunctions.connectFunctionNodeToAppendTables(
            sourceNode as FunctionNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as FunctionNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // add alter columns node as the source node
  else if (sourceNodeType === NodeTypeNames.alterColumnsNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToSelectCategories(
            sourceNode as AlterColumnsNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToSelectColumns(
            sourceNode as AlterColumnsNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToKnowledgeGraph(
            sourceNode as AlterColumnsNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as AlterColumnsNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToMultiCovariantAnalysis(
            sourceNode as AlterColumnsNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToFunctionNode(
            sourceNode as AlterColumnsNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToAddConstant(
            sourceNode as AlterColumnsNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToOutputDataset(
            sourceNode as AlterColumnsNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToFilter(
            sourceNode as AlterColumnsNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToGroupBy(
            sourceNode as AlterColumnsNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy<AlterColumnsNodeType>(
            sourceNode as AlterColumnsNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToJoin(
            sourceNode as AlterColumnsNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as AlterColumnsNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? alterColumnsNodeFunctions.connectAlterColumnsToAppendTables(
            sourceNode as AlterColumnsNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as AlterColumnsNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // add alter columns node as the source node
  else if (sourceNodeType === NodeTypeNames.filterNode) {
    if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToSelectCategories(
            sourceNode as FilterNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToSelectColumns(
            sourceNode as FilterNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToKnowledgeGraph(
            sourceNode as FilterNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as FilterNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToMultiCovariantAnalysis(
            sourceNode as FilterNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToFunctionNode(
            sourceNode as FilterNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToAddConstant(
            sourceNode as FilterNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToOutputDataset(
            sourceNode as FilterNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToAlterColumns(
            sourceNode as FilterNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToGroupBy(
            sourceNode as FilterNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy<FilterNodeType>(
            sourceNode as FilterNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToJoin(
            sourceNode as FilterNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as FilterNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? filterNodeFunctions.connectFilterToAppendTables(
            sourceNode as FilterNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as FilterNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // group by as source node
  else if (sourceNodeType === NodeTypeNames.groupByNode) {
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToKnowledgeGraph(
            sourceNode as GroupByNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as GroupByNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToAddConstant(
            sourceNode as GroupByNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToSelectCategories(
            sourceNode as GroupByNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToSelectColumns(
            sourceNode as GroupByNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToFunctionNode(
            sourceNode as GroupByNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToAlterColumns(
            sourceNode as GroupByNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToFilter(
            sourceNode as GroupByNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToMultiCovariantAnalysis(
            sourceNode as GroupByNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToOutputDataset(
            sourceNode as GroupByNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<GroupByNodeType>(
            sourceNode as GroupByNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToJoin(
            sourceNode as GroupByNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as GroupByNodeType,
            targetNode as JoinNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? groupByNodeFunctions.connectGroupByToAppendTables(
            sourceNode as GroupByNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as GroupByNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // join as source node
  else if (sourceNodeType === NodeTypeNames.joinNode) {
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToKnowledgeGraph(
            sourceNode as JoinNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as JoinNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToAddConstant(
            sourceNode as JoinNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToSelectCategories(
            sourceNode as JoinNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToSelectColumns(
            sourceNode as JoinNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToFunctionNode(
            sourceNode as JoinNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToAlterColumns(
            sourceNode as JoinNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToFilter(
            sourceNode as JoinNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToMultiCovariantAnalysis(
            sourceNode as JoinNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToOutputDataset(
            sourceNode as JoinNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<JoinNodeType>(
            sourceNode as JoinNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToGroupBy(
            sourceNode as JoinNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy(
            sourceNode as JoinNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.appendTablesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToAppendTables(
            sourceNode as JoinNodeType,
            targetNode as AppendTablesNodeType,
          )
        : appendTablesNodeFunctions.disconnectNodeFromAppendTables(
            sourceNode as JoinNodeType,
            targetNode as AppendTablesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? joinNodeFunctions.connectJoinToJoin(
            sourceNode as JoinNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as JoinNodeType,
            targetNode as JoinNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } // append tables as source node
  else if (sourceNodeType === NodeTypeNames.appendTablesNode) {
    if (targetNodeType === NodeTypeNames.knowledgeGraphNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToKnowledgeGraph(
            sourceNode as AppendTablesNodeType,
            targetNode as KnowledgeGraphNodeType,
          )
        : knowledgeGraphNodeFunctions.disconnectNodeFromKnowledgeGraph(
            sourceNode as AppendTablesNodeType,
            targetNode as KnowledgeGraphNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.addConstantNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToAddConstant(
            sourceNode as AppendTablesNodeType,
            targetNode as AddConstantNodeType,
          )
        : addConstantNodeFunctions.disconnectNodeFromAddConstant<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as AddConstantNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectCategoriesNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToSelectCategories(
            sourceNode as AppendTablesNodeType,
            targetNode as SelectCategoriesNodeType,
          )
        : selectCategoriesNodeFunctions.disconnectNodeFromSelectCategories<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as SelectCategoriesNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.selectColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToSelectColumns(
            sourceNode as AppendTablesNodeType,
            targetNode as SelectColumnsNodeType,
          )
        : selectColumnsNodeFunctions.disconnectNodeFromSelectColumns<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as SelectColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.functionNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToFunctionNode(
            sourceNode as AppendTablesNodeType,
            targetNode as FunctionNodeType,
          )
        : functionNodeFunctions.disconnectNodeFromFunctionNode<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as FunctionNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.alterColumnsNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToAlterColumns(
            sourceNode as AppendTablesNodeType,
            targetNode as AlterColumnsNodeType,
          )
        : alterColumnsNodeFunctions.disconnectNodeFromAlterColumns<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as AlterColumnsNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.filterNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToFilter(
            sourceNode as AppendTablesNodeType,
            targetNode as FilterNodeType,
          )
        : filterNodeFunctions.disconnectNodeFromFilter<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as FilterNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.multiCovariantAnalysisNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToMultiCovariantAnalysis(
            sourceNode as AppendTablesNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          )
        : disconnectNodeFromMultiCovariantAnalysis<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as MultiCovariantAnalysisNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.outputDatasetNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToOutputDataset(
            sourceNode as AppendTablesNodeType,
            targetNode as OutputDatasetNodeType,
          )
        : disconnectNodeFromOutputDataset<AppendTablesNodeType>(
            sourceNode as AppendTablesNodeType,
            targetNode as OutputDatasetNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.groupByNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToGroupBy(
            sourceNode as AppendTablesNodeType,
            targetNode as GroupByNodeType,
          )
        : groupByNodeFunctions.disconnectNodeFromGroupBy(
            sourceNode as AppendTablesNodeType,
            targetNode as GroupByNodeType,
          );
    } else if (targetNodeType === NodeTypeNames.joinNode) {
      [updatedSourceNode, updatedTargetNode] = isConnection
        ? appendTablesNodeFunctions.connectAppendTablesToJoin(
            sourceNode as AppendTablesNodeType,
            targetNode as JoinNodeType,
          )
        : joinNodeFunctions.disconnectNodeFromJoin(
            sourceNode as AppendTablesNodeType,
            targetNode as JoinNodeType,
          );
    } else {
      //TODO: handle default case here, maybe flash a toast?
      updatedSourceNode = sourceNode;
      updatedTargetNode = targetNode;
    }
  } else {
    //TODO: handle default case here, maybe flash a toast?
    updatedSourceNode = sourceNode;
    updatedTargetNode = targetNode;
  }

  return [updatedSourceNode, updatedTargetNode];
};
