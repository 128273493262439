import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useModelingState } from '../../state/modelingState';
import { useUserState } from '../../state/userState';
import { theme } from '../../styles/theme';
import { ProcessFlowType } from '../../types/processFlows';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}) as typeof Box;

export default function ProcessFlowSelectionHeader() {
  const navigate = useNavigate();
  const {
    availableProcessFlows,
    setSelectedProcessFlow,
    setAvailableProcessFlows,
    setNodes,
    setEdges,
  } = useModelingState();

  const { userProfile, selectedClient } = useUserState();

  const handleNewProcessFlowClick = useCallback(() => {
    const id = uuidv4();

    const newProcessFlow: ProcessFlowType = {
      PK: `${
        selectedClient?.clientNameFormattedDynamo ?? 'DEFAULTCLIENT'
      }#PROCESSFLOW`,
      SK: `${
        selectedClient?.clientNameFormattedDynamo ?? 'DEFAULTCLIENT'
      }#PFNAME#`,
      creationDate: format(new Date(), 'yyyy-MM-dd'),
      createdBy: userProfile?.username ?? 'defaultUser',
      nodes: [],
      edges: [],
      objId: id,
      name: '',
      lastRunDate: '',
      lastRunErrorMsg: '',
      lastRunStatus: '',
    };

    setSelectedProcessFlow(newProcessFlow);
    setAvailableProcessFlows(
      availableProcessFlows != null
        ? [...availableProcessFlows].concat(newProcessFlow)
        : [newProcessFlow],
    );
    setNodes([]);
    setEdges([]);
    navigate(`/modeling/${id}`);
  }, [
    setSelectedProcessFlow,
    setAvailableProcessFlows,
    availableProcessFlows,
    userProfile,
    selectedClient,
    setNodes,
    setEdges,
    navigate,
  ]);

  return (
    <HeaderContainer>
      <Typography variant="h4">Process Flows</Typography>
      <Tooltip title="Create New Process Flow" placement="right" arrow>
        <IconButton
          aria-label="create process flow"
          color="secondary"
          onClick={handleNewProcessFlowClick}
          sx={{ marginLeft: theme.spacing(1) }}
        >
          <FaCirclePlus size="2rem" />
        </IconButton>
      </Tooltip>
    </HeaderContainer>
  );
}
