import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  IsValidConnection,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnEdgesDelete,
  OnNodesChange,
} from 'reactflow';
import { create } from 'zustand';
import {
  getAvailableProcessFlowsFromClient,
  runProcessFlowService,
  saveProcessFlowService,
} from '../services/processFlowServices';
import { ResponseTypes } from '../types/api';
import { CustomNodeTypes, NodeTypeNames } from '../types/nodes';
import {
  ProcessFlowRowData,
  ProcessFlowStorageType,
  ProcessFlowType,
} from '../types/processFlows';
import {
  handleNodesConnection,
  isValidConnectionFromType,
} from '../utils/nodeFunctions/generalNodeConnectionFunctions';
import {
  getDownstreamEdges,
  updateNodesWithNewProcessFlowName,
} from '../utils/nodeFunctions/generalNodeFunctions';
import { toast } from 'react-toastify';

export interface ModelingState {
  nodes: (Node | CustomNodeTypes)[];
  selectedNode: CustomNodeTypes | undefined;
  edges: Edge[];
  selectedProcessFlow: ProcessFlowType | undefined;
  processFlowSaving: boolean;
  availableProcessFlows: ProcessFlowType[] | undefined;
  formattedProcessFlows: ProcessFlowRowData[] | undefined;
  getAvailableProcessFlowsIsLoading: boolean;
  getAvailableProcessFlowsError: boolean;
  processFlowTableQueryParams: string;

  setSelectedProcessFlow: (processFlow: ProcessFlowType | undefined) => void;
  setAvailableProcessFlows: (
    processFlows: ProcessFlowType[] | undefined,
  ) => void;
  setNodes: (nodes: (Node | CustomNodeTypes)[]) => void;
  setSelectedNode: (node: CustomNodeTypes | undefined) => void;
  setEdges: (edges: Edge[]) => void;
  onNodeSave: (nodeId: string) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  onEdgesDelete: OnEdgesDelete;
  onNodeDelete: (nodeId: string) => void;
  onProcessFlowSave: (
    processFlow: ProcessFlowType,
    name: string,
    username: string,
    runProcessFlow: boolean,
  ) => void;
  getAvailableProcessFlows: (clientName: string) => void;
  isValidConnection: IsValidConnection;
  setProcessFlowTableQueryParams: (queryString: string) => void;
}

export const useModelingState = create<ModelingState>((set, get) => ({
  nodes: [],
  selectedNode: undefined,
  edges: [],
  selectedProcessFlow: undefined,
  processFlowSaving: false,
  availableProcessFlows: [],
  formattedProcessFlows: undefined,
  getAvailableProcessFlowsIsLoading: false,
  getAvailableProcessFlowsError: false,
  processFlowTableQueryParams: '',

  setSelectedProcessFlow: (processFlow: ProcessFlowType | undefined) => {
    set({ selectedProcessFlow: processFlow });
  },
  setAvailableProcessFlows: (processFlows: ProcessFlowType[] | undefined) => {
    set({ availableProcessFlows: processFlows });
  },
  setNodes: (nodes: (Node | CustomNodeTypes)[]) => {
    set({ nodes });
  },
  setSelectedNode: (node: CustomNodeTypes | undefined) => {
    set({ selectedNode: node });
  },
  setEdges: (edges: Edge[]) => {
    set({ edges });
  },

  onNodeSave: (nodeId: string) => {
    const currentEdges = get().edges;
    const currentNodes = get().nodes;

    const updatedNodeIndex = currentNodes.findIndex(node => node.id === nodeId);

    if (updatedNodeIndex > -1) {
      // grab all edges this node is the source of
      const edgesToDelete = currentEdges.filter(
        (edge: Edge) => edge.source === nodeId,
      );

      get().onEdgesDelete(edgesToDelete);
      // call this to force edges to re-render
      get().onEdgesChange([]);
    }
  },

  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },

  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },

  onConnect: (connection: Connection) => {
    let currentNodes = get().nodes;
    let updatedNodes = [...currentNodes];
    const sourceNodeIndex = currentNodes.findIndex(
      node => node.id === connection.source,
    );
    const targetNodeIndex = currentNodes.findIndex(
      node => node.id === connection.target,
    );

    if (sourceNodeIndex !== -1 && targetNodeIndex !== -1) {
      let sourceNode = currentNodes[sourceNodeIndex];
      let targetNode = currentNodes[targetNodeIndex];

      const [updatedSourceNode, updatedTargetNode] = handleNodesConnection(
        sourceNode as CustomNodeTypes,
        targetNode as CustomNodeTypes,
        true,
      );

      updatedNodes[sourceNodeIndex] = updatedSourceNode;
      updatedNodes[targetNodeIndex] = updatedTargetNode;
    } else {
      //TODO: add error handling here. Not sure how this would ever happen though
      console.log('there was an error connecting the nodes');
    }

    set({
      edges: addEdge(connection, get().edges),
      nodes: updatedNodes,
    });
  },

  onEdgesDelete: (edges: Edge[]) => {
    const currentNodes = get().nodes;
    let updatedEdges = get().edges;
    let updatedNodes = [...currentNodes];
    let downstreamEdges = [] as Edge[];

    //grab any affected downstream edges
    if (edges.length > 0) {
      //TODO: this may produce duplicates and be inefficient, look into updating it
      downstreamEdges = edges.flatMap(edge =>
        getDownstreamEdges(edge.target, updatedEdges),
      );
    }

    //combine the downstream edges with the selected edges to delete
    const edgesToDelete = [...edges].concat(downstreamEdges);

    edgesToDelete.forEach((deletedEdge: Edge) => {
      const sourceNodeIndex = currentNodes.findIndex(
        node => node.id === deletedEdge.source,
      );
      const targetNodeIndex = currentNodes.findIndex(
        node => node.id === deletedEdge.target,
      );
      const targetEdgeIndex = updatedEdges.findIndex(
        edge => edge.id === deletedEdge.id,
      );

      if (sourceNodeIndex > -1 && targetNodeIndex > -1) {
        let sourceNode = currentNodes[sourceNodeIndex];
        let targetNode = currentNodes[targetNodeIndex];

        // update the affected nodes by running their disconnect logic
        const [updatedSourceNode, updatedTargetNode] = handleNodesConnection(
          sourceNode as CustomNodeTypes,
          targetNode as CustomNodeTypes,
          false,
        );

        updatedNodes[sourceNodeIndex] = updatedSourceNode;
        updatedNodes[targetNodeIndex] = updatedTargetNode;
      } else {
        //TODO: add error handling here. Not sure how this would ever happen though
        console.log('there was an error deleting the edge', deletedEdge);
      }

      // remove the deleted edge
      if (targetEdgeIndex > -1) {
        updatedEdges.splice(targetEdgeIndex, 1);
      }
    });

    if (edges.length > 0) {
      toast.warning('Downstream nodes affected');
    }

    set({ nodes: updatedNodes, edges: updatedEdges });
  },

  onNodeDelete: (nodeId: string) => {
    const currentEdges = get().edges;
    let updatedNodes = get().nodes;

    const deletedNodeIndex = updatedNodes.findIndex(node => node.id === nodeId);

    if (deletedNodeIndex > -1) {
      // grab all edges connected to this node
      const edgesToDelete = currentEdges.filter(
        (edge: Edge) => edge.source === nodeId || edge.target === nodeId,
      );

      get().onEdgesDelete(edgesToDelete);
      updatedNodes.splice(deletedNodeIndex, 1);
      // call this to force edges to re-render
      get().onEdgesChange([]);
    }

    set({
      nodes: updatedNodes,
    });
  },

  onProcessFlowSave: async (
    processFlow: ProcessFlowType,
    name: string,
    username: string,
    runProcessFlow: boolean,
  ) => {
    set({ processFlowSaving: true });
    const currentNodes = get().nodes;
    const currentEdges = get().edges;
    const availableProcessFlows = get().availableProcessFlows;

    const updatedNodes = updateNodesWithNewProcessFlowName(
      currentNodes as CustomNodeTypes[],
      name,
    );

    let updatedAvailableProcessFlows =
      availableProcessFlows != null ? [...availableProcessFlows] : [];

    // SK's will always be of the structure: {clientName}#PFNAME#{processFlowName}
    // the clientName and PFNAME parts are assigned on generation so we just need to make sure we overwrite the existing process flow name at the end
    const skSplit = processFlow.SK.split('#');
    const newSK = `${skSplit[0]}#${skSplit[1]}#${name}`;

    const updatedProcessFlow: ProcessFlowType = {
      PK: processFlow.PK,
      SK: newSK,
      creationDate: new Date().toISOString(),
      //TODO: update this user with real data
      createdBy: username,
      nodes: updatedNodes,
      edges: currentEdges,
      name,
      objId: processFlow.objId,
      lastRunDate: processFlow.lastRunDate,
      lastRunErrorMsg: processFlow.lastRunErrorMsg,
      lastRunStatus: processFlow.lastRunStatus,
    };

    const saveProcessFlowResponse = await saveProcessFlowService(
      updatedProcessFlow,
    );

    // will return a string if it's a bad response
    if (typeof saveProcessFlowResponse !== typeof '') {
      updatedAvailableProcessFlows.concat(updatedProcessFlow);
    }

    if (runProcessFlow) {
      await runProcessFlowService(updatedProcessFlow);
    }

    const clientName = updatedProcessFlow.PK.split('#')[0];

    // grab the available process flows again so the freshly saved process flow is included
    if (clientName.length > 0) {
      get().getAvailableProcessFlows(clientName);
      toast.info('Getting updated process flows', { autoClose: 1500 });
    }

    set({
      processFlowSaving: false,
      selectedProcessFlow: undefined,
      availableProcessFlows: updatedAvailableProcessFlows,
      nodes: updatedNodes,
    });
  },

  getAvailableProcessFlows: async (clientName: string) => {
    set({ getAvailableProcessFlowsIsLoading: true });
    let updatedAvailableProcessFlows: ProcessFlowType[] = [];

    const getAvailableProcessFlowsResponse =
      await getAvailableProcessFlowsFromClient(clientName);
    const isGoodResponse =
      typeof getAvailableProcessFlowsResponse !== typeof '';

    if (
      getAvailableProcessFlowsResponse !== ResponseTypes.BadResponse &&
      getAvailableProcessFlowsResponse !== ResponseTypes.ErrorResponse
    ) {
      updatedAvailableProcessFlows = getAvailableProcessFlowsResponse.map(
        (processFlowResponse: ProcessFlowStorageType) => {
          return {
            ...processFlowResponse.JSONPROCESSFLOW[0],
            lastRunDate: processFlowResponse?.lastRunDate ?? '',
            lastRunStatus: processFlowResponse?.lastRunStatus ?? '',
            lastRunErrorMsg: processFlowResponse?.lastRunErrorMsg ?? '',
          };
        },
      );
    }

    const formattedProcessFlows: ProcessFlowRowData[] =
      updatedAvailableProcessFlows.map(processFlow => {
        return {
          ...processFlow,
          action: '',
          numberOfNodes: processFlow.nodes.length,
        };
      });

    set({
      getAvailableProcessFlowsIsLoading: false,
      getAvailableProcessFlowsError: !isGoodResponse,
      availableProcessFlows: [...updatedAvailableProcessFlows],
      formattedProcessFlows,
    });
  },

  isValidConnection: (connection: Connection | Edge): boolean => {
    if (connection?.source != null && connection?.target != null) {
      let currentNodes = get().nodes;

      const sourceNodeIndex = currentNodes.findIndex(
        node => node.id === connection.source,
      );
      const targetNodeIndex = currentNodes.findIndex(
        node => node.id === connection.target,
      );

      if (sourceNodeIndex !== -1 && targetNodeIndex !== -1) {
        const sourceNode = currentNodes[sourceNodeIndex];
        const targetNode = currentNodes[targetNodeIndex];

        return isValidConnectionFromType(
          sourceNode.type as NodeTypeNames,
          targetNode.type as NodeTypeNames,
        );
      } else {
        // this should never happen
        return false;
      }
    } else {
      // checking if this is a connection or not
      return false;
    }
  },

  setProcessFlowTableQueryParams: (queryString: string) => {
    set({ processFlowTableQueryParams: queryString });
  },
}));
