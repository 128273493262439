import { Box, Button, TextField, Typography, styled } from '@mui/material';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';
import { AppendTablesNodeType } from '../../types/nodes';
import {
  NodeMenuContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../styles/nodeStyles';
import { MarginRightButton } from '../../styles/inputStyles';

const StyledTextField = styled(TextField)({
  marginBottom: theme.spacing(2),
  width: '100%',
}) as typeof TextField;

const TextFieldContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'inherit',
}) as typeof Box;

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes } = useModelingState();

  const selectedAppendTablesNode = useMemo(
    () => selectedNode as AppendTablesNodeType | undefined,
    [selectedNode],
  );

  const [nodeName, setNodeName] = useState(
    selectedAppendTablesNode?.data.nodeName || '',
  );

  const handleOnSave = useCallback(
    (selectedNode: AppendTablesNodeType) => {
      // UPDATE SELECTED NODE DATA
      if (nodes != null) {
        const selectedNodeIndex = nodes.findIndex(
          node => node.id === selectedNode.id,
        );

        if (selectedNodeIndex >= 0 && nodeName !== '') {
          let updatedNodes = [...nodes];
          let updatedSelectedNode = nodes[
            selectedNodeIndex
          ] as AppendTablesNodeType;

          //TODO: update the rest of the node data
          updatedSelectedNode.data.nodeName = nodeName.trim();

          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }
      setSelectedNode(undefined);
    },
    [setSelectedNode, setNodes, nodes, nodeName],
  );

  if (selectedAppendTablesNode != null) {
    return (
      <NodeMenuContainer>
        <Typography variant="h4" gutterBottom>
          Name Your Node
        </Typography>
        <TextFieldContainer>
          <StyledTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </TextFieldContainer>
        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedAppendTablesNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    // if there is something wrong with the input data and/or there is no input node connected
    return (
      <NodeMenuErrorContainer>
        <Typography variant="h5" gutterBottom>
          There are no inputs found. Did you connect an input node?
        </Typography>
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
