import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  MultiCovariantAnalysisNodeType,
  FilterNodeType,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  OutputDatasetNodeType,
  SelectCategoriesNodeType,
  FunctionNodeType,
  GroupByNodeType,
  JoinNodeType,
  SelectColumnsNodeType,
  AppendTablesNodeType,
} from '../../types/nodes';
import {
  ConnectToAppendTablesNodeData,
  connectNodeToAppendTables,
} from './appendTablesNodeFunctions';
import { ConnectToJoinNodeData, connectNodeToJoin } from './joinNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

// ====================== FILTER NODE AS TARGET ======================

export const disconnectNodeFromFilter = <Type>(
  sourceNode: Type,
  targetNode: FilterNodeType,
): [Type, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
    filterRules: [],
    fields: undefined,
    inputNodeName: undefined,
  };

  return [sourceNode, updatedTargetNode];
};

// ====================== FILTER NODE AS SOURCE ======================

export const connectFilterToMultiCovariantAnalysis = (
  sourceNode: FilterNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [FilterNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToSelectCategories = (
  sourceNode: FilterNodeType,
  targetNode: SelectCategoriesNodeType,
): [FilterNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: sourceNode.data.fields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToSelectColumns = (
  sourceNode: FilterNodeType,
  targetNode: SelectColumnsNodeType,
): [FilterNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: sourceNode.data.fields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToKnowledgeGraph = (
  sourceNode: FilterNodeType,
  targetNode: KnowledgeGraphNodeType,
): [FilterNodeType, KnowledgeGraphNodeType] => {
  //Format the Filter Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedFilterNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: undefined,
      fields: sourceNode.data.fields,
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.nodeName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedFilterNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToAddConstant = (
  sourceNode: FilterNodeType,
  targetNode: AddConstantNodeType,
): [FilterNodeType, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    outputColumnNames: sourceNode.data.fields,
    columnsToAdd: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToAlterColumns = (
  sourceNode: FilterNodeType,
  targetNode: AlterColumnsNodeType,
): [FilterNodeType, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    outputFields: [],
    alterColumns: [],
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToFunctionNode = (
  sourceNode: FilterNodeType,
  targetNode: FunctionNodeType,
): [FilterNodeType, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    functions: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToOutputDataset = (
  sourceNode: FilterNodeType,
  targetNode: OutputDatasetNodeType,
): [FilterNodeType, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToGroupBy = (
  sourceNode: FilterNodeType,
  targetNode: GroupByNodeType,
): [FilterNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    groupByCalcs: [],
    groupByCols: [],
    outputFields: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFilterToJoin = (
  sourceNode: FilterNodeType,
  targetNode: JoinNodeType,
): [FilterNodeType, JoinNodeType] => {
  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    objId: sourceNode.data.objId,
    nodeName: sourceNode.data.nodeName,
    fields: sourceNode.data?.fields ?? [],
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};

export const connectFilterToAppendTables = (
  sourceNode: FilterNodeType,
  targetNode: AppendTablesNodeType,
): [FilterNodeType, AppendTablesNodeType] => {
  const sourceNodeDataForAppendTablesNode: ConnectToAppendTablesNodeData = {
    objId: sourceNode.data.objId,
    fields: sourceNode.data?.fields ?? [],
    processId: sourceNode.data.processId,
  };

  return [
    sourceNode,
    connectNodeToAppendTables(sourceNodeDataForAppendTablesNode, targetNode),
  ];
};
