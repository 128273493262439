import { useMemo } from 'react';
import { FaPlay, FaTrash } from 'react-icons/fa';
import {
  deleteProcessFlowService,
  runProcessFlowService,
} from '../../services/processFlowServices';
import { useModelingState } from '../../state/modelingState';
import { useUserState } from '../../state/userState';
import { ProcessFlowRowData, ProcessFlowType } from '../../types/processFlows';
import ConfirmationModal from '../common/confirmationModal';
import ViewInformationModal from '../common/viewInformationModal';

interface ProcessFlowSelectionModalsProps {
  processFlowToRunProp: ProcessFlowRowData | undefined;
  processFlowToDeleteProp: ProcessFlowRowData | undefined;
  selectedErrorMsgProp: string | undefined;
  setProcessFlowToRun: (
    processFlowToRun: ProcessFlowRowData | undefined,
  ) => void;
  setProcessFlowToDelete: (
    processFlowToDelete: ProcessFlowRowData | undefined,
  ) => void;
  setSelectedErrorMsg: (errorMsg: string | undefined) => void;
}

export default function ProcessFlowSelectionModals({
  processFlowToDeleteProp,
  processFlowToRunProp,
  selectedErrorMsgProp,
  setProcessFlowToRun,
  setProcessFlowToDelete,
  setSelectedErrorMsg,
}: ProcessFlowSelectionModalsProps) {
  const { selectedClient } = useUserState();
  const { getAvailableProcessFlowsIsLoading, getAvailableProcessFlows } =
    useModelingState();

  const selectedErrorMsg = useMemo(
    () => selectedErrorMsgProp,
    [selectedErrorMsgProp],
  );
  const processFlowToDelete = useMemo(
    () => processFlowToDeleteProp,
    [processFlowToDeleteProp],
  );
  const processFlowToRun = useMemo(
    () => processFlowToRunProp,
    [processFlowToRunProp],
  );

  const handleRunIconClick = async (processFlow: ProcessFlowType) => {
    setProcessFlowToRun(undefined);
    runProcessFlowService(processFlow);
  };

  const handleDelete = async (pk: string, processFlowName: string) => {
    // closes the modal
    setProcessFlowToDelete(undefined);

    // process flow PK's have the following format: CLIENT_NAME#PROCESSFLOW
    // so we split at the '#' to obtain the client name and send it to uppercase because that is the required format by the BE
    const clientName = pk.split('#')[0].toUpperCase();
    await deleteProcessFlowService(clientName, processFlowName);

    // refresh the list of available process flows
    if (!getAvailableProcessFlowsIsLoading && selectedClient != null) {
      getAvailableProcessFlows(selectedClient.clientNameFormattedDynamo);
    }
  };

  return (
    <>
      <ViewInformationModal
        ariaLabelledBy="view-error-message-modal"
        ariaDescribedBy="This modal is used to view the selected process flow error message"
        displayText={
          selectedErrorMsg ?? 'There was a problem grabbing the error message.'
        }
        showModal={selectedErrorMsg != null}
        handleCloseClick={() => setSelectedErrorMsg(undefined)}
        onClose={() => setSelectedErrorMsg(undefined)}
      />

      <ConfirmationModal
        ariaLabelledBy="confirm-process-flow-delete-modal"
        ariaDescribedBy="This modal is used to confirm the deletion of a process flow"
        displayText={`Are you sure you want to delete this process flow: ${
          processFlowToDelete?.name ?? 'error selecting process flow'
        }`}
        confirmButtonText="Delete Process Flow"
        confirmButtonColor="error"
        showModal={processFlowToDelete != null}
        onClose={() => setProcessFlowToDelete(undefined)}
        handleConfirm={() => {
          if (processFlowToDelete != null)
            handleDelete(processFlowToDelete.PK, processFlowToDelete.name);
        }}
        handleCancel={() => setProcessFlowToDelete(undefined)}
        confirmButtonIcon={<FaTrash />}
      />

      <ConfirmationModal
        ariaLabelledBy="confirm-process-flow-run-modal"
        ariaDescribedBy="This modal is used to confirm the running of a process flow"
        displayText={`Are you sure you want to run this process flow: ${
          processFlowToRun?.name ?? 'error selecting process flow'
        }`}
        confirmButtonText={`Run ${processFlowToRun?.name ?? 'Process Flow'}`}
        showModal={processFlowToRun != null}
        onClose={() => setProcessFlowToRun(undefined)}
        handleConfirm={() => {
          if (processFlowToRun != null) handleRunIconClick(processFlowToRun);
        }}
        handleCancel={() => setProcessFlowToRun(undefined)}
        confirmButtonIcon={<FaPlay />}
      />
    </>
  );
}
