import { Typography, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import { FaCaretDown, FaTable } from 'react-icons/fa';
import { Handle, Position } from 'reactflow';
import { theme } from '../../styles/theme';
import { SelectColumnsNodeData } from '../../types/nodes';
import DeleteNodeButton from './DeleteNodeButton';
import { useModelingState } from '../../state/modelingState';
import { NodeContainer, StyledSourceHandle } from '../../styles/nodeStyles';

// TODO: update this position stuff there's probably a better way
const NodeNameTypography = styled(Typography)({
  position: 'absolute',
  top: 48,
  left: -43,
  textAlign: 'center',
  width: '8rem',
  overflowWrap: 'break-word',
  display: 'inline',
}) as typeof Typography;

const FlexEndNodeContainer = styled(NodeContainer)({
  flexDirection: 'column',
  alignItems: 'end',
}) as typeof NodeContainer;

export default memo((props: { data: SelectColumnsNodeData }) => {
  const { selectedNode } = useModelingState();

  const nodeName = useMemo(
    () => props.data.nodeName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, selectedNode],
  );

  const isConnectable = useMemo(
    () =>
      props.data.processId !== -1 &&
      props.data?.selectedColumns != null &&
      props.data.selectedColumns.length > 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, selectedNode],
  );

  return (
    <div>
      <FlexEndNodeContainer>
        <DeleteNodeButton nodeId={props.data.objId} />

        <FaCaretDown
          size="0.5rem"
          color={theme.palette.text.primary}
          style={{ marginRight: '5px' }}
        />
        <FaTable size="2rem" color={theme.palette.text.primary} />

        <StyledSourceHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          id="target"
          position={Position.Left}
          isConnectable={true}
        />

        <NodeNameTypography variant="caption">{nodeName}</NodeNameTypography>
      </FlexEndNodeContainer>
    </div>
  );
});
