import { Box, Toolbar, Typography, styled } from '@mui/material';
import { useEffect } from 'react';
import { SiMusicbrainz } from 'react-icons/si';

import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import NavBar from './components/Navigation/navBar/navBar';
import { useAuth } from './hooks/useAuth';
import DataGridPage from './pages/DataGridPage';
import DatalakePage from './pages/DatalakePage';
import ModelingPage from './pages/ModelingPage';
import ProcessFlowSelectionPage from './pages/ProcessFlowSelectionPage';
import { SignIn } from './pages/SignIn';
import { useDatalakeState } from './state/datalakeState';
import { useUserState } from './state/userState';
import { StyledAppBar } from './styles/commonStyles';
import { theme } from './styles/theme';
import NoAccessPage from './pages/NoAccessPage';
import ReportsPage from './pages/ReportsPage';
import PlotPage from './pages/PlotPage';

const ColumnFlexContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}) as typeof Box;

const CenteredFlexContainer = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'center',
}) as typeof Box;

const AppContentContainer = styled(Box)({
  display: 'flex',
  padding: '0 4rem',
  flexGrow: 1,
}) as typeof Box;

function App() {
  const { getAvailableFileConfigs } = useDatalakeState();
  const auth = useAuth();
  const { userIsLoading, userProfile, selectedClient, getUserProfile } =
    useUserState();

  // once we have the username from auth, grab the user profile and set it in state
  useEffect(() => {
    if (auth.username !== '') {
      getUserProfile(auth.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.username]);

  useEffect(() => {
    if (selectedClient != null) {
      getAvailableFileConfigs(selectedClient.clientNameFormattedDynamo);
    }
  }, [getAvailableFileConfigs, selectedClient]);

  // Auth is loading
  if (auth.isLoading) {
    return <LoadingScreen loadingText="Auth is Loading" />;
  }

  // User is not authenticated, show the Sign in page with a modified Header
  if (!auth.isAuthenticated) {
    return (
      <ColumnFlexContainer>
        <CenteredFlexContainer>
          <StyledAppBar position="static">
            <Toolbar sx={{ justifyContent: 'start' }}>
              <Typography color={theme.palette.primary.contrastText}>
                RYAILITI
              </Typography>
              <SiMusicbrainz />
            </Toolbar>
          </StyledAppBar>
          <SignIn />
        </CenteredFlexContainer>
      </ColumnFlexContainer>
    );
  }

  // user is loading
  if (userIsLoading || userProfile == null || selectedClient == null) {
    return <LoadingScreen loadingText="User is Loading" />;
  }

  // user does not have a license, show them the "No Access" page
  if (userProfile.ETLLicense !== 'YES') {
    return <NoAccessPage />;
  }

  // User is not loading, auth is not loading, and user is fully authenticated, show the App
  return (
    <ColumnFlexContainer>
      <NavBar />
      <AppContentContainer>
        <Routes>
          <Route path="" element={<Navigate to="datalake" />} />
          <Route path="datalake" element={<DatalakePage />} />
          <Route path="modeling" element={<ProcessFlowSelectionPage />} />
          <Route path="modeling/:processFlowId" element={<ModelingPage />} />
          <Route
            path="datalake/:fileNameParam/reports"
            element={<ReportsPage />}
          />
          <Route
            path="datalake/:fileNameParam/reports/dataGrid"
            element={<DataGridPage />}
          />
          <Route
            path="datalake/:fileNameParam/reports/:reportIdParam/plot/"
            element={<PlotPage />}
          />
          <Route path="signin" element={<SignIn />} />
        </Routes>
      </AppContentContainer>
    </ColumnFlexContainer>
  );
}

export default App;
