import { Button, ButtonOwnProps, Modal } from '@mui/material';
import React, { useMemo } from 'react';
import { BasicModalContainer } from '../../styles/commonStyles';
import { TwoMarginTypography } from '../../styles/textStyles';

interface ViewInformationModalProps {
  displayText: string;
  showModal: boolean;
  onClose: () => void;
  handleCloseClick: () => void;
  closeButtonIcon?: React.ReactNode;
  closeButtonText?: string;
  closeButtonColor?: ButtonOwnProps['color'];
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
}

export default function ViewInformationModal({
  displayText,
  showModal,
  closeButtonColor,
  closeButtonText,
  closeButtonIcon,
  ariaDescribedBy,
  ariaLabelledBy,
  onClose,
  handleCloseClick,
}: ViewInformationModalProps) {
  const modalIsOpen = useMemo(() => showModal, [showModal]);

  return (
    <Modal
      aria-labelledby={`${ariaLabelledBy ?? 'default-modal-label'}`}
      aria-describedby={`${ariaDescribedBy ?? 'default-modal-description'}`}
      open={modalIsOpen}
      onClose={onClose}
    >
      <BasicModalContainer>
        <TwoMarginTypography>{displayText}</TwoMarginTypography>
        <Button
          variant="contained"
          onClick={handleCloseClick}
          startIcon={closeButtonIcon ?? <></>}
          color={closeButtonColor ?? 'primary'}
        >
          {closeButtonText ?? 'Close'}
        </Button>
      </BasicModalContainer>
    </Modal>
  );
}
