import { Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useModelingState } from '../../../state/modelingState';
import { theme } from '../../../styles/theme';
import {
  AddConstantNodeType,
  ConstantColumnFormat,
} from '../../../types/nodes';

import { v4 as uuidv4 } from 'uuid';
import AddColumnConstantForm from './AddColumnConstantForm';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../../styles/nodeStyles';
import {
  FitContentButton,
  MarginRightButton,
} from '../../../styles/inputStyles';

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedAddConstantNode = useMemo(
    () => selectedNode as AddConstantNodeType | undefined,
    [selectedNode],
  );

  const [constants, setConstants] = useState<ConstantColumnFormat[]>(
    selectedAddConstantNode != null
      ? selectedAddConstantNode.data.columnsToAdd
      : [],
  );
  const [nodeName, setNodeName] = useState(
    selectedAddConstantNode?.data.nodeName || '',
  );

  const handleValueUpdate = useCallback(
    (id: string, type: 'name' | 'format' | 'value', newValue: string) => {
      let updatedConstants = [...constants];
      const indexToUpdate = updatedConstants.findIndex(
        (constant: ConstantColumnFormat) => constant.id === id,
      );

      if (indexToUpdate >= 0) {
        let constantToUpdate = updatedConstants[indexToUpdate];

        if (type === 'name') {
          constantToUpdate.columnName = newValue;
        }
        if (type === 'format') {
          constantToUpdate.columnFormat = newValue;
        }
        if (type === 'value') {
          constantToUpdate.columnValue = newValue;
        }

        updatedConstants[indexToUpdate] = constantToUpdate;
      }
      setConstants([...updatedConstants]);
    },
    [constants, setConstants],
  );

  const handleDeleteConstant = useCallback(
    (id: string) => {
      const indexToDelete = constants.findIndex(constant => constant.id === id);

      if (indexToDelete >= 0) {
        let updatedConstants = [...constants];
        updatedConstants.splice(indexToDelete, 1);
        setConstants([...updatedConstants]);
      }
    },
    [constants, setConstants],
  );

  const handleAddNewConstant = useCallback(() => {
    const newConstant: ConstantColumnFormat = {
      columnName: '',
      columnFormat: '',
      columnValue: '',
      id: uuidv4(),
    };
    setConstants([...constants].concat(newConstant));
  }, [constants, setConstants]);

  // If there are no constants, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedAddConstantNode != null && constants.length === 0) {
      handleAddNewConstant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddConstantNode, constants]);

  // TODO: add error handling here like if an input is invalid or there was an error updating the nodes
  const handleOnSave = useCallback(
    (selectedNode: AddConstantNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === selectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          //only grab constants that are valid
          const updatedConstants = constants.filter(
            constant =>
              constant.columnFormat.trim() !== '' &&
              constant.columnName.trim() !== '' &&
              constant.columnValue.trim() !== '',
          );

          // can add this back in later if we need it down the line, in this current state though it causes duplicate column names
          // could add error handling to validate the column names before saving
          // const newColumnNamesArray = updatedConstants.map(
          //   constant => constant.columnName,
          // );
          // const oldColumnNamesArray = selectedNode.data?.outputColumnNames;

          // let combinedColumnNamesArray = [...newColumnNamesArray];

          // if (oldColumnNamesArray != null && oldColumnNamesArray.length > 0) {
          //   combinedColumnNamesArray = combinedColumnNamesArray.concat([
          //     ...oldColumnNamesArray,
          //   ]);
          // }

          let updatedSelectedNode = { ...selectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            columnsToAdd: [...updatedConstants],
            nodeName: nodeName.trim(),
            // outputColumnNames: [...combinedColumnNamesArray],
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(selectedNode.id);
      setSelectedNode(undefined);
      setConstants([]);
      setNodeName('');
    },
    [constants, nodes, nodeName, setNodes, onNodeSave, setSelectedNode],
  );

  if (
    selectedAddConstantNode != null &&
    selectedAddConstantNode.data?.fields != null &&
    selectedAddConstantNode.data?.inputObjId != null
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Add a Constant
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <ScrollableContentContainer>
          {constants.map(constant => {
            return (
              <AddColumnConstantForm
                name={constant.columnName}
                format={constant.columnFormat}
                value={constant.columnValue}
                id={constant.id}
                handleDelete={handleDeleteConstant}
                handleUpdate={handleValueUpdate}
                key={constant.id}
              />
            );
          })}
          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewConstant}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
          >
            Add Constant
          </FitContentButton>
        </ScrollableContentContainer>
        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedAddConstantNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  }

  // if there is something wrong with the input data and/or there is no input node connected
  return (
    <NodeMenuErrorContainer>
      <Typography variant="h5" gutterBottom>
        There are no input columns found. Did you connect an input node?
      </Typography>
      <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
        Cancel
      </Button>
    </NodeMenuErrorContainer>
  );
});
