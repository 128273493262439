import { Button, Typography, styled } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useModelingState } from '../../state/modelingState';
import { MarginRightButton } from '../../styles/inputStyles';
import {
  NodeMenuContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
  NodeMenuHeaderContainer,
  NodeMenuListItemButton,
  NodeMenuListItemText,
  NodeMenuSelectionList,
  NodeNameTextField,
} from '../../styles/nodeStyles';
import { SelectColumnsNodeType } from '../../types/nodes';

const EndButtonsContainer = styled(NodeMenuEndButtonsContainer)({
  justifyContent: 'space-between',
  width: '100%',
});

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedSelectColumnsNode = useMemo(
    () => selectedNode as SelectColumnsNodeType | undefined,
    [selectedNode],
  );

  const availableColumns = useMemo(
    () =>
      selectedSelectColumnsNode != null &&
      selectedSelectColumnsNode.data?.fields != null
        ? selectedSelectColumnsNode.data.fields.sort()
        : undefined,
    [selectedSelectColumnsNode],
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    selectedSelectColumnsNode?.data?.selectedColumns || [],
  );
  const [nodeName, setNodeName] = useState(
    selectedSelectColumnsNode?.data.nodeName || '',
  );

  const handleListItemClick = useCallback(
    (clickedColumn: string) => {
      let updatedSelectedColumns = [...selectedColumns];

      if (updatedSelectedColumns.length > 0) {
        const clickedColumnIndex = updatedSelectedColumns.findIndex(
          column => column === clickedColumn,
        );

        //The column has not been selected yet and is not in the list
        if (clickedColumnIndex === -1) {
          updatedSelectedColumns.push(clickedColumn);
        } else {
          //The column was already selected and should be removed from the list
          updatedSelectedColumns.splice(clickedColumnIndex, 1);
        }
      } else {
        updatedSelectedColumns.push(clickedColumn);
      }

      setSelectedColumns(updatedSelectedColumns);
    },
    [setSelectedColumns, selectedColumns],
  );

  const handleOnSave = useCallback(
    (savedSelectedNode: SelectColumnsNodeType) => {
      // UPDATE SELECTED NODE DATA
      if (nodes != null) {
        const selectedNodeIndex = nodes.findIndex(
          node => node.id === savedSelectedNode.id,
        );

        if (selectedNodeIndex >= 0 && selectedColumns.length > 0) {
          let updatedNodes = [...nodes];
          let updatedSelectedNode = nodes[
            selectedNodeIndex
          ] as SelectColumnsNodeType;

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            selectedColumns: [...selectedColumns],
            nodeName: nodeName.trim(),
          };

          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
          // UPDATE IMPACTED STATE INFORMATION
          onNodeSave(savedSelectedNode.id);
          setNodeName('');
          setSelectedColumns([]);
        }
      }
      setSelectedNode(undefined);
    },
    [setSelectedNode, setNodes, onNodeSave, nodes, selectedColumns, nodeName],
  );

  const handleSelectAll = useCallback(() => {
    setSelectedColumns(
      availableColumns != null ? [...availableColumns] : selectedColumns,
    );
  }, [selectedColumns, availableColumns, setSelectedColumns]);

  const handleDeselectAll = useCallback(() => {
    setSelectedColumns([]);
  }, [setSelectedColumns]);

  if (selectedSelectColumnsNode != null && availableColumns != null) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Select Columns
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <Typography variant="h6" gutterBottom>
          Available Columns:
        </Typography>
        {/* TODO: Add logic to handle if select columns list is empty or errored */}
        {/* Maybe make this a virtualized list if dataset count get to be huge */}
        {/* Maybe add a second list to the right with the selected columns */}
        <NodeMenuSelectionList>
          {availableColumns.map(column => {
            const isSelected =
              selectedColumns.findIndex(columnName => column === columnName) !==
              -1;

            return (
              <NodeMenuListItemButton
                key={`${column}}`}
                selected={isSelected}
                onClick={() => handleListItemClick(column)}
              >
                <NodeMenuListItemText
                  primary={column}
                  isSelected={isSelected}
                />
              </NodeMenuListItemButton>
            );
          })}
        </NodeMenuSelectionList>

        <EndButtonsContainer>
          <div>
            <MarginRightButton
              variant="contained"
              color="secondary"
              onClick={handleSelectAll}
            >
              Select All
            </MarginRightButton>
            <Button onClick={handleDeselectAll}>Deselect All</Button>
          </div>
          <div>
            <MarginRightButton onClick={() => setSelectedNode(undefined)}>
              Cancel
            </MarginRightButton>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleOnSave(selectedSelectColumnsNode)}
            >
              Save Selections
            </Button>
          </div>
        </EndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    return (
      <NodeMenuErrorContainer>
        <Typography variant="h5" gutterBottom>
          There are no available columns to select. Did you connect an input
          node?
        </Typography>
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
