import {
  Box,
  CardActionArea,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { FaChevronDown, FaFolder } from 'react-icons/fa6';
import { StringParam, useQueryParam } from 'use-query-params';
import { theme } from '../../styles/theme';
import { useFileConfigState } from '../../state/fileconfigState';
import { DatalakeFolder } from '../../types/datalake';

const FoldersContainer = styled(Box)({
  marginBottom: theme.spacing(2),
});

const FoldersHeader = styled(CardActionArea)({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.defaultPaddingRem,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  alignItems: 'center',
  borderRadius: 0,
});

const FolderChevron = styled(FaChevronDown, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded: boolean }>(({ expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : '',
  transition: 'all 300ms ease-in-out', // smooth transition
}));

export default function DatalakeFolders() {
  const {
    formattedFileConfigFolders,
    getFileConfigIsLoading,
    setSelectedFileConfigs,
  } = useFileConfigState();

  const [expanded, setExpanded] = useState(false);

  const [selectedFolder, setSelectedFolder] = useQueryParam(
    'selectedFolder',
    StringParam,
  );

  const handleHeaderClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const handleFolderClick = useCallback(
    (folder: DatalakeFolder) => {
      setSelectedFolder(folder.name);
      setSelectedFileConfigs(folder.files);
    },
    [setSelectedFileConfigs, setSelectedFolder],
  );

  if (
    selectedFolder == null &&
    !getFileConfigIsLoading &&
    formattedFileConfigFolders != null &&
    formattedFileConfigFolders.length > 0
  ) {
    return (
      <FoldersContainer>
        <FoldersHeader onClick={handleHeaderClick}>
          <Typography fontWeight="bold" variant="body1">
            Folders
          </Typography>
          <FolderChevron
            expanded={expanded}
            size={20}
            color={theme.palette.text.primary}
          />
        </FoldersHeader>
        <Collapse in={expanded}>
          <List sx={{ paddingTop: 0 }}>
            {formattedFileConfigFolders.map(folder => {
              return (
                <ListItemButton
                  key={folder.name}
                  onClick={() => {
                    handleFolderClick(folder);
                  }}
                >
                  <ListItemIcon>
                    <FaFolder />
                  </ListItemIcon>
                  <ListItemText primary={folder.name} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </FoldersContainer>
    );
  }

  return <></>;
}
