import axios from 'axios';
import { ResponseTypes } from '../types/api';
import {
  GetAllProcessFlowsResponse,
  GetSingleProcessFlowsResponse,
  ProcessFlowStorageType,
  ProcessFlowType,
  RunProcessFlowBodyShape,
} from '../types/processFlows';
import { BASE_URL } from '../utils/constants';
import { toast } from 'react-toastify';

export const saveProcessFlowService = async (processFlow: ProcessFlowType) => {
  console.log('==== saveProcessFlowService ======');
  const toastId = toast.loading('Process flow saving...');

  try {
    const bodyData = {
      PK: processFlow.PK,
      SK: processFlow.SK,
      JSONPROCESSFLOW: [{ ...processFlow }],
    };
    const dynamoAPI = `${BASE_URL}/updaterkedynamodb`;
    const response = await axios.post(dynamoAPI, bodyData);

    if (response.status === 200) {
      toast.update(toastId, {
        type: 'success',
        render: 'Process Flow saved!',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem saving your process flow',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
  } catch (error) {
    console.log('++++++++++ ERROR WITH saveProcessFlowService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem saving your process flow',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};

export const getAvailableProcessFlowsFromClient = async (
  clientName: string,
) => {
  console.log('==== getAvailableProcessFlowsFromClientService ======');
  try {
    const getAvailableProcessFlowsFromClientResponse = await axios.get(
      `${BASE_URL}/getallprocessflows?pk=${clientName.toUpperCase()}`,
    );

    if (getAvailableProcessFlowsFromClientResponse.data.length > 0) {
      const processFlowData: GetAllProcessFlowsResponse[] =
        await getAvailableProcessFlowsFromClientResponse.data;

      // ensure we only attempt to map process flows with valid data
      const filteredProcessFlowData = processFlowData.filter(
        processFlowData =>
          processFlowData?.ProcessFlowData != null &&
          processFlowData.ProcessFlowData?.S != null &&
          processFlowData.ProcessFlowData.S.length > 0,
      );

      return filteredProcessFlowData.map(processFlowData => {
        return {
          ...JSON.parse(processFlowData.ProcessFlowData.S),
          lastRunDate: processFlowData?.lastRunDate,
          lastRunStatus: processFlowData?.lastRunStatus,
          lastRunErrorMsg: processFlowData?.lastRunErrMsg,
        };
      });
    } else {
      toast.error('There was a problem getting your available process flows');

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log(
      '++++++++++ ERROR WITH getAvailableProcessFlowsFromClientService ++++++++++',
    );
    console.log(error);
    toast.error('There was a problem getting your available process flows');

    return ResponseTypes.ErrorResponse;
  }
};

export const runProcessFlowService = async (processFlow: ProcessFlowType) => {
  console.log('==== runProcessFlowService ======');
  const toastId = toast.loading('Process flow running...');

  try {
    const bodyData: RunProcessFlowBodyShape = {
      jobParams: {
        job_name: 'RKE Process Process Flow',
        job_steps: ['processFlow'],
        PK: processFlow.PK,
        SK: processFlow.SK,
      },
      sysParams: {
        sys_mountPoint: 's3://',
        sys_access_key: process.env.REACT_APP_AWS_ACCESS_KEY ?? '',
        sys_secret_key: process.env.REACT_APP_AWS_SECRET_KEY ?? '',
        sys_dynamodb_table: process.env.REACT_APP_SYS_DYNAMO_TABLE ?? '',
        sys_region_name: process.env.REACT_APP_SYS_REGION ?? '',
      },
    };
    const apiUrl = `${BASE_URL}/runpipeline`;

    const response = await axios.post(apiUrl, bodyData);

    console.log('----- runProcessFlowService RESPONSE -----');

    if (response.status === 200) {
      toast.update(toastId, {
        type: 'success',
        render: 'Process Flow ran successfully!',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });
      toast.info(
        'The output data and last run metrics may take some extra time to process.',
      );

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem running your process flow',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
  } catch (error) {
    console.log('++++++++++ ERROR WITH runProcessFlowService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem running your process flow',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};

export const getSingleProcessFlowService = async (
  clientName: string,
  processFlowName: string,
): Promise<ProcessFlowType | string> => {
  console.log('==== getSingleProcessFlowService ======');
  const toastId = toast.loading('Grabbing your process flow...');
  try {
    const getSingleProcessFlowResponse = await axios.get(
      `${BASE_URL}/getaprocessflow`,
      { params: { pk: clientName.toUpperCase(), sk: processFlowName } },
    );

    if (getSingleProcessFlowResponse.data.length > 0) {
      const processFlowData: GetSingleProcessFlowsResponse =
        await getSingleProcessFlowResponse.data[0];

      // ensure the process flow response has valid data before attempting to parse
      if (
        processFlowData?.ProcessFlowData != null &&
        processFlowData.ProcessFlowData?.S != null &&
        processFlowData.ProcessFlowData.S.length > 0
      ) {
        const jsonData: ProcessFlowStorageType = JSON.parse(
          processFlowData.ProcessFlowData.S,
        );
        toast.dismiss(toastId);

        return jsonData.JSONPROCESSFLOW[0];
      } else {
        toast.update(toastId, {
          type: 'error',
          render: 'There was a problem getting your process flow',
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        return ResponseTypes.BadResponse;
      }
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem getting your process flow',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log('++++++++++ ERROR WITH getSingleProcessFlowService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem getting your process flow',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};

export const deleteProcessFlowService = async (
  clientName: string,
  processFlowName: string,
): Promise<string> => {
  console.log('==== deleteProcessFlowService ======');
  const toastId = toast.loading('Deleting the process flow...');
  try {
    const bodyData = { PK: clientName.toUpperCase(), pfName: processFlowName };
    const deleteProcessFlowResponse = await axios.post(
      `${BASE_URL}/deleteaprocessflow`,
      bodyData,
    );

    if (
      deleteProcessFlowResponse?.data?.body ===
      'File and record deleted successfully.'
    ) {
      toast.update(toastId, {
        type: 'success',
        render: 'Process flow deleted',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem deleting your process flow',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.ErrorResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log('++++++++++ ERROR WITH deleteProcessFlowService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem deleting your process flow',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};
