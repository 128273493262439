import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useFileConfigState } from '../../state/fileconfigState';
import {
  CenterAlignedRowFlex,
  StyledSkeleton,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import { ShowProcessFlowsParam, ShowUploadsParam } from '../../types/datalake';
import CreateFolderComponent from './createFolder';
import UploadFileComponent from './uploadFile';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}) as typeof Box;

const FileUploadContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
}) as typeof Box;

const SkeletonLoadersContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}) as typeof Box;

const OneGapRowFlex = styled(CenterAlignedRowFlex)({
  gap: theme.spacing(1),
});

const StyledFormGroup = styled(FormGroup)({
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'nowrap',
}) as typeof FormGroup;

export default function DatalakeHeader() {
  const { getFileConfigIsLoading, formattedFileConfigs, getFileConfigsError } =
    useFileConfigState();

  const [showProcessFlows, setShowProcessFlows] = useQueryParam(
    'showProcessFlows',
    ShowProcessFlowsParam,
  );
  const [showUploads, setShowUploads] = useQueryParam(
    'showUploads',
    ShowUploadsParam,
  );
  const [selectedFolder, setSelectedFolder] = useQueryParam(
    'selectedFolder',
    StringParam,
  );

  const updateTableDataState = useCallback((): number => {
    if (getFileConfigsError && !getFileConfigIsLoading) {
      return -1;
    }

    if (getFileConfigIsLoading || formattedFileConfigs == null) {
      return 0;
    }

    if (formattedFileConfigs != null) {
      return formattedFileConfigs.length > 0 ? 1 : 2;
    }

    return -1;
  }, [formattedFileConfigs, getFileConfigIsLoading, getFileConfigsError]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const tableDataState = useMemo(
    () => updateTableDataState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      updateTableDataState,
      formattedFileConfigs,
      getFileConfigIsLoading,
      getFileConfigsError,
    ],
  );

  const handleShowProcessFlowsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowProcessFlows(event.target.checked);
  };

  const handleShowUploadsChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowUploads(event.target.checked);
  };

  return (
    <HeaderContainer>
      {(tableDataState === -1 || tableDataState >= 1) && (
        <FileUploadContainer>
          {selectedFolder == null && (
            <Typography variant="h4">RKE Datalake Storage</Typography>
          )}

          {selectedFolder != null && (
            <OneGapRowFlex>
              <Link
                to="/datalake"
                onClick={() => {
                  setSelectedFolder(undefined);
                }}
              >
                <Typography variant="h4">RKE Datalake Storage</Typography>
              </Link>
              <Typography variant="h4">{` / ${selectedFolder}`}</Typography>
            </OneGapRowFlex>
          )}

          <UploadFileComponent />
          <CreateFolderComponent />
        </FileUploadContainer>
      )}

      {/* Loading */}
      {tableDataState === 0 && (
        <SkeletonLoadersContainer>
          <OneGapRowFlex>
            <StyledSkeleton
              variant="rectangular"
              animation="wave"
              height="3rem"
              width={selectedFolder == null ? '30vw' : '40vw'}
            />
            <Skeleton
              variant="circular"
              animation="wave"
              height="2.5rem"
              width="2.5rem"
            />
            {selectedFolder == null && (
              <Skeleton
                variant="circular"
                animation="wave"
                height="2.5rem"
                width="2.5rem"
              />
            )}
          </OneGapRowFlex>
          <OneGapRowFlex>
            <StyledSkeleton
              key="filters-label-skeleton"
              variant="rectangular"
              animation="wave"
              height="1.5rem"
              width="3vw"
            />
            <StyledSkeleton
              key="show-process-flows-skeleton"
              variant="rectangular"
              animation="wave"
              height="1.5rem"
              width="7vw"
            />
            <StyledSkeleton
              key="show-uploads-skeleton"
              variant="rectangular"
              animation="wave"
              height="1.5rem"
              width="7vw"
            />
          </OneGapRowFlex>
        </SkeletonLoadersContainer>
      )}

      {/* Good data show filters */}
      {tableDataState === 1 && (
        <StyledFormGroup>
          <Typography fontWeight={'bold'} marginRight={theme.spacing(1)}>
            Filters:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={showProcessFlows}
                onChange={handleShowProcessFlowsChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Process Flows"
            sx={{ display: 'flex', width: 'max-content' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showUploads}
                onChange={handleShowUploadsChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Uploads"
          />
        </StyledFormGroup>
      )}
    </HeaderContainer>
  );
}
