import { Breadcrumbs, Link } from '@mui/material';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbFormat = {
  text: string;
  href: string;
};

interface BreadCrumbNavigatorProps {
  breadcrumbs: BreadcrumbFormat[];
}

export default function BreadCrumbNavigator({
  breadcrumbs,
}: BreadCrumbNavigatorProps) {
  const breadcrumbsArray = useMemo(() => [...breadcrumbs], [breadcrumbs]);
  const currentPageBreadcrumb = useMemo(
    () => breadcrumbsArray.pop(),
    [breadcrumbsArray],
  );

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbsArray.map(breadcrumb => {
        return (
          <Link
            key={`${breadcrumb.text}-${breadcrumb.href}`}
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={breadcrumb.href}
            state={{ navigatedFromBreadcrumb: true }}
          >
            {breadcrumb.text}
          </Link>
        );
      })}
      {currentPageBreadcrumb != null && (
        <Link color="primary" underline="none" fontWeight="bold">
          {currentPageBreadcrumb.text}
        </Link>
      )}
    </Breadcrumbs>
  );
}
