import { Button, InputLabel, Select, styled } from '@mui/material';
import { theme } from './theme';

// ========== BUTTONS ==========

export const MarginRightButton = styled(Button)({
  marginRight: theme.spacing(1),
}) as typeof Button;

export const FitContentButton = styled(Button)({
  width: 'fit-content',
}) as typeof Button;

// ========== SELECT ==========

export const HighlightOnMatchSelect = styled(Select, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'myProp',
})<{ isHightlighted?: boolean }>(({ isHightlighted, theme }) => ({
  '.MuiSelect-icon': {
    fill: isHightlighted ? theme.palette.success.main : 'default',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: isHightlighted ? theme.palette.success.main : 'default',
    borderWidth: isHightlighted ? 2 : 1,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isHightlighted ? theme.palette.success.light : 'default',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isHightlighted ? theme.palette.success.dark : 'default',
  },
  textDecoration: isHightlighted ? 'none' : 'line-through',
}));

export const HighlightOnMatchSelectLabel = styled(InputLabel, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'myProp',
})<{ isHightlighted?: boolean }>(({ isHightlighted, theme }) => ({
  color: isHightlighted ? theme.palette.success.main : 'default',
  '&.Mui-focused': {
    color: isHightlighted ? theme.palette.success.dark : 'default',
  },
}));
