import { Box, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useReportsState } from '../../state/reportsState';
import { Report } from '../../types/reports';
import ReportTile, { CARD_WIDTH } from './reportTile';
import { useMemo } from 'react';
import { setUpQueryParams } from '../../types/plots';

const defaultDataGridReport: Report = {
  name: 'Data Grid',
  xAxis: '',
  yAxis: '',
  xAxisReducer: false,
  yAxisReducer: false,
  type: 'dataGrid',
  id: '',
  csvKey: '',
  fileSK: '',
  client: '',
  PK: '',
  SK: '',
};

export const ReportsGridContainer = styled(Box)({
  gridTemplateColumns: `repeat(auto-fill, minmax(${CARD_WIDTH}rem, 1fr))`,
  gap: '2rem',
  height: 'fit-content',
  width: '100%',
  display: 'grid',
}) as typeof Box;

export default function ReportsContent() {
  const { setSelectedReport, availableReports, selectedFileInfoForS3 } =
    useReportsState();
  const navigate = useNavigate();

  const dataGridReport: Report = useMemo(() => {
    return {
      ...defaultDataGridReport,
      csvKey: selectedFileInfoForS3?.fileData ?? '',
      fileSK: selectedFileInfoForS3?.fileSK ?? '',
      client: selectedFileInfoForS3?.fileClient ?? '',
    };
  }, [selectedFileInfoForS3]);

  const handleReportTileClick = (clickedReport: Report) => {
    setSelectedReport(clickedReport);

    if (clickedReport.type === 'dataGrid') {
      navigate('dataGrid');
    } else {
      // the plot uses query params as state variables, so set those up here
      const queryParams = setUpQueryParams(clickedReport);
      navigate(`${clickedReport.id}/plot${queryParams}`);
    }
  };

  if (availableReports != null) {
    return (
      <ReportsGridContainer>
        <ReportTile
          key={dataGridReport.id}
          {...dataGridReport}
          handleClick={() => handleReportTileClick(dataGridReport)}
        />
        {availableReports.map(report => (
          <ReportTile
            key={`${report.id}-${report.name}`}
            {...report}
            handleClick={() => handleReportTileClick(report)}
          />
        ))}
      </ReportsGridContainer>
    );
  }

  return <></>;
}
