import { QueryParamConfig, encodeNumber, encodeString } from 'use-query-params';

export type Order = 'asc' | 'desc';

// ========== Table Sorting Utils ==========

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// ========== Query Param Utils ==========

export const defaultOrder: Order = 'asc';
export const defaultPageNum = 0;

// We have these custom decoder functions because the user-query-param library doesn't know how to handle default, null, undefined, or unexpected values so handle them here

// custom function telling use-query-params how to decode the order param so typescript is happy
export function decodeOrderQueryParam(
  order: string | (string | null)[] | null | undefined,
): Order {
  if (order == null) return defaultOrder;
  if (typeof order === typeof ['']) return defaultOrder;
  if (order === 'asc') return order;
  if (order === 'desc') return order;

  return defaultOrder;
}

// custom function telling use-query-params how to decode a number query param, given a default value, so typescript is happy
export function decodeNumberQueryParam(
  queryParam: string | (string | null)[] | null | undefined,
  defaultValue: number,
): number {
  if (queryParam == null) return defaultValue;

  if (typeof queryParam === typeof ['']) return defaultValue;

  if (typeof queryParam === 'string' && !isNaN(+queryParam)) {
    return parseInt(queryParam);
  }

  return defaultValue;
}

// custom function telling use-query-params how to decode a boolean query param given a default value
export function decodeBooleanQueryParam(
  queryParam: string | (string | null)[] | null | undefined,
  defaultValue: boolean,
): boolean {
  if (queryParam == null) return defaultValue;

  if (typeof queryParam === typeof ['']) return defaultValue;

  if (
    typeof queryParam === 'string' &&
    (queryParam.toLowerCase() === '0' || queryParam.toLowerCase() === '1')
  ) {
    return Boolean(+queryParam);
  }

  if (
    typeof queryParam === 'string' &&
    (queryParam.toLowerCase() === 'false' ||
      queryParam.toLowerCase() === 'true')
  ) {
    return Boolean(queryParam);
  }

  return defaultValue;
}

// these are "types" for the useQueryParam hook used in the tables. These objects tell the hook how to encode and decode that specific query parameter
export const PageNumParam: QueryParamConfig<number> = {
  encode: (pageNum: number | null | undefined) => encodeNumber(pageNum),
  decode: (pageNum: string | (string | null)[] | null | undefined) =>
    decodeNumberQueryParam(pageNum, defaultPageNum),
};

export const OrderParam: QueryParamConfig<Order> = {
  encode: (order: Order | null | undefined) => encodeString(order),
  decode: (order: string | (string | null)[] | null | undefined) =>
    decodeOrderQueryParam(order),
};
