import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  MultiCovariantAnalysisNodeType,
  FunctionNodeType,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  OutputDatasetNodeType,
  SelectCategoriesNodeType,
  FilterNodeType,
  GroupByNodeType,
  JoinNodeType,
  SelectColumnsNodeType,
  AppendTablesNodeType,
} from '../../types/nodes';
import {
  ConnectToAppendTablesNodeData,
  connectNodeToAppendTables,
} from './appendTablesNodeFunctions';
import { ConnectToJoinNodeData, connectNodeToJoin } from './joinNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

// ====================== ADD CONSTANT AS SOURCE ======================

export const connectAddConstantToSelectCategories = (
  sourceNode: AddConstantNodeType,
  targetNode: SelectCategoriesNodeType,
): [AddConstantNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToSelectColumns = (
  sourceNode: AddConstantNodeType,
  targetNode: SelectColumnsNodeType,
): [AddConstantNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToKnowledgeGraph = (
  sourceNode: AddConstantNodeType,
  targetNode: KnowledgeGraphNodeType,
): [AddConstantNodeType, KnowledgeGraphNodeType] => {
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  //Format the Add Constant Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedAddConstantNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: undefined,
      fields: combinedColumnNames,
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.nodeName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedAddConstantNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToMultiCovariantAnalysis = (
  sourceNode: AddConstantNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [AddConstantNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: combinedColumnNames,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToFunctionNode = (
  sourceNode: AddConstantNodeType,
  targetNode: FunctionNodeType,
): [AddConstantNodeType, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    functions: [],
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToAlterColumns = (
  sourceNode: AddConstantNodeType,
  targetNode: AlterColumnsNodeType,
): [AddConstantNodeType, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    alterColumns: [],
    outputFields: [],
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToOutputDataset = (
  sourceNode: AddConstantNodeType,
  targetNode: OutputDatasetNodeType,
): [AddConstantNodeType, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: combinedColumnNames,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToFilter = (
  sourceNode: AddConstantNodeType,
  targetNode: FilterNodeType,
): [AddConstantNodeType, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: combinedColumnNames,
    filterRules: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToGroupBy = (
  sourceNode: AddConstantNodeType,
  targetNode: GroupByNodeType,
): [AddConstantNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    groupByCalcs: [],
    groupByCols: [],
    outputFields: [],
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAddConstantToJoin = (
  sourceNode: AddConstantNodeType,
  targetNode: JoinNodeType,
): [AddConstantNodeType, JoinNodeType] => {
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    objId: sourceNode.data.objId,
    nodeName: sourceNode.data.nodeName,
    fields: combinedColumnNames,
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};

export const connectAddConstantToAppendTables = (
  sourceNode: AddConstantNodeType,
  targetNode: AppendTablesNodeType,
): [AddConstantNodeType, AppendTablesNodeType] => {
  let combinedColumnNames: string[] = sourceNode.data.columnsToAdd.map(
    constant => constant.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  const sourceNodeDataForAppendTablesNode: ConnectToAppendTablesNodeData = {
    objId: sourceNode.data.objId,
    fields: combinedColumnNames,
    processId: sourceNode.data.processId,
  };

  return [
    sourceNode,
    connectNodeToAppendTables(sourceNodeDataForAppendTablesNode, targetNode),
  ];
};

// ====================== ADD CONSTANT AS TARGET ======================

export const disconnectNodeFromAddConstant = <Type>(
  sourceNode: Type,
  targetNode: AddConstantNodeType,
): [Type, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
    fields: undefined,
    outputColumnNames: undefined,
    columnsToAdd: [],
    inputNodeName: undefined,
  };

  return [sourceNode, updatedTargetNode];
};
