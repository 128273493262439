import { Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDatalakeState } from '../../state/datalakeState';
import { useModelingState } from '../../state/modelingState';
import { MarginRightButton } from '../../styles/inputStyles';
import {
  NodeMenuContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
  NodeMenuListItemButton,
  NodeMenuListItemText,
  NodeMenuSelectionList,
} from '../../styles/nodeStyles';
import { Dataset } from '../../types/datalake';
import { InputDatasetNodeType } from '../../types/nodes';

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();
  const { availableFileConfigList } = useDatalakeState();

  const selectedDatasetInputNode = useMemo(
    () => selectedNode as InputDatasetNodeType | undefined,
    [selectedNode],
  );

  const availableDatasets = useMemo(
    () => availableFileConfigList,
    [availableFileConfigList],
  );
  const [selectedDataset, setSelectedDataset] = useState<Dataset | undefined>(
    undefined,
  );

  const handleOnSave = useCallback(
    (savedSelectedNode: InputDatasetNodeType) => {
      // UPDATE SELECTED NODE DATA
      if (nodes != null) {
        const selectedNodeIndex = nodes.findIndex(
          node => node.id === savedSelectedNode.id,
        );

        if (selectedNodeIndex >= 0 && selectedDataset != null) {
          let updatedNodes = [...nodes];
          let updatedSelectedNode: InputDatasetNodeType = nodes[
            selectedNodeIndex
          ] as InputDatasetNodeType;

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            inputDatasetName: selectedDataset.fileNm,
            outputDatasetName: selectedDataset.fileNm,
            nodeName: selectedDataset.fileNm,
            fields: selectedDataset.fldList,
            parquetLocation: selectedDataset.parquetLocation,
          };

          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
        // UPDATE IMPACTED STATE INFORMATION
        onNodeSave(savedSelectedNode.id);
      }
      setSelectedNode(undefined);
    },
    [setSelectedNode, setNodes, onNodeSave, nodes, selectedDataset],
  );

  const handleListItemClick = useCallback(
    (dataset: Dataset) => {
      setSelectedDataset(dataset);
    },
    [setSelectedDataset],
  );

  useEffect(() => {
    // if we do not currently have a selected dataset and our selected node has a dataset assigned to it
    if (
      selectedDataset == null &&
      selectedDatasetInputNode != null &&
      selectedDatasetInputNode.data?.inputDatasetName != null &&
      availableDatasets != null
    ) {
      const datasetIndex = availableDatasets.findIndex(
        dataset =>
          dataset.fileNm === selectedDatasetInputNode.data.inputDatasetName,
      );

      if (datasetIndex >= 0) {
        setSelectedDataset(availableDatasets[datasetIndex]);
      }
    }
  }, [
    availableDatasets,
    setSelectedDataset,
    selectedDataset,
    selectedDatasetInputNode,
  ]);

  if (selectedDatasetInputNode != null && availableDatasets != null) {
    return (
      <NodeMenuContainer>
        <Typography variant="h4" gutterBottom>
          Select an Input Dataset
        </Typography>
        <Typography variant="h6" gutterBottom>
          Available Datasets:
        </Typography>
        {/* TODO: Add logic to handle if dataset list is empty or errored */}
        {/* Maybe make this a virtualized list if dataset count get to be huge */}
        {/* Maybe add some more info about datasets or selected datasets */}
        <NodeMenuSelectionList>
          {availableDatasets.map(dataset => {
            const isSelected = dataset.fileNm === selectedDataset?.fileNm;

            return (
              <NodeMenuListItemButton
                key={`${dataset.PK}-${dataset.fileNm}-${dataset.SK}`}
                selected={isSelected}
                onClick={() => handleListItemClick(dataset)}
              >
                <NodeMenuListItemText
                  primary={dataset.fileNm}
                  isSelected={isSelected}
                />
              </NodeMenuListItemButton>
            );
          })}
        </NodeMenuSelectionList>

        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedDatasetInputNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    // if there is something wrong with the input data and/or there is no input node connected
    return (
      <NodeMenuErrorContainer>
        <Typography variant="h5" gutterBottom>
          There are no inputs found. Did you connect an input node?
        </Typography>
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
