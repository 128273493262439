import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdAddCircle } from 'react-icons/md';
import { theme } from '../../../styles/theme';
import { Edge, InputDatasetNodeType } from '../../../types/nodes';
import AddEdgeForm from './AddEdgeForm';
import { FitContentButton } from '../../../styles/inputStyles';

const FormContainer = styled(Box)({
  gridTemplateColumns: 'repeat(2, minmax(9.375rem, 1fr)) 3rem',
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  minWidth: '2rem',
  maxWidth: '3rem',
}) as typeof IconButton;

const StyledDivider = styled(Divider)({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}) as typeof Divider;

export interface AddTripleFormProps {
  entityA: string;
  entityB: string;
  edges: Edge[];
  availableDatasets: InputDatasetNodeType[];
  id: string;
  handleUpdateEntity: (
    id: string,
    type: 'entityA' | 'entityB',
    newValue: string,
  ) => void;
  handleDelete: (id: string) => void;
  handleDeleteEdge: (tripleId: string, edgeId: string) => void;
  handleUpdateEdge: (
    tripleId: string,
    edgeId: string,
    type: 'columnA' | 'columnB',
    newValue: string,
  ) => void;
  handleAddNewEdge: (tripleId: string) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({
    entityA,
    entityB,
    edges,
    availableDatasets,
    id,
    handleUpdateEntity,
    handleDelete,
    handleDeleteEdge,
    handleUpdateEdge,
    handleAddNewEdge,
  }: AddTripleFormProps) => {
    const entityAName = useMemo(() => entityA, [entityA]);
    const entityBName = useMemo(() => entityB, [entityB]);
    const currentEdges = useMemo(() => edges, [edges]);
    const availableDatasetNodes = useMemo(
      () => availableDatasets,
      [availableDatasets],
    );

    const [availableColumnANames, setAvailableColumnANames] = useState<
      string[]
    >([]);
    const [availableColumnBNames, setAvailableColumnBNames] = useState<
      string[]
    >([]);

    const handleUpdateAvailableColumnNames = useCallback(
      (type: 'columnA' | 'columnB', datasetName: string) => {
        const selectedDatasetIndex = availableDatasetNodes.findIndex(
          datasetNode => datasetNode.data.nodeName === datasetName,
        );

        if (selectedDatasetIndex >= 0) {
          const selectedDataset = availableDatasetNodes[selectedDatasetIndex];

          if (type === 'columnA') {
            setAvailableColumnANames(selectedDataset.data?.fields ?? []);
          }
          if (type === 'columnB') {
            setAvailableColumnBNames(selectedDataset.data?.fields ?? []);
          }
        }
      },
      [
        availableDatasetNodes,
        setAvailableColumnANames,
        setAvailableColumnBNames,
      ],
    );

    useEffect(() => {
      handleUpdateAvailableColumnNames('columnA', entityAName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityAName]);

    useEffect(() => {
      handleUpdateAvailableColumnNames('columnB', entityBName);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityBName]);

    return (
      <div>
        <FormContainer>
          <StyledFormControl>
            <InputLabel id="entity-a-name-select-label">Entity A</InputLabel>
            <Select
              labelId="entity-a-name-select-label"
              id="entity-a-name-select"
              label="Entity A"
              value={entityAName}
              onChange={(event: SelectChangeEvent) => {
                handleUpdateEntity(id, 'entityA', event.target.value);
              }}
            >
              {availableDatasetNodes.sort().map(datasetNode => (
                <MenuItem value={datasetNode.data.nodeName}>
                  {datasetNode.data.nodeName}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <StyledFormControl>
            <InputLabel id="entity-b-name-select-label">Entity B</InputLabel>
            <Select
              labelId="entity-b-name-select-label"
              id="entity-b-name-select"
              label="Entity B"
              value={entityBName}
              onChange={(event: SelectChangeEvent) => {
                handleUpdateEntity(id, 'entityB', event.target.value);
              }}
            >
              {availableDatasetNodes.sort().map(datasetNode => (
                <MenuItem value={datasetNode.data.nodeName}>
                  {datasetNode.data.nodeName}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <StyledIconButton
            aria-label="delete"
            className="delete-node-button"
            onClick={() => {
              handleDelete(id);
            }}
          >
            <FaTrash
              size="2rem"
              color={theme.palette.error.main}
              style={{ display: 'inherit' }}
            />
          </StyledIconButton>
        </FormContainer>
        <Typography variant="subtitle1" gutterBottom>
          Edges:
        </Typography>
        {currentEdges.map(edge => {
          return (
            <AddEdgeForm
              id={edge.id}
              columnA={edge.columnAName}
              columnB={edge.columnBName}
              columnANames={availableColumnANames}
              columnBNames={availableColumnBNames}
              tripleId={id}
              handleDelete={handleDeleteEdge}
              handleUpdateColumnName={handleUpdateEdge}
              key={`${edge.id}`}
            />
          );
        })}

        <FitContentButton
          variant="contained"
          color="secondary"
          onClick={() => handleAddNewEdge(id)}
          endIcon={<MdAddCircle color={theme.palette.secondary.contrastText} />}
          size="small"
        >
          Add Edge
        </FitContentButton>

        <StyledDivider />
      </div>
    );
  },
);
