import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useMemo } from 'react';
import {
  ProcessFlowRowData,
  ProcessFlowTableColumn,
} from '../../types/processFlows';
import { Order } from '../../types/tables';

interface ProcessFlowSelectionTableHeaderProps {
  columns: readonly ProcessFlowTableColumn[];
  orderByProp: string;
  orderProp: Order;
  handleRequestSort: (property: keyof ProcessFlowRowData) => void;
}

export default function ProcessFlowSelectionTableHeader({
  columns,
  orderByProp,
  orderProp,
  handleRequestSort,
}: ProcessFlowSelectionTableHeaderProps) {
  const orderBy = useMemo(() => orderByProp, [orderByProp]);
  const order = useMemo(() => orderProp, [orderProp]);

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{
              minWidth: column.minWidth,
              fontWeight: 'bold',
            }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={() => handleRequestSort(column.id)}
              disabled={column.id === 'action'}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
