import { Box, styled } from '@mui/material';
import DataGridPageBody from '../components/dataGrid/body';
import { theme } from '../styles/theme';
import SetupReportsData from '../components/reports/setupReportsData';

const DataGridPageContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: theme.defaultPaddingRem,
}) as typeof Box;

export default function DataGridPage() {
  return (
    <DataGridPageContainer>
      <SetupReportsData>
        <DataGridPageBody />
      </SetupReportsData>
    </DataGridPageContainer>
  );
}
