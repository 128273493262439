import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { FaCheckCircle, FaEdit, FaPlay, FaTrash } from 'react-icons/fa';
import { FaCircleExclamation } from 'react-icons/fa6';
import { CenterAlignedRowFlex } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  ProcessFlowRowData,
  ProcessFlowTableColumn,
  ProcessFlowType,
} from '../../types/processFlows';
import { formateDateWithoutTimezone } from '../../utils/generalFunctions';

interface ProcessFlowSelectionTableBodyProps {
  columns: readonly ProcessFlowTableColumn[];
  visibleRowsProp: ProcessFlowRowData[];
  handleExistingProcessFlowClick: (processFlowClicked: ProcessFlowType) => void;
  setSelectedErrorMsgProp: (errorMsg: string | undefined) => void;
  setProcessFlowToRunProp: (
    processFlow: ProcessFlowRowData | undefined,
  ) => void;
  setProcessFlowToDeleteProp: (
    processFlow: ProcessFlowRowData | undefined,
  ) => void;
}

export default function ProcessFlowSelectionTableBody({
  columns,
  visibleRowsProp,
  handleExistingProcessFlowClick,
  setSelectedErrorMsgProp,
  setProcessFlowToRunProp,
  setProcessFlowToDeleteProp,
}: ProcessFlowSelectionTableBodyProps) {
  const visibleRows = useMemo(() => visibleRowsProp, [visibleRowsProp]);

  return (
    <TableBody>
      {visibleRows.map((rowData, index) => {
        const processFlowHasError = rowData.lastRunStatus
          .toLowerCase()
          .includes('failed');

        return (
          <TableRow
            tabIndex={-1}
            key={`${rowData.objId}-${rowData.name}`}
            sx={{
              backgroundColor:
                index % 2 === 0 ? 'none' : theme.palette.grey[100],
            }}
          >
            <TableCell key={columns[0].id} align={columns[0].align}>
              {rowData.name}
            </TableCell>

            <TableCell key={columns[1].id} align={columns[1].align}>
              {rowData.createdBy}
            </TableCell>

            <TableCell key={columns[2].id} align={columns[2].align}>
              {rowData.numberOfNodes}
            </TableCell>

            <TableCell key={columns[3].id} align={columns[3].align}>
              {rowData.lastRunDate === ''
                ? '-'
                : formateDateWithoutTimezone(
                    new Date(rowData.lastRunDate.split(' ')[0]),
                    'MMM dd, yyyy',
                  )}
            </TableCell>

            <Tooltip
              title="Click to view error message"
              placement="top-start"
              arrow
              disableHoverListener={!processFlowHasError}
            >
              <TableCell
                key={columns[4].id}
                align={columns[4].align}
                onClick={() => {
                  if (processFlowHasError)
                    setSelectedErrorMsgProp(rowData.lastRunErrorMsg);
                }}
                sx={{
                  cursor: processFlowHasError ? 'pointer' : 'default',
                }}
              >
                <CenterAlignedRowFlex>
                  <Typography fontSize="0.875rem">
                    {rowData.lastRunStatus === '' ? '-' : rowData.lastRunStatus}
                  </Typography>
                  {rowData.lastRunStatus === 'Successful' && (
                    <FaCheckCircle
                      size="1rem"
                      color={theme.palette.success.main}
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  )}
                  {processFlowHasError && (
                    <FaCircleExclamation
                      size="1rem"
                      color={theme.palette.error.main}
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  )}
                </CenterAlignedRowFlex>
              </TableCell>
            </Tooltip>

            <TableCell
              key={columns[5].id}
              align={columns[5].align}
              width="fit-content"
            >
              <Tooltip title="Run Process Flow" placement="top" arrow>
                <IconButton
                  aria-label="run process flow"
                  onClick={() => setProcessFlowToRunProp(rowData)}
                >
                  <FaPlay size="1rem" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Edit Process Flow" placement="top" arrow>
                <IconButton
                  aria-label="edit process flow"
                  onClick={() => handleExistingProcessFlowClick(rowData)}
                >
                  <FaEdit size="1rem" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Process Flow">
                <IconButton
                  aria-label="delete"
                  onClick={() => setProcessFlowToDeleteProp(rowData)}
                >
                  <FaTrash size="1rem" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
