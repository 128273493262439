import { Box, Typography, styled } from '@mui/material';
import { theme } from '../../styles/theme';
import { useMemo } from 'react';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}) as typeof Box;

interface DataGridHeaderProps {
  fileName: string;
}

export default function DataGridHeader({ fileName }: DataGridHeaderProps) {
  const pageHeader = useMemo(() => fileName, [fileName]);

  return (
    <HeaderContainer>
      <Typography variant="h4">{pageHeader}</Typography>
    </HeaderContainer>
  );
}
