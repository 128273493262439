import { Typography, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import { FaTableCellsLarge } from 'react-icons/fa6';
import { TbRowInsertTop } from 'react-icons/tb';
import { Handle, Position } from 'reactflow';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';
import { AppendTablesNodeData } from '../../types/nodes';
import DeleteNodeButton from './DeleteNodeButton';
import { NodeContainer, StyledSourceHandle } from '../../styles/nodeStyles';

// TODO: update this position stuff there's probably a better way
const NodeNameTypography = styled(Typography)<{
  showInputsMatchError: boolean;
}>(({ showInputsMatchError }) => ({
  position: 'absolute',
  top: 68,
  left: -43,
  textAlign: 'center',
  width: '8rem',
  overflowWrap: 'break-word',
  display: 'inline',
  color: showInputsMatchError
    ? theme.palette.error.main
    : theme.palette.text.primary,
}));

const StyledNodeContainer = styled(NodeContainer)<{
  showInputsMatchError: boolean;
}>(({ showInputsMatchError }) => ({
  flexDirection: 'column',
  alignItems: 'end',
  backgroundColor: showInputsMatchError ? theme.palette.error.main : 'white',
}));

export default memo((props: { data: AppendTablesNodeData }) => {
  const { selectedNode } = useModelingState();

  const connectionsAreValid = useMemo(
    () =>
      props.data.processId !== -1 && props.data?.inputDatasetObjIds.length > 1,
    [props],
  );
  const inputsMatch = useMemo(() => props.data.inputsMatch, [props]);
  const showInputsMatchError = useMemo(
    () => connectionsAreValid && !inputsMatch,
    [connectionsAreValid, inputsMatch],
  );
  const isConnectable = useMemo(
    () => connectionsAreValid && inputsMatch,
    [connectionsAreValid, inputsMatch],
  );
  const nodeName = useMemo(
    () => props.data.nodeName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, selectedNode],
  );

  return (
    <div>
      <StyledNodeContainer showInputsMatchError={showInputsMatchError}>
        <DeleteNodeButton nodeId={props.data.objId} />

        <FaTableCellsLarge size="2rem" color={theme.palette.text.primary} />
        <TbRowInsertTop
          size="2rem"
          color={theme.palette.text.primary}
          style={{ marginTop: '-0.25rem' }}
        />

        <StyledSourceHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          id="target"
          position={Position.Left}
          isConnectable={true}
        />

        <NodeNameTypography
          variant="caption"
          showInputsMatchError={showInputsMatchError}
        >
          {showInputsMatchError ? 'Input fields do not match' : nodeName}
        </NodeNameTypography>
      </StyledNodeContainer>
    </div>
  );
});
