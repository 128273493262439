import { IconButton, styled } from '@mui/material';
import { memo } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';

const StyledIconButton = styled(IconButton)({
  display: 'none',
  position: 'absolute',
  top: '-0.5rem',
  left: '-0.75rem',
  height: '0.5rem',
  width: 'fit-content',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
}) as typeof IconButton;

export interface DeleteNodeButtonProps {
  nodeId: string;
}
export default memo(({ nodeId }: DeleteNodeButtonProps) => {
  const { onNodeDelete } = useModelingState();

  return (
    <StyledIconButton
      aria-label="delete"
      className="delete-node-button"
      onClick={event => {
        event.stopPropagation();
        onNodeDelete(nodeId);
      }}
    >
      <FaTrash
        size="0.5rem"
        color={theme.palette.error.main}
        style={{ display: 'inherit' }}
      />
    </StyledIconButton>
  );
});
