import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { MdAddCircle } from 'react-icons/md';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';
import { FilterNodeType, NodeFilterType } from '../../types/nodes';

import { v4 as uuidv4 } from 'uuid';
import { TwoMarginBottomDivider } from '../../styles/commonStyles';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../styles/nodeStyles';
import { FitContentButton, MarginRightButton } from '../../styles/inputStyles';

const InputContainer = styled(Box)({
  display: 'flex',
  width: 'inherit',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}) as typeof Box;

const StyledTextField = styled(TextField)({
  display: 'flex',
  width: 'inherit',
}) as typeof TextField;

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedFilterNode = useMemo(
    () => selectedNode as FilterNodeType | undefined,
    [selectedNode],
  );

  const [filters, setFilters] = useState<NodeFilterType[]>(
    selectedFilterNode != null ? selectedFilterNode.data.filterRules : [],
  );
  const [nodeName, setNodeName] = useState(
    selectedFilterNode?.data.nodeName || '',
  );

  const handleValueUpdate = useCallback(
    (id: string, newValue: string) => {
      let updatedFilters = [...filters];
      const indexToUpdate = updatedFilters.findIndex(
        (constant: NodeFilterType) => constant.id === id,
      );

      if (indexToUpdate >= 0) {
        let filterToUpdate = updatedFilters[indexToUpdate];

        filterToUpdate.filterRule = newValue;

        updatedFilters[indexToUpdate] = filterToUpdate;
      }
      setFilters([...updatedFilters]);
    },
    [filters, setFilters],
  );

  const handleDeleteFilter = useCallback(
    (id: string) => {
      const indexToDelete = filters.findIndex(
        nodeFilter => nodeFilter.id === id,
      );

      if (indexToDelete >= 0) {
        let updatedNodeFilters = [...filters];
        updatedNodeFilters.splice(indexToDelete, 1);
        setFilters([...updatedNodeFilters]);
      }
    },
    [filters, setFilters],
  );

  const handleAddNewFilter = useCallback(() => {
    const newFilter: NodeFilterType = {
      filterRule: '',
      id: uuidv4(),
    };
    setFilters([...filters].concat(newFilter));
  }, [filters, setFilters]);

  // If there are no constants, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedFilterNode != null && filters.length === 0) {
      handleAddNewFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterNode, filters]);

  // TODO: add error handling here like if an input is invalid or there was an error updating the nodes
  const handleOnSave = useCallback(
    (selectedNode: FilterNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === selectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          //only grab constants that are valid
          const updatedFilters = filters.filter(
            nodeFilter => nodeFilter.filterRule.trim() !== '',
          );

          let updatedSelectedNode = { ...selectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            filterRules: [...updatedFilters],
            nodeName: nodeName.trim(),
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(selectedNode.id);
      setSelectedNode(undefined);
      setFilters([]);
      setNodeName('');
    },
    [
      filters,
      nodes,
      nodeName,
      setNodes,
      onNodeSave,
      setSelectedNode,
      setNodeName,
    ],
  );

  if (
    selectedFilterNode != null &&
    selectedFilterNode.data?.inputObjId != null
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Add Filter
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <ScrollableContentContainer>
          {filters.map((nodeFilter, index) => {
            return (
              <>
                {index > 0 && <TwoMarginBottomDivider />}
                <InputContainer>
                  <StyledTextField
                    variant="outlined"
                    label="Filter Rule"
                    placeholder="Filter Rule"
                    value={nodeFilter.filterRule}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleValueUpdate(nodeFilter.id, event.target.value);
                    }}
                  />
                  <IconButton
                    aria-label="delete"
                    className="delete-node-button"
                    onClick={() => {
                      handleDeleteFilter(nodeFilter.id);
                    }}
                  >
                    <FaTrash
                      size="2rem"
                      color={theme.palette.error.main}
                      style={{ display: 'inherit' }}
                    />
                  </IconButton>
                </InputContainer>
              </>
            );
          })}
          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewFilter}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
          >
            Add Filter
          </FitContentButton>
        </ScrollableContentContainer>
        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedFilterNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  }

  // if there is something wrong with the input data and/or there is no input node connected
  return (
    <NodeMenuErrorContainer>
      <Typography variant="h5" gutterBottom>
        There are no input columns found. Did you connect an input node?
      </Typography>
      <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
        Cancel
      </Button>
    </NodeMenuErrorContainer>
  );
});
