import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import App from './App';
import { ProvideAuth } from './hooks/useAuth';
import reportWebVitals from './reportWebVitals';
import { theme } from './styles/theme';
import styled from '@emotion/styled';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled(ToastContainer)`
  --toastify-icon-color-success: ${theme.palette.success.main};
  --toastify-color-progress-success: ${theme.palette.success.main};

  --toastify-icon-color-error: ${theme.palette.error.main};
  --toastify-color-progress-error: ${theme.palette.error.main};

  --toastify-icon-color-warning: ${theme.palette.warning.main};
  --toastify-color-progress-warning: ${theme.palette.warning.main};

  --toastify-icon-color-info: ${theme.palette.secondary.main};
  --toastify-color-progress-info: ${theme.palette.secondary.main};
`;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ProvideAuth>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <App />
            <StyledToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </QueryParamProvider>
        </BrowserRouter>
      </ProvideAuth>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
