import { Box, IconButton, TextField, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { theme } from '../../../styles/theme';

const FormContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: theme.spacing(2),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const InputsContainer = styled(Box)({
  display: 'flex',
  width: 'inherit',
  gap: theme.spacing(1),
}) as typeof Box;

export interface AddColumnConstantFormProps {
  name: string;
  functionRules: string;
  id: string;
  handleUpdate: (
    id: string,
    type: 'name' | 'function',
    newValue: string,
  ) => void;
  handleDelete: (id: string) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({
    name,
    functionRules,
    id,
    handleUpdate,
    handleDelete,
  }: AddColumnConstantFormProps) => {
    const columnName = useMemo(() => name, [name]);
    const columnFunctionRules = useMemo(() => functionRules, [functionRules]);

    return (
      <FormContainer>
        <InputsContainer>
          <TextField
            variant="outlined"
            label="Column Name"
            placeholder="Column Name"
            value={columnName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdate(id, 'name', event.target.value);
            }}
            sx={{ display: 'flex', width: 'inherit' }}
          />
          <IconButton
            aria-label="delete"
            className="delete-node-button"
            onClick={() => {
              handleDelete(id);
            }}
          >
            <FaTrash
              size="2rem"
              color={theme.palette.error.main}
              style={{ display: 'inherit' }}
            />
          </IconButton>
        </InputsContainer>

        <TextField
          variant="outlined"
          label="Function Rules"
          placeholder="SELECT * FROM COLUMN_NAME"
          value={columnFunctionRules}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleUpdate(id, 'function', event.target.value);
          }}
          multiline
          sx={{ width: 'inherit' }}
        />
      </FormContainer>
    );
  },
);
