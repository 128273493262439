import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  MultiCovariantAnalysisNodeType,
  FunctionNodeType,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  OutputDatasetNodeType,
  SelectCategoriesNodeType,
  FilterNodeType,
  GroupByNodeType,
  JoinNodeType,
  SelectColumnsNodeType,
  AppendTablesNodeType,
} from '../../types/nodes';
import {
  ConnectToAppendTablesNodeData,
  connectNodeToAppendTables,
} from './appendTablesNodeFunctions';
import { ConnectToJoinNodeData, connectNodeToJoin } from './joinNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

// ====================== ALTER COLUMNS AS TARGET ======================

export const disconnectNodeFromAlterColumns = <Type>(
  sourceNode: Type,
  targetNode: AlterColumnsNodeType,
): [Type, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
    alterColumns: [],
    outputFields: undefined,
    fields: undefined,
    inputNodeName: undefined,
  };

  return [sourceNode, updatedTargetNode];
};

// ====================== ALTER COLUMNS AS SOURCE ======================

export const connectAlterColumnsToSelectCategories = (
  sourceNode: AlterColumnsNodeType,
  targetNode: SelectCategoriesNodeType,
): [AlterColumnsNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: sourceNode.data.outputFields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToSelectColumns = (
  sourceNode: AlterColumnsNodeType,
  targetNode: SelectColumnsNodeType,
): [AlterColumnsNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: sourceNode.data.outputFields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToKnowledgeGraph = (
  sourceNode: AlterColumnsNodeType,
  targetNode: KnowledgeGraphNodeType,
): [AlterColumnsNodeType, KnowledgeGraphNodeType] => {
  //Format the Alter Columns Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedAlterColumnsNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: undefined,
      fields: sourceNode.data.outputFields,
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.nodeName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedAlterColumnsNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToMultiCovariantAnalysis = (
  sourceNode: AlterColumnsNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [AlterColumnsNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.outputFields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToFunctionNode = (
  sourceNode: AlterColumnsNodeType,
  targetNode: FunctionNodeType,
): [AlterColumnsNodeType, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    functions: [],
    fields: sourceNode.data.outputFields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToAddConstant = (
  sourceNode: AlterColumnsNodeType,
  targetNode: AddConstantNodeType,
): [AlterColumnsNodeType, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.outputFields,
    outputColumnNames: sourceNode.data.outputFields,
    columnsToAdd: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToOutputDataset = (
  sourceNode: AlterColumnsNodeType,
  targetNode: OutputDatasetNodeType,
): [AlterColumnsNodeType, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.outputFields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToFilter = (
  sourceNode: AlterColumnsNodeType,
  targetNode: FilterNodeType,
): [AlterColumnsNodeType, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.outputFields,
    filterRules: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToGroupBy = (
  sourceNode: AlterColumnsNodeType,
  targetNode: GroupByNodeType,
): [AlterColumnsNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.outputFields,
    groupByCalcs: [],
    groupByCols: [],
    outputFields: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAlterColumnsToJoin = (
  sourceNode: AlterColumnsNodeType,
  targetNode: JoinNodeType,
): [AlterColumnsNodeType, JoinNodeType] => {
  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    objId: sourceNode.data.objId,
    nodeName: sourceNode.data.nodeName,
    fields: sourceNode.data?.outputFields ?? [],
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};

export const connectAlterColumnsToAppendTables = (
  sourceNode: AlterColumnsNodeType,
  targetNode: AppendTablesNodeType,
): [AlterColumnsNodeType, AppendTablesNodeType] => {
  const sourceNodeDataForAppendTablesNode: ConnectToAppendTablesNodeData = {
    objId: sourceNode.data.objId,
    fields: sourceNode.data?.outputFields ?? [],
    processId: sourceNode.data.processId,
  };

  return [
    sourceNode,
    connectNodeToAppendTables(sourceNodeDataForAppendTablesNode, targetNode),
  ];
};
