import { Box, styled } from '@mui/material';
import ReportsPageBody from '../components/reports/body';
import SetupReportsData from '../components/reports/setupReportsData';
import { theme } from '../styles/theme';

const ReportsPageContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: theme.defaultPaddingRem,
}) as typeof Box;

export default function ReportsPage() {
  return (
    <ReportsPageContainer>
      <SetupReportsData>
        <ReportsPageBody />
      </SetupReportsData>
    </ReportsPageContainer>
  );
}
