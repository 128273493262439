import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';
import { MultiCovariantAnalysisNodeType } from '../../types/nodes';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../styles/nodeStyles';
import { MarginRightButton } from '../../styles/inputStyles';

const FullFlexFormControl = styled(FormControl)({
  display: 'flex',
  flex: 1,
}) as typeof FormControl;

const SelectButtonsContainer = styled(Box)({
  display: 'flex',
  width: 'inherit',
  gap: theme.spacing(1),
}) as typeof Box;

const InputsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(1),
  flex: 1,
}) as typeof Box;

const ContentContainer = styled(Box)({
  gridTemplateColumns: 'repeat(2, minmax(9.375rem, 1fr))',
  display: 'grid',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes } = useModelingState();

  const selectedMultiCovariantAnalysisNode = useMemo(
    () => selectedNode as MultiCovariantAnalysisNodeType | undefined,
    [selectedNode],
  );
  const availableFields = useMemo(
    () => selectedMultiCovariantAnalysisNode?.data?.fields ?? [],
    [selectedMultiCovariantAnalysisNode],
  );

  const [nodeName, setNodeName] = useState(
    selectedMultiCovariantAnalysisNode?.data?.nodeName || '',
  );
  const [selectedFactors, setSelectedFactors] = useState(
    selectedMultiCovariantAnalysisNode?.data?.factors ?? [],
  );
  const [correlateToName, setCorrelateToName] = useState(
    selectedMultiCovariantAnalysisNode?.data?.correlateTo ?? '',
  );
  const [formIsValid, setFormIsValid] = useState(false);

  const handleOnSave = useCallback(
    (selectedNode: MultiCovariantAnalysisNodeType) => {
      // UPDATE SELECTED NODE DATA
      if (nodes != null) {
        const selectedNodeIndex = nodes.findIndex(
          node => node.id === selectedNode.id,
        );

        if (selectedNodeIndex >= 0) {
          let updatedNodes = [...nodes];
          let updatedSelectedNode = nodes[
            selectedNodeIndex
          ] as MultiCovariantAnalysisNodeType;

          //TODO: update the rest of the node data
          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            nodeName: nodeName.trim(),
            factors: [...selectedFactors],
            correlateTo: correlateToName,
          };

          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      setSelectedNode(undefined);
      setNodeName('');
      setCorrelateToName('');
      setSelectedFactors([]);
    },
    [
      nodes,
      setSelectedNode,
      nodeName,
      selectedFactors,
      correlateToName,
      setNodes,
    ],
  );

  const handleSelectAllFactors = useCallback(() => {
    setSelectedFactors([...availableFields]);
  }, [availableFields]);

  const handleDeselectAllFactors = useCallback(() => {
    setSelectedFactors([]);
  }, []);

  useEffect(() => {
    setFormIsValid(
      selectedMultiCovariantAnalysisNode != null &&
        nodeName.trim() !== '' &&
        selectedFactors.length > 0 &&
        correlateToName.trim() !== '',
    );
  }, [
    correlateToName,
    nodeName,
    selectedFactors.length,
    selectedMultiCovariantAnalysisNode,
  ]);

  if (
    selectedMultiCovariantAnalysisNode != null &&
    selectedMultiCovariantAnalysisNode.data?.fields != null
  ) {
    return (
      <NodeMenuContainer>
        {/* =============== HEADER =============== */}
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            MCA Data
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <ContentContainer>
          <FullFlexFormControl>
            <InputLabel id="correlate-column-select-label">
              Columns to Correlate
            </InputLabel>
            <Select
              labelId="correlate-column-select-label"
              id="correlate-column-select"
              label="Columns to Correlate"
              value={correlateToName}
              onChange={(event: SelectChangeEvent<typeof correlateToName>) => {
                const {
                  target: { value },
                } = event;
                setCorrelateToName(value);
              }}
            >
              {availableFields.sort().map(columnName => (
                <MenuItem key={columnName} value={columnName}>
                  {columnName}
                </MenuItem>
              ))}
            </Select>
          </FullFlexFormControl>

          <InputsContainer>
            <FullFlexFormControl>
              <InputLabel id="factors-select-label">
                Associated Factors
              </InputLabel>
              <Select
                labelId="factors-select-label"
                id="factors-select"
                label="Associated Factors"
                multiple
                value={selectedFactors}
                renderValue={selected => selected.join(', ')}
                onChange={(
                  event: SelectChangeEvent<typeof selectedFactors>,
                ) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedFactors(
                    typeof value === 'string' ? value.split(',') : value,
                  );
                }}
              >
                {availableFields.sort().map(columnName => (
                  <MenuItem value={columnName}>
                    <Checkbox
                      checked={selectedFactors.indexOf(columnName) > -1}
                    />
                    <ListItemText primary={columnName} />
                  </MenuItem>
                ))}
              </Select>
            </FullFlexFormControl>
            <SelectButtonsContainer>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSelectAllFactors}
              >
                Select All
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDeselectAllFactors}
              >
                Deselect All
              </Button>
            </SelectButtonsContainer>
          </InputsContainer>
        </ContentContainer>

        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedMultiCovariantAnalysisNode)}
            disabled={!formIsValid}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    // if there is something wrong with the input data and/or there is no input node connected
    return (
      <NodeMenuErrorContainer>
        <Typography variant="h5" gutterBottom>
          There are no inputs found. Did you connect an input node?
        </Typography>
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
