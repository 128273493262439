import { Typography, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import { MdJoinInner } from 'react-icons/md';

import { Handle, Position } from 'reactflow';
import { useModelingState } from '../../state/modelingState';
import { NodeContainer, StyledSourceHandle } from '../../styles/nodeStyles';
import { theme } from '../../styles/theme';
import { JoinNodeData } from '../../types/nodes';
import DeleteNodeButton from './DeleteNodeButton';

// TODO: update this position stuff there's probably a better way
const NodeNameTypography = styled(Typography)({
  position: 'absolute',
  top: 40,
  left: -43,
  textAlign: 'center',
  width: '8rem',
  overflowWrap: 'break-word',
  display: 'inline',
}) as typeof Typography;

export default memo((props: { data: JoinNodeData }) => {
  const { selectedNode } = useModelingState();

  const isConnectable = useMemo(
    () =>
      props.data.processId !== -1 &&
      props.data?.leftTableNameObjId != null &&
      props.data?.rightTableNameObjId != null &&
      props.data.outputFieldList.length > 0,
    [props],
  );

  const nodeName = useMemo(
    () => props.data.nodeName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, selectedNode],
  );

  return (
    <div>
      <NodeContainer>
        <DeleteNodeButton nodeId={props.data.objId} />

        <MdJoinInner size="2rem" color={theme.palette.text.primary} />

        <StyledSourceHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
        <Handle type="target" position={Position.Left} isConnectable={true} />

        <NodeNameTypography variant="caption">{nodeName}</NodeNameTypography>
      </NodeContainer>
    </div>
  );
});
