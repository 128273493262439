import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  capitalize,
  styled,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { theme } from '../../styles/theme';
import { Report } from '../../types/reports';
import {
  PLOT_TYPES_ARRAY,
  PlotTypes,
  setUpQueryParams,
} from '../../types/plots';
import { useNavigate } from 'react-router-dom';
import { useReportsState } from '../../state/reportsState';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}) as typeof Box;

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'visible',
})<{ visible: boolean }>(({ visible }) => ({
  display: 'flex',
  width: visible ? '11rem' : '2rem', // creates the wipe out effect
  opacity: visible ? 1 : 0,
  transition: 'all 300ms ease-in-out', // smooth transition
}));

const RotatableIconButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'rotate',
})<{ rotate: boolean }>(({ rotate }) => ({
  transform: rotate ? 'rotate(45deg)' : '',
  transition: 'transform 300ms ease', // smooth transition
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

interface ReportsHeaderProps {
  fileName: string;
  showAddButton: boolean;
  fileSK?: string;
  fileClient?: string;
  fileCSVKey?: string;
}

export default function ReportsHeader({
  fileName,
  showAddButton,
  fileCSVKey,
  fileClient,
  fileSK,
}: ReportsHeaderProps) {
  const navigate = useNavigate();
  const { setSelectedReport } = useReportsState();

  const pageHeader = useMemo(() => fileName, [fileName]);
  const displayAddButton = useMemo(() => showAddButton, [showAddButton]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [plotTypeValue, setPlotTypeValue] = useState('');

  const handlePlusButtonClick = useCallback(() => {
    setShowDropdown(!showDropdown);
  }, [showDropdown]);

  const handleNewReportClick = useCallback(
    (plotType: PlotTypes) => {
      if (fileSK != null && fileClient != null && fileCSVKey != null) {
        const id = uuidv4();
        const newReport: Report = {
          name: 'Report',
          id,
          xAxis: '',
          yAxis: '',
          xAxisReducer: false,
          yAxisReducer: false,
          type: plotType,
          fileSK,
          csvKey: fileCSVKey,
          client: fileClient,
          PK: `${fileClient}#REPORT`,
          SK: `${fileSK}#${id}`,
        };

        // the plot uses query params as state variables, so set those up here
        const queryParams = setUpQueryParams(newReport);

        setSelectedReport(newReport);
        navigate(`${newReport.id}/plot${queryParams}`);
      } else {
        toast.error('Sorry there was a problem creating your report');
      }
    },
    [fileCSVKey, fileClient, fileSK, navigate, setSelectedReport],
  );

  return (
    <HeaderContainer>
      <Typography variant="h4">{pageHeader}</Typography>
      {displayAddButton && (
        <Tooltip title="Create New Report" placement="top" arrow>
          <RotatableIconButton
            aria-label="create report"
            color="secondary"
            onClick={handlePlusButtonClick}
            rotate={showDropdown}
          >
            <FaCirclePlus size="2rem" />
          </RotatableIconButton>
        </Tooltip>
      )}

      <StyledFormControl
        visible={showDropdown}
        disabled={!showDropdown}
        size="small"
      >
        <InputLabel id="new-report-type-select-label">
          New Report Type
        </InputLabel>
        <Select
          labelId="new-report-type-select-label"
          id="new-report-type-select"
          label="New Report Type"
          value={plotTypeValue}
        >
          {PLOT_TYPES_ARRAY.map(plotType => (
            <MenuItem
              key={plotType}
              value={plotType}
              onClick={() => {
                setPlotTypeValue(plotType);
                handleNewReportClick(plotType);
              }}
            >
              {capitalize(plotType)}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </HeaderContainer>
  );
}
