import { styled } from '@mui/material';
import React from 'react';
import { NodeTypeNames } from '../../types/nodes';

const TransparentDiv = styled('div')({
  backgroundColor: 'transparent',
});

const StyledContainer = styled(TransparentDiv)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export interface DraggableNodeContainerProps {
  nodeType: NodeTypeNames;
  label: string;
  children: React.ReactNode;
}

export default function DraggableNodeContainer(
  props: DraggableNodeContainerProps,
) {
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: NodeTypeNames,
  ) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <StyledContainer
      id={`dnd-${props.nodeType}`}
      draggable
      onDragStart={(event: React.DragEvent<HTMLDivElement>) =>
        onDragStart(event, props.nodeType)
      }
    >
      {props.children}
      <TransparentDiv>{props.label}</TransparentDiv>
    </StyledContainer>
  );
}
