import { Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useModelingState } from '../../../state/modelingState';
import { theme } from '../../../styles/theme';
import { FunctionNodeType, NodeFunctionType } from '../../../types/nodes';

import { v4 as uuidv4 } from 'uuid';
import { TwoMarginBottomDivider } from '../../../styles/commonStyles';
import AddFunctionForm from './AddFunctionForm';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../../styles/nodeStyles';
import {
  FitContentButton,
  MarginRightButton,
} from '../../../styles/inputStyles';

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedFunctionNode = useMemo(
    () => selectedNode as FunctionNodeType | undefined,
    [selectedNode],
  );

  const [functions, setFunctions] = useState<NodeFunctionType[]>(
    selectedFunctionNode != null ? selectedFunctionNode.data.functions : [],
  );
  const [nodeName, setNodeName] = useState(
    selectedFunctionNode?.data.nodeName || '',
  );

  const handleValueUpdate = useCallback(
    (id: string, type: 'name' | 'function', newValue: string) => {
      let updatedFunctions = [...functions];
      const indexToUpdate = updatedFunctions.findIndex(
        (constant: NodeFunctionType) => constant.id === id,
      );

      if (indexToUpdate >= 0) {
        let functionToUpdate = updatedFunctions[indexToUpdate];

        if (type === 'name') {
          functionToUpdate.columnName = newValue;
        }
        if (type === 'function') {
          functionToUpdate.functionRules = newValue;
        }

        updatedFunctions[indexToUpdate] = functionToUpdate;
      }
      setFunctions([...updatedFunctions]);
    },
    [functions, setFunctions],
  );

  const handleDeleteFunction = useCallback(
    (id: string) => {
      const indexToDelete = functions.findIndex(
        nodeFunction => nodeFunction.id === id,
      );

      if (indexToDelete >= 0) {
        let updatedNodeFunctions = [...functions];
        updatedNodeFunctions.splice(indexToDelete, 1);
        setFunctions([...updatedNodeFunctions]);
      }
    },
    [functions, setFunctions],
  );

  const handleAddNewFunction = useCallback(() => {
    const newFunction: NodeFunctionType = {
      columnName: '',
      functionRules: '',
      id: uuidv4(),
    };
    setFunctions([...functions].concat(newFunction));
  }, [functions, setFunctions]);

  // If there are no constants, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedFunctionNode != null && functions.length === 0) {
      handleAddNewFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFunctionNode, functions]);

  // TODO: add error handling here like if an input is invalid or there was an error updating the nodes
  const handleOnSave = useCallback(
    (selectedNode: FunctionNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === selectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          //only grab constants that are valid
          const updatedFunctions = functions.filter(
            nodeFunction =>
              nodeFunction.columnName.trim() !== '' &&
              nodeFunction.functionRules.trim() !== '',
          );

          let updatedSelectedNode = { ...selectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            functions: [...updatedFunctions],
            nodeName: nodeName.trim(),
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(selectedNode.id);
      setSelectedNode(undefined);
      setFunctions([]);
      setNodeName('');
    },
    [functions, nodes, nodeName, setNodes, onNodeSave, setSelectedNode],
  );

  if (
    selectedFunctionNode != null &&
    selectedFunctionNode.data?.inputObjId != null
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Add Function
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <ScrollableContentContainer>
          {functions.map((nodeFunction, index) => {
            return (
              <>
                {index > 0 && <TwoMarginBottomDivider />}
                <AddFunctionForm
                  name={nodeFunction.columnName}
                  functionRules={nodeFunction.functionRules}
                  id={nodeFunction.id}
                  handleDelete={handleDeleteFunction}
                  handleUpdate={handleValueUpdate}
                />
              </>
            );
          })}
          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewFunction}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
          >
            Add Function
          </FitContentButton>
        </ScrollableContentContainer>
        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedFunctionNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  }

  // if there is something wrong with the input data and/or there is no input node connected
  return (
    <NodeMenuErrorContainer>
      <Typography variant="h5" gutterBottom>
        There are no input columns found. Did you connect an input node?
      </Typography>
      <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
        Cancel
      </Button>
    </NodeMenuErrorContainer>
  );
});
