import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  MultiCovariantAnalysisNodeType,
  FunctionNodeType,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  OutputDatasetNodeType,
  SelectCategoriesNodeType,
  FilterNodeType,
  GroupByNodeType,
  JoinNodeType,
  SelectColumnsNodeType,
  AppendTablesNodeType,
} from '../../types/nodes';
import {
  ConnectToAppendTablesNodeData,
  connectNodeToAppendTables,
} from './appendTablesNodeFunctions';
import { ConnectToJoinNodeData, connectNodeToJoin } from './joinNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

// ====================== FUNCTION NODE AS TARGET ======================

export const disconnectNodeFromFunctionNode = <Type>(
  sourceNode: Type,
  targetNode: FunctionNodeType,
): [Type, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
    functions: [],
    fields: undefined,
    inputNodeName: undefined,
  };

  return [sourceNode, updatedTargetNode];
};

// ====================== FUNCTION NODE AS SOURCE ======================

export const connectFunctionNodeToMultiCovariantAnalysis = (
  sourceNode: FunctionNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [FunctionNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: combinedColumnNames,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToSelectCategories = (
  sourceNode: FunctionNodeType,
  targetNode: SelectCategoriesNodeType,
): [FunctionNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToSelectColumns = (
  sourceNode: FunctionNodeType,
  targetNode: SelectColumnsNodeType,
): [FunctionNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: combinedColumnNames,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToKnowledgeGraph = (
  sourceNode: FunctionNodeType,
  targetNode: KnowledgeGraphNodeType,
): [FunctionNodeType, KnowledgeGraphNodeType] => {
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  //Format the Filter Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedFunctionNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: undefined,
      fields: combinedColumnNames,
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.nodeName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedFunctionNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToAddConstant = (
  sourceNode: FunctionNodeType,
  targetNode: AddConstantNodeType,
): [FunctionNodeType, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: combinedColumnNames,
    outputColumnNames: combinedColumnNames,
    columnsToAdd: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToAlterColumns = (
  sourceNode: FunctionNodeType,
  targetNode: AlterColumnsNodeType,
): [FunctionNodeType, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: combinedColumnNames,
    outputFields: [],
    alterColumns: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToOutputDataset = (
  sourceNode: FunctionNodeType,
  targetNode: OutputDatasetNodeType,
): [FunctionNodeType, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: combinedColumnNames,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToFilter = (
  sourceNode: FunctionNodeType,
  targetNode: FilterNodeType,
): [FunctionNodeType, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: combinedColumnNames,
    filterRules: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToGroupBy = (
  sourceNode: FunctionNodeType,
  targetNode: GroupByNodeType,
): [FunctionNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    fields: combinedColumnNames,
    outputFields: [],
    groupByCalcs: [],
    groupByCols: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectFunctionNodeToJoin = (
  sourceNode: FunctionNodeType,
  targetNode: JoinNodeType,
): [FunctionNodeType, JoinNodeType] => {
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    objId: sourceNode.data.objId,
    nodeName: sourceNode.data.nodeName,
    fields: combinedColumnNames,
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};

export const connectFunctionNodeToAppendTables = (
  sourceNode: FunctionNodeType,
  targetNode: AppendTablesNodeType,
): [FunctionNodeType, AppendTablesNodeType] => {
  let combinedColumnNames: string[] = sourceNode.data.functions.map(
    columnFunction => columnFunction.columnName,
  );

  if (sourceNode.data?.fields != null) {
    combinedColumnNames = combinedColumnNames.concat(sourceNode.data.fields);
  }

  const sourceNodeDataForAppendTablesNode: ConnectToAppendTablesNodeData = {
    objId: sourceNode.data.objId,
    fields: combinedColumnNames,
    processId: sourceNode.data.processId,
  };

  return [
    sourceNode,
    connectNodeToAppendTables(sourceNodeDataForAppendTablesNode, targetNode),
  ];
};
