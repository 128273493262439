import { Toolbar, Typography } from '@mui/material';
import { SiMusicbrainz } from 'react-icons/si';
import {
  FlexGrowCenteredColumn,
  FullSizeColumnFlex,
  StyledAppBar,
} from '../styles/commonStyles';
import { theme } from '../styles/theme';
import { FitContentButton } from '../styles/inputStyles';
import { useAuth } from '../hooks/useAuth';

export default function NoAccessPage() {
  const auth = useAuth();

  return (
    <FullSizeColumnFlex>
      <StyledAppBar position="static">
        <Toolbar sx={{ justifyContent: 'start' }}>
          <Typography color={theme.palette.primary.contrastText}>
            RYAILITI
          </Typography>
          <SiMusicbrainz />
        </Toolbar>
      </StyledAppBar>

      <FlexGrowCenteredColumn>
        <Typography variant="h4" fontWeight={500} gutterBottom>
          You do not currently have access to this application. Please contact
          your adminstrator to set up a license.
        </Typography>

        <FitContentButton
          variant="contained"
          onClick={() => auth.signOut()}
          size="large"
        >
          Logout
        </FitContentButton>
      </FlexGrowCenteredColumn>
    </FullSizeColumnFlex>
  );
}
