import { IconButton, Tooltip, styled } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { deleteReportService } from '../../services/reportsService';
import { theme } from '../../styles/theme';
import ConfirmationModal from '../common/confirmationModal';
import { useReportsState } from '../../state/reportsState';

const StyledIconButton = styled(IconButton)({
  display: 'none',
  position: 'absolute',
  top: '0.25rem',
  left: '0rem',
  height: '1.5rem',
  width: 'fit-content',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 500,
});

export interface DeleteReportButtonProps {
  reportPK: string;
  reportSK: string;
  reportName: string;
  fileSK: string;
  fileClient: string;
}
export default memo(
  ({
    reportPK,
    reportSK,
    reportName,
    fileClient,
    fileSK,
  }: DeleteReportButtonProps) => {
    const [showModal, setShowModal] = useState(false);
    const { getAvailableReports } = useReportsState();

    const handleConfirmClick = useCallback(async () => {
      setShowModal(false);
      await deleteReportService(reportPK, reportSK);
      getAvailableReports(fileClient.toLocaleUpperCase(), fileSK);
    }, [reportPK, reportSK, getAvailableReports, fileClient, fileSK]);

    return (
      <>
        <Tooltip title="Delete Report">
          <StyledIconButton
            aria-label="delete"
            className="delete-report-button"
            onClick={event => {
              event.stopPropagation();
              setShowModal(true);
            }}
          >
            <FaTrash
              size="1.5rem"
              color={theme.palette.error.main}
              style={{ display: 'inherit' }}
            />
          </StyledIconButton>
        </Tooltip>
        <ConfirmationModal
          ariaLabelledBy="confirm-report-delete-modal"
          ariaDescribedBy="This modal is used to confirm the deletion of a report"
          displayText={`Are you sure you want to delete ${reportName}?`}
          confirmButtonText="Delete Report"
          confirmButtonColor="error"
          confirmButtonIcon={<FaTrash />}
          showModal={showModal}
          onClose={() => setShowModal(false)}
          handleCancel={() => setShowModal(false)}
          handleConfirm={handleConfirmClick}
        />
      </>
    );
  },
);
