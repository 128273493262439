import { memo, useMemo } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  IconButton,
  styled,
  Box,
} from '@mui/material';
import { FaTrash } from 'react-icons/fa';
import { theme } from '../../../styles/theme';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';

const arrowIconSize = '1.5rem';

const FormContainer = styled(Box)({
  gridTemplateColumns: `minmax(9.375rem, 1fr) ${arrowIconSize} minmax(9.375rem, 1fr) 3rem`,
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'center',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  minWidth: '2rem',
  maxWidth: '3rem',
}) as typeof IconButton;

export interface AddEdgeFormProps {
  columnA: string;
  columnB: string;
  columnANames: string[];
  columnBNames: string[];
  id: string;
  tripleId: string;
  handleUpdateColumnName: (
    tripleId: string,
    edgeId: string,
    type: 'columnA' | 'columnB',
    newValue: string,
  ) => void;
  handleDelete: (tripleId: string, edgeId: string) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({
    columnA,
    columnB,
    columnANames,
    columnBNames,
    id,
    tripleId,
    handleUpdateColumnName,
    handleDelete,
  }: AddEdgeFormProps) => {
    const columnAName = useMemo(() => columnA, [columnA]);
    const columnBName = useMemo(() => columnB, [columnB]);
    const columnANamesArray = useMemo(() => columnANames, [columnANames]);
    const columnBNamesArray = useMemo(() => columnBNames, [columnBNames]);

    return (
      <FormContainer>
        <StyledFormControl>
          <InputLabel id="column-a-name-select-label">Column A</InputLabel>
          <Select
            labelId="column-a-name-select-label"
            id="column-a-name-select"
            label="Column A"
            value={columnAName}
            onChange={(event: SelectChangeEvent) => {
              handleUpdateColumnName(
                tripleId,
                id,
                'columnA',
                event.target.value,
              );
            }}
          >
            {columnANamesArray.sort().map(columnName => (
              <MenuItem value={columnName}>{columnName}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <FaArrowRightArrowLeft
          size={arrowIconSize}
          color={theme.palette.text.primary}
        />

        <StyledFormControl>
          <InputLabel id="column-b-name-select-label">Column B </InputLabel>
          <Select
            labelId="column-b-name-select-label"
            id="column-b-name-select"
            label="Column B"
            value={columnBName}
            onChange={(event: SelectChangeEvent) => {
              handleUpdateColumnName(
                tripleId,
                id,
                'columnB',
                event.target.value,
              );
            }}
          >
            {columnBNamesArray.sort().map(columnName => (
              <MenuItem value={columnName}>{columnName}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledIconButton
          aria-label="delete"
          className="delete-node-button"
          onClick={() => {
            handleDelete(tripleId, id);
          }}
        >
          <FaTrash
            size="2rem"
            color={theme.palette.error.main}
            style={{ display: 'inherit' }}
          />
        </StyledIconButton>
      </FormContainer>
    );
  },
);
