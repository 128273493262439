import { Typography, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import { FaTable } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { Handle, Position } from 'reactflow';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';
import { AlterColumnsNodeData } from '../../types/nodes';
import DeleteNodeButton from './DeleteNodeButton';
import { NodeContainer, StyledSourceHandle } from '../../styles/nodeStyles';

const ColumnEndNodeContaier = styled(NodeContainer)({
  flexDirection: 'column',
  alignItems: 'end',
}) as typeof NodeContainer;

const StyledMdEdit = styled(MdEdit)({
  position: 'absolute',
  backgroundColor: 'white',
  bottom: 1,
  right: 1,
}) as typeof MdEdit;

// TODO: update this position stuff there's probably a better way
const NodeNameTypography = styled(Typography)({
  position: 'absolute',
  top: 40,
  left: -43,
  textAlign: 'center',
  width: '8rem',
  overflowWrap: 'break-word',
  display: 'inline',
}) as typeof Typography;

export default memo((props: { data: AlterColumnsNodeData }) => {
  const { selectedNode } = useModelingState();

  const isConnectable = useMemo(
    () =>
      props.data.processId !== -1 &&
      props.data?.inputObjId != null &&
      props.data?.outputFields != null &&
      props.data.alterColumns.length > 0 &&
      props.data.outputFields.length > 0,
    [props],
  );
  const nodeName = useMemo(
    () => props.data.nodeName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, selectedNode],
  );

  return (
    <div>
      <ColumnEndNodeContaier>
        <DeleteNodeButton nodeId={props.data.objId} />

        <StyledMdEdit size="1rem" color={theme.palette.text.primary} />
        <FaTable size="2rem" color={theme.palette.text.primary} />

        <StyledSourceHandle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          id="target"
          position={Position.Left}
          isConnectable={true}
        />

        <NodeNameTypography variant="caption">{nodeName}</NodeNameTypography>
      </ColumnEndNodeContaier>
    </div>
  );
});
