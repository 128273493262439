import { Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AwsConfigAuth } from '../config/auth';

Auth.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  signIn: (username: string, password: string) => Promise<Result>;
  setNewPassword: (newPassword: string) => Promise<Result>;
  signOut: () => void;
}

interface Result {
  success: boolean;
  message: string;
  navigateTo: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [userForSetNewPassword, setUserForSetNewPassword] = useState<
    any | undefined
  >(undefined);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(result => {
        setUsername(result.username);
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUsername('');
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const signIn = async (username: string, password: string) => {
    try {
      const result = await Auth.signIn(username, password);
      let navigateTo = '';
      let message = '';

      setUsername(result.username);
      setIsAuthenticated(result?.challengeName !== 'NEW_PASSWORD_REQUIRED');

      if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        message = 'NEW_PASSWORD_REQUIRED';
        setUserForSetNewPassword(result);
      }

      return { success: true, message, navigateTo };
    } catch (error) {
      return {
        success: false,
        message: 'LOGIN FAIL',
        navigateTo: '/signin',
      };
    }
  };

  const setNewPassword = async (newPassword: string) => {
    try {
      // TODO: either make these not required or grab them somehow
      let family_name = 'test';
      let given_name = 'test';
      let phone_number = '+11234567890';
      let name = username;

      if (userForSetNewPassword != null) {
        // family_name =
        //   userForSetNewPassword?.challengeParams?.userAttributes?.family_name ??
        //   '';
        // given_name =
        //   userForSetNewPassword?.challengeParams?.userAttributes?.given_name ??
        //   '';
        // phone_number =
        //   userForSetNewPassword?.challengeParams?.userAttributes
        //     ?.phone_number ?? '';
        // name =
        //   userForSetNewPassword?.challengeParams?.userAttributes?.name ??
        //   '';
      } else {
        throw new Error();
      }
      // TODO: set the access token here

      await Auth.completeNewPassword(userForSetNewPassword, newPassword, {
        family_name,
        given_name,
        phone_number,
        name,
      });

      setUsername(userForSetNewPassword.username);
      setIsAuthenticated(true);

      return { success: true, message: '', navigateTo: '' };
    } catch (error) {
      console.log('SET NEW PASSWORD ERROR', error);
      return {
        success: false,
        message: 'LOGIN FAIL',
        navigateTo: '/signin',
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername('');
      setIsAuthenticated(false);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    username,
    signIn,
    setNewPassword,
    signOut,
  };
};
