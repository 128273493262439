import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useModelingState } from '../../../state/modelingState';
import { theme } from '../../../styles/theme';
import { GroupByCalcType, GroupByNodeType } from '../../../types/nodes';

import { v4 as uuidv4 } from 'uuid';
import { TwoMarginBottomDivider } from '../../../styles/commonStyles';
import AddCalculationForm from './AddCalculationForm';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../../styles/nodeStyles';
import {
  FitContentButton,
  MarginRightButton,
} from '../../../styles/inputStyles';

const GappedButtonContainer = styled(Box)({
  display: 'flex',
  width: 'inherit',
  gap: theme.spacing(1),
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
  marginBottom: theme.spacing(2),
}) as typeof FormControl;

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedGroupByNode = useMemo(
    () => selectedNode as GroupByNodeType | undefined,
    [selectedNode],
  );

  const [calculations, setCalculations] = useState<GroupByCalcType[]>(
    selectedGroupByNode != null ? selectedGroupByNode.data.groupByCalcs : [],
  );
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    selectedGroupByNode != null ? selectedGroupByNode.data.groupByCols : [],
  );
  const [nodeName, setNodeName] = useState(
    selectedGroupByNode?.data.nodeName || '',
  );

  const handleSelectAll = useCallback(
    (availableFields: string[]) => {
      setSelectedColumns([...availableFields]);
    },
    [setSelectedColumns],
  );

  const handleDeselectAll = useCallback(() => {
    setSelectedColumns([]);
  }, [setSelectedColumns]);

  const handleValueUpdate = useCallback(
    (id: string, type: 'name' | 'calculation' | 'rename', newValue: string) => {
      let updatedCalculations = [...calculations];
      const indexToUpdate = updatedCalculations.findIndex(
        (calculation: GroupByCalcType) => calculation.id === id,
      );

      if (indexToUpdate >= 0) {
        let calculationToUpdate = updatedCalculations[indexToUpdate];

        if (type === 'calculation') {
          calculationToUpdate.calculationType = newValue;
        }
        if (type === 'name') {
          calculationToUpdate.columnName = newValue;
        }
        if (type === 'rename') {
          calculationToUpdate.newColumnName = newValue;
        }

        updatedCalculations[indexToUpdate] = calculationToUpdate;
      }
      setCalculations([...updatedCalculations]);
    },
    [calculations, setCalculations],
  );

  const handleDeleteCalculation = useCallback(
    (id: string) => {
      const indexToDelete = calculations.findIndex(
        calculation => calculation.id === id,
      );

      if (indexToDelete >= 0) {
        let updatedCalculations = [...calculations];
        updatedCalculations.splice(indexToDelete, 1);
        setCalculations([...updatedCalculations]);
      }
    },
    [calculations, setCalculations],
  );

  const handleAddNewCalculation = useCallback(() => {
    const newCalculation: GroupByCalcType = {
      calculationType: '',
      columnName: '',
      newColumnName: '',
      id: uuidv4(),
    };
    setCalculations([...calculations].concat(newCalculation));
  }, [calculations, setCalculations]);

  // If there are no calculations, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedGroupByNode != null && calculations.length === 0) {
      handleAddNewCalculation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupByNode, calculations]);

  // TODO: add error handling here like if an input is invalid or there was an error updating the nodes
  const handleOnSave = useCallback(
    (selectedNode: GroupByNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === selectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          //only grab calculations that are valid
          const updatedCalculations = calculations.filter(
            calculation =>
              calculation.newColumnName.trim() !== '' &&
              calculation.columnName !== '' &&
              calculation.calculationType !== '',
          );

          let outputColumns = updatedCalculations.map(
            calculation => calculation.newColumnName,
          );

          outputColumns = outputColumns.concat(selectedColumns);

          let updatedSelectedNode = { ...selectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            groupByCalcs: [...updatedCalculations],
            groupByCols: [...selectedColumns],
            outputFields: [...outputColumns],
            nodeName: nodeName.trim(),
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(selectedNode.id);
      setSelectedNode(undefined);
      setCalculations([]);
      setNodeName('');
    },
    [
      calculations,
      nodes,
      selectedColumns,
      nodeName,
      setNodes,
      onNodeSave,
      setSelectedNode,
    ],
  );

  if (
    selectedGroupByNode != null &&
    selectedGroupByNode.data?.inputObjId != null &&
    selectedGroupByNode.data?.fields != null
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Group By
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <StyledFormControl>
          <InputLabel id="column-select-label">Columns</InputLabel>
          <Select
            labelId="column-select-label"
            id="column-select"
            label="Column"
            multiple
            value={selectedColumns}
            renderValue={selected => selected.join(', ')}
            onChange={(event: SelectChangeEvent<typeof selectedColumns>) => {
              const {
                target: { value },
              } = event;

              setSelectedColumns(
                typeof value === 'string' ? value.split(',') : value,
              );
            }}
          >
            {selectedGroupByNode.data.fields.map(columnName => (
              <MenuItem value={columnName}>
                <Checkbox checked={selectedColumns.indexOf(columnName) > -1} />
                <ListItemText primary={columnName} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <GappedButtonContainer>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              handleSelectAll(selectedGroupByNode.data?.fields ?? [])
            }
          >
            Select All
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDeselectAll}
          >
            Deselect All
          </Button>
        </GappedButtonContainer>

        <ScrollableContentContainer>
          {calculations.map((calculation, index) => {
            return (
              <>
                {index > 0 && <TwoMarginBottomDivider />}
                <AddCalculationForm
                  key={`${calculation.id}`}
                  id={calculation.id}
                  name={calculation.columnName}
                  rename={calculation.newColumnName}
                  calculationType={calculation.calculationType}
                  availableColumns={
                    selectedGroupByNode.data?.fields ?? selectedColumns
                  }
                  handleDelete={handleDeleteCalculation}
                  handleUpdate={handleValueUpdate}
                />
              </>
            );
          })}
          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewCalculation}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
          >
            Add Calculation
          </FitContentButton>
        </ScrollableContentContainer>

        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedGroupByNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  }

  // if there is something wrong with the input data and/or there is no input node connected
  return (
    <NodeMenuErrorContainer>
      <Typography variant="h5" gutterBottom>
        There are no input columns found. Did you connect an input node?
      </Typography>
      <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
        Cancel
      </Button>
    </NodeMenuErrorContainer>
  );
});
