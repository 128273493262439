import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  AppendTablesNodeType,
  FilterNodeType,
  FunctionNodeType,
  GroupByNodeType,
  InputDatasetNodeType,
  JoinNodeType,
  KnowledgeGraphNodeType,
  SelectCategoriesNodeType,
  SelectColumnsNodeType,
} from '../../types/nodes';
import {
  ConnectToAppendTablesNodeData,
  connectNodeToAppendTables,
} from './appendTablesNodeFunctions';
import { ConnectToJoinNodeData, connectNodeToJoin } from './joinNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

// ====================== DATASET AS SOURCE ======================

export const connectInputDatasetToKnowledgeGraph = (
  sourceNode: InputDatasetNodeType,
  targetNode: KnowledgeGraphNodeType,
): [InputDatasetNodeType, KnowledgeGraphNodeType] => {
  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    sourceNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToSelectCategories = (
  sourceNode: InputDatasetNodeType,
  targetNode: SelectCategoriesNodeType,
): [InputDatasetNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.inputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: sourceNode.data.fields ?? [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToSelectColumns = (
  sourceNode: InputDatasetNodeType,
  targetNode: SelectColumnsNodeType,
): [InputDatasetNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.inputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: sourceNode.data.fields ?? [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToAddConstant = (
  sourceNode: InputDatasetNodeType,
  targetNode: AddConstantNodeType,
): [InputDatasetNodeType, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.inputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data?.fields ?? [],
    outputColumnNames: sourceNode.data?.fields ?? [],
    columnsToAdd: [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToFunctionNode = (
  sourceNode: InputDatasetNodeType,
  targetNode: FunctionNodeType,
): [InputDatasetNodeType, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.inputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    functions: [],
    fields: sourceNode.data?.fields ?? [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToAlterColumns = (
  sourceNode: InputDatasetNodeType,
  targetNode: AlterColumnsNodeType,
): [InputDatasetNodeType, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.inputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data?.fields ?? [],
    outputFields: [],
    alterColumns: [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToFilter = (
  sourceNode: InputDatasetNodeType,
  targetNode: FilterNodeType,
): [InputDatasetNodeType, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data?.fields ?? [],
    filterRules: [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToGroupBy = (
  sourceNode: InputDatasetNodeType,
  targetNode: GroupByNodeType,
): [InputDatasetNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data?.fields ?? [],
    groupByCalcs: [],
    groupByCols: [],
    outputFields: [],
    inputNodeName: sourceNode.data?.outputDatasetName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectInputDatasetToJoin = (
  sourceNode: InputDatasetNodeType,
  targetNode: JoinNodeType,
): [InputDatasetNodeType, JoinNodeType] => {
  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    objId: sourceNode.data.objId,
    nodeName: sourceNode.data?.outputDatasetName ?? 'bad dataset name',
    fields: sourceNode.data?.fields ?? [],
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};

export const connectInputDatasetToAppendTables = (
  sourceNode: InputDatasetNodeType,
  targetNode: AppendTablesNodeType,
): [InputDatasetNodeType, AppendTablesNodeType] => {
  const sourceNodeDataForAppendTablesNode: ConnectToAppendTablesNodeData = {
    objId: sourceNode.data.objId,
    fields: sourceNode.data?.fields ?? [],
    processId: sourceNode.data.processId,
  };

  return [
    sourceNode,
    connectNodeToAppendTables(sourceNodeDataForAppendTablesNode, targetNode),
  ];
};
