import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useMemo } from 'react';
import { theme } from '../../styles/theme';
import { Report } from '../../types/reports';
import DeleteReportButton from './deleteReportButton';

export const CARD_HEIGHT = 14;
export const CARD_WIDTH = 14;

const DOT_CHART_IMG_URL =
  'https://static.observableusercontent.com/thumbnail/9169a799a9fec51bac5e040ddbe670bd7fd546bae43ee1f358898b50aa054e1b.jpg';
const BAR_CHART_IMG_URL =
  'https://static.observableusercontent.com/thumbnail/93e7f37ec244398eac1bbedf74e66228b2e3f578ee329760a472187086997d5d.jpg';
const DATA_GRID_IMG_URL = '/mui_datagrid_stock_img.png';
const DEFAULT_IMG_URL = '/default_chart_img.jpeg';

const getImgUrlFromType = (type: Report['type']): string => {
  switch (type) {
    case 'bar':
      return BAR_CHART_IMG_URL;
    case 'dot':
      return DOT_CHART_IMG_URL;
    case 'dataGrid':
      return DATA_GRID_IMG_URL;
    default:
      return DEFAULT_IMG_URL;
  }
};

const ReportTileContainer = styled(Card)`
  width: ${CARD_WIDTH}rem;
  height: ${CARD_HEIGHT}rem;
  background-color: ${theme.palette.background.default};
  border-radius: ${theme.borderRadiusRem};
  cursor: pointer;
  position: relative;

  &:hover .delete-report-button {
    display: flex;
  }
`;

const StyledActionArea = styled(CardActionArea)({
  height: `${CARD_HEIGHT}rem`,
  width: `${CARD_WIDTH}rem`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  overflow: 'hidden',
}) as typeof CardActionArea;

interface ReportTileProps extends Report {
  handleClick: () => void;
}

export default function ReportTile({
  name,
  type,
  PK,
  SK,
  client,
  fileSK,
  handleClick,
}: ReportTileProps) {
  const imgUrl = useMemo(() => getImgUrlFromType(type), [type]);

  return (
    <ReportTileContainer>
      {type !== 'dataGrid' && (
        <DeleteReportButton
          reportPK={PK}
          reportSK={SK}
          reportName={name}
          fileClient={client}
          fileSK={fileSK}
        />
      )}

      <StyledActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          image={imgUrl}
          // the height in rem is not working so just defaulting to a number here (1rem = 16)
          height={CARD_HEIGHT * 16}
          width={`${CARD_WIDTH}rem`}
          alt="bar chart"
          onError={({ currentTarget }) => {
            // this will trigger if the provided img src is broken or not working
            currentTarget.onerror = null;
            currentTarget.src = DEFAULT_IMG_URL;
          }}
        />
        <CardContent sx={{ padding: '0.5rem' }}>
          <Tooltip title={name}>
            <Typography variant="h6" noWrap width={`${CARD_WIDTH - 0.5}rem`}>
              {name}
            </Typography>
          </Tooltip>
        </CardContent>
      </StyledActionArea>
    </ReportTileContainer>
  );
}
