import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  styled,
} from '@mui/material';
import { Handle } from 'reactflow';
import { theme } from './theme';

export const NodeContainer = styled('div')`
  display: flex;
  border: 1px solid black;
  flex-direction: row;
  align-items: center;
  border-radius: ${theme.shape.borderRadius};
  padding: 0.25rem;
  max-height: fit-content;
  max-width: fit-content;
  background-color: white;

  &:hover .delete-node-button {
    display: flex;
  }
`;

// ========== NODE MENUS ==========

export const NodeMenuContainer = styled(Box)({
  display: 'flex',
  width: `calc(100% - ${theme.defaultPaddingRem})`,
  height: `calc(100% - ${theme.defaultPaddingRem})`,
  flexDirection: 'column',
}) as typeof Box;

export const NodeMenuErrorContainer = styled(Box)({
  display: 'flex',
  width: `calc(100% - ${theme.defaultPaddingRem})`,
  height: `calc(100% - ${theme.defaultPaddingRem})`,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}) as typeof Box;

export const NodeMenuHeaderContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}) as typeof Box;

export const ScrollableContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.defaultPaddingRem,
  paddingBottom: theme.defaultPaddingRem,
  height: 'inherit',
  width: 'inherit',
  overflow: 'scroll',
}) as typeof Box;

export const NodeMenuEndButtonsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'end',
}) as typeof Box;

export const NodeNameTextField = styled(TextField)({
  marginLeft: theme.spacing(2),
  display: 'flex',
  flex: 1,
}) as typeof TextField;

export const NodeMenuSelectionList = styled(List)({
  width: `calc(100% - ${theme.defaultPaddingRem})`,
  height: 'fit-content',
  maxHeight: 'inherit',
  backgroundColor: theme.palette.background.default,
  overflow: 'auto',
  marginBottom: theme.spacing(2),
}) as typeof List;

export const NodeMenuListItemButton = styled(ListItemButton)({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },
}) as typeof ListItemButton;

export const NodeMenuListItemText = styled(ListItemText)<{
  isSelected: boolean;
}>(({ isSelected }) => ({
  color: isSelected
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
}));

// ========== NODES ==========

export const StyledSourceHandle = styled(Handle)<{
  isConnectable: boolean;
}>(({ isConnectable }) => ({
  background: isConnectable
    ? theme.palette.success.main
    : theme.palette.error.main,
}));
