import AWS from 'aws-sdk';
import axios from 'axios';
import { create } from 'zustand';
import { Dataset } from '../types/datalake';
import { BASE_URL } from '../utils/constants';

export interface DatalakeState {
  availableFiles: AWS.S3.ObjectList | undefined;
  availableFileConfigList: Dataset[] | undefined;
  datalakeTableQueryParams: string;

  getAvailableFiles: (clientName: string) => void;
  getAvailableFileConfigs: (clientName: string) => void;
  setDatalakeTableQueryParams: (queryString: string) => void;
}

export const useDatalakeState = create<DatalakeState>((set, get) => ({
  availableFiles: undefined,
  availableFileConfigList: undefined,
  datalakeTableQueryParams: '',

  getAvailableFiles: async (clientName: string) => {
    const s3 = new AWS.S3();
    // client name MUST be lowercase here. The system is looking for file names with this client name and the file names are all lowercase
    const lowercaseClientName = clientName.toLowerCase();
    const listParams = {
      Bucket: `rke-${lowercaseClientName}`,
      Delimiter: '',
      Prefix: 'Raw/',
    };

    s3.listObjectsV2(listParams, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        set({ availableFiles: data.Contents ?? [] });
      }
    });
  },
  getAvailableFileConfigs: async (clientName: string) => {
    try {
      const pk = clientName;
      const sk = clientName;
      const fileAPI = `${BASE_URL}/fileconfig`;

      console.log('========== getAvailableFileConfigs() ==========');
      const response = await axios.get(fileAPI, {
        params: {
          pk: pk,
          sk: sk,
        },
      });

      set({ availableFileConfigList: await JSON.parse(response.data) });
      // console.log('File Config Data: ', response.data); // Handle the response data here
    } catch (error) {
      console.error('GetFileConfig Error: ', error);
    }
  },

  setDatalakeTableQueryParams: (queryString: string) => {
    set({ datalakeTableQueryParams: queryString });
  },
}));
