import { Box, styled } from '@mui/material';
import ProcessFlowSelectionHeader from '../components/processFlowSelection/header';
import ProcessFlowSelectionTable from '../components/processFlowSelection/table';
import { theme } from '../styles/theme';
import { useModelingState } from '../state/modelingState';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const ProcessFlowSelectionPageContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: theme.defaultPaddingRem,
}) as typeof Box;

const ProcessFlowSelectionPageBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}) as typeof Box;

export default function ProcessFlowSelectionPage() {
  let location = useLocation();
  const { processFlowTableQueryParams, setProcessFlowTableQueryParams } =
    useModelingState();
  const [searchParams, setSearchParams] = useSearchParams();

  // save the currently applied table filters (query params) to the modeling state so we can apply them when the user navigates back here
  useEffect(() => {
    setProcessFlowTableQueryParams(location.search);
  }, [location, setProcessFlowTableQueryParams]);

  // apply any saved table filters (via query params) on page load
  useEffect(() => {
    // if there are no saved processFlowTableQueryParams, check if there are any query params in the url (searchParams) and apply those instead
    setSearchParams(
      processFlowTableQueryParams === ''
        ? searchParams
        : processFlowTableQueryParams,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProcessFlowSelectionPageContainer>
      <ProcessFlowSelectionHeader />
      <ProcessFlowSelectionPageBody>
        <ProcessFlowSelectionTable />
      </ProcessFlowSelectionPageBody>
    </ProcessFlowSelectionPageContainer>
  );
}
