import { List, Tooltip, styled } from '@mui/material';
import { FaArrowRight, FaCaretDown, FaDatabase, FaTable } from 'react-icons/fa';
import {
  FaArrowDown,
  FaC,
  FaFilter,
  FaMicrochip,
  FaTableCellsLarge,
} from 'react-icons/fa6';
import { MdEdit, MdJoinInner } from 'react-icons/md';
import {
  TbColumnInsertRight,
  TbMathFunction,
  TbRowInsertTop,
} from 'react-icons/tb';
import { VscCombine } from 'react-icons/vsc';
import { CenterAlignedRowFlex, ColumnFlex } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import { NodeTypeNames } from '../../types/nodes';
import DraggableNodeContainer from './draggableNodeContainer';
import SidebarSection from './section';
import { BsArrowsAngleContract } from 'react-icons/bs';
import { NodeContainer } from '../../styles/nodeStyles';
import { useUserState } from '../../state/userState';

const StyledList = styled(List)({
  width: '100%',
  height: `calc(100% - ${theme.defaultPaddingRem})`,
  maxWidth: '12rem',
  bgcolor: theme.palette.surface.default,
  borderRight: `1px solid ${theme.palette.divider}`,
}) as typeof List;

const ColumnNodeContaier = styled(NodeContainer)({
  flexDirection: 'column',
}) as typeof NodeContainer;

const ColumnEndNodeContaier = styled(ColumnNodeContaier)({
  alignItems: 'end',
}) as typeof ColumnNodeContaier;

const MarginedColumnFlex = styled(ColumnFlex)({
  marginLeft: -4,
}) as typeof ColumnFlex;

export default function NodeSidebar() {
  const { userProfile } = useUserState();

  return (
    <StyledList>
      <SidebarSection label="Datasets">
        <DraggableNodeContainer
          label="Input"
          nodeType={NodeTypeNames.inputDatasetNode}
        >
          <Tooltip title="Input Dataset">
            <NodeContainer>
              <FaArrowRight size="0.5rem" color={theme.palette.text.primary} />
              <FaDatabase size="2rem" color={theme.palette.text.primary} />
            </NodeContainer>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="Output"
          nodeType={NodeTypeNames.outputDatasetNode}
        >
          <Tooltip title="Output Dataset">
            <NodeContainer>
              <FaDatabase size="2rem" color={theme.palette.text.primary} />
              <FaArrowRight size="0.5rem" color={theme.palette.text.primary} />
            </NodeContainer>
          </Tooltip>
        </DraggableNodeContainer>
      </SidebarSection>

      <SidebarSection label="Mappings">
        <DraggableNodeContainer
          label="KG"
          nodeType={NodeTypeNames.knowledgeGraphNode}
        >
          <Tooltip title="Knowledge Graph">
            <NodeContainer>
              <VscCombine size="2rem" color={theme.palette.text.primary} />
            </NodeContainer>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer label="Join" nodeType={NodeTypeNames.joinNode}>
          <Tooltip title="Join Tables">
            <NodeContainer>
              <MdJoinInner size="2rem" color={theme.palette.text.primary} />
            </NodeContainer>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="Apnd Tbl"
          nodeType={NodeTypeNames.appendTablesNode}
        >
          <Tooltip title="Append Tables">
            <ColumnNodeContaier>
              <FaTableCellsLarge
                size="2rem"
                color={theme.palette.text.primary}
              />
              <TbRowInsertTop
                size="2rem"
                color={theme.palette.text.primary}
                style={{ marginTop: '-0.25rem' }}
              />
            </ColumnNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>
      </SidebarSection>

      <SidebarSection label="Functions">
        <DraggableNodeContainer
          label="Sel Cat"
          nodeType={NodeTypeNames.selectCategoriesNode}
        >
          <Tooltip title="Select Categories">
            <ColumnEndNodeContaier>
              <FaCaretDown
                size="0.5rem"
                color={theme.palette.text.primary}
                style={{ marginRight: '5px' }}
              />
              <FaTable size="2rem" color={theme.palette.text.primary} />
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="Sel Col"
          nodeType={NodeTypeNames.selectColumnsNode}
        >
          <Tooltip title="Select Columns">
            <ColumnEndNodeContaier>
              <FaCaretDown
                size="0.5rem"
                color={theme.palette.text.primary}
                style={{ marginRight: '5px' }}
              />
              <FaTable size="2rem" color={theme.palette.text.primary} />
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="Func"
          nodeType={NodeTypeNames.functionNode}
        >
          <Tooltip title="Add Function Node">
            <ColumnEndNodeContaier>
              <TbMathFunction size="2rem" color={theme.palette.text.primary} />
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="+C"
          nodeType={NodeTypeNames.addConstantNode}
        >
          <Tooltip title="Add Constant">
            <ColumnEndNodeContaier>
              <CenterAlignedRowFlex>
                <TbColumnInsertRight
                  size="2rem"
                  color={theme.palette.text.primary}
                />
                <MarginedColumnFlex>
                  <FaArrowDown
                    size="0.75rem"
                    color={theme.palette.text.primary}
                  />
                  <FaC size="0.75rem" color={theme.palette.text.primary} />
                </MarginedColumnFlex>
              </CenterAlignedRowFlex>
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="AC"
          nodeType={NodeTypeNames.alterColumnsNode}
        >
          <Tooltip title="Alter Columns">
            <ColumnEndNodeContaier>
              <MdEdit
                size="1rem"
                color={theme.palette.text.primary}
                style={{
                  position: 'absolute',
                  backgroundColor: 'white',
                }}
              />
              <FaTable size="2rem" color={theme.palette.text.primary} />
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="Filter"
          nodeType={NodeTypeNames.filterNode}
        >
          <Tooltip title="Add Filter Node">
            <ColumnEndNodeContaier>
              <FaFilter size="2rem" color={theme.palette.text.primary} />
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>

        <DraggableNodeContainer
          label="Group By"
          nodeType={NodeTypeNames.groupByNode}
        >
          <Tooltip title="Group By Node">
            <ColumnEndNodeContaier>
              <BsArrowsAngleContract
                size="2rem"
                color={theme.palette.text.primary}
              />
            </ColumnEndNodeContaier>
          </Tooltip>
        </DraggableNodeContainer>
      </SidebarSection>

      {/* This section lets the user add Biomimetic engine nodes. Only render it if they have the Biomimetic License flag */}
      {userProfile != null && userProfile.BiomimeticLicense === 'YES' && (
        <SidebarSection label="Biomimetic Engine">
          <DraggableNodeContainer
            label="MCA"
            nodeType={NodeTypeNames.multiCovariantAnalysisNode}
          >
            <Tooltip title="Multi-Covariant Analysis">
              <NodeContainer>
                <FaMicrochip
                  size="2rem"
                  color={theme.palette.text.primary}
                  style={{ marginRight: theme.spacing(0.25) }}
                />
                <FaArrowRight
                  size="0.5rem"
                  color={theme.palette.text.primary}
                />
              </NodeContainer>
            </Tooltip>
          </DraggableNodeContainer>
        </SidebarSection>
      )}
    </StyledList>
  );
}
