import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { useModelingState } from '../../state/modelingState';
import { useUserState } from '../../state/userState';
import {
  FullWidthContainer,
  StyledSkeleton,
  WarningMessageBox,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  OrderByProcessFlowParam,
  ProcessFlowRowData,
  ProcessFlowTableColumn,
  ProcessFlowType,
  RowsPerPageProcessFlowParam,
} from '../../types/processFlows';
import {
  OrderParam,
  PageNumParam,
  getComparator,
  stableSort,
} from '../../types/tables';
import ProcessFlowSelectionModals from './modals';
import ProcessFlowSelectionTableBody from './tableBody';
import ProcessFlowSelectionTableHeader from './tableHeader';

const SkeletonsContainer = styled(Box)({
  display: 'grid',
  rowGap: theme.spacing(1),
}) as typeof Box;

const columns: readonly ProcessFlowTableColumn[] = [
  { id: 'name', label: 'Name' },
  { id: 'createdBy', label: 'Created By' },
  {
    id: 'numberOfNodes',
    label: 'Nodes',
  },
  {
    id: 'lastRunDate',
    label: 'Last Ran',
  },
  {
    id: 'lastRunStatus',
    label: 'Status',
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'right',
  },
];

export default function ProcessFlowSelectionTable() {
  const { selectedClient } = useUserState();
  const navigate = useNavigate();
  const {
    formattedProcessFlows,
    getAvailableProcessFlowsIsLoading,
    availableProcessFlows,
    getAvailableProcessFlowsError,
    getAvailableProcessFlows,
    setSelectedProcessFlow,
    setNodes,
    setEdges,
  } = useModelingState();

  const [processFlowToDelete, setProcessFlowToDelete] = useState<
    ProcessFlowRowData | undefined
  >(undefined);
  const [processFlowToRun, setProcessFlowToRun] = useState<
    ProcessFlowRowData | undefined
  >(undefined);
  const [selectedErrorMsg, setSelectedErrorMsg] = useState<string | undefined>(
    undefined,
  );

  const [page, setPage] = useQueryParam('pageNum', PageNumParam);
  const [rowsPerPage, setRowsPerPage] = useQueryParam(
    'rowsPerPage',
    RowsPerPageProcessFlowParam,
  );
  const [order, setOrder] = useQueryParam('order', OrderParam);
  const [orderBy, setOrderBy] = useQueryParam(
    'orderBy',
    OrderByProcessFlowParam,
  );

  const rows: readonly ProcessFlowRowData[] = useMemo(
    () => formattedProcessFlows ?? [],
    [formattedProcessFlows],
  );
  const visibleRows = useMemo(
    () =>
      stableSort<ProcessFlowRowData>(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rows, rowsPerPage],
  );

  const updateAvailableProcessFlowsState = useCallback((): number => {
    if (getAvailableProcessFlowsError && !getAvailableProcessFlowsIsLoading) {
      return -1;
    }

    if (
      getAvailableProcessFlowsIsLoading ||
      availableProcessFlows == null ||
      formattedProcessFlows == null
    ) {
      return 0;
    }

    if (availableProcessFlows != null && formattedProcessFlows != null) {
      return availableProcessFlows.length > 0 ? 1 : 2;
    }

    return -1;
  }, [
    availableProcessFlows,
    formattedProcessFlows,
    getAvailableProcessFlowsIsLoading,
    getAvailableProcessFlowsError,
  ]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const availableProcessFlowsState = useMemo(
    () => updateAvailableProcessFlowsState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      updateAvailableProcessFlowsState,
      availableProcessFlows,
      getAvailableProcessFlowsIsLoading,
      getAvailableProcessFlowsError,
      formattedProcessFlows,
    ],
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property: keyof ProcessFlowRowData) => {
    if (property !== 'action') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  const handleExistingProcessFlowClick = useCallback(
    (processFlowClicked: ProcessFlowType) => {
      setSelectedProcessFlow(processFlowClicked);
      setNodes(
        processFlowClicked?.nodes != null ? [...processFlowClicked.nodes] : [],
      );
      setEdges(
        processFlowClicked?.edges != null ? [...processFlowClicked.edges] : [],
      );
      navigate(`/modeling/${processFlowClicked.objId}`);
    },
    [navigate, setEdges, setNodes, setSelectedProcessFlow],
  );

  useEffect(() => {
    if (!getAvailableProcessFlowsIsLoading && selectedClient != null) {
      getAvailableProcessFlows(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableProcessFlows, selectedClient]);

  const skeletonLoaders = Array.from({ length: rowsPerPage }, (_, index) => (
    <StyledSkeleton
      key={index}
      variant="rectangular"
      animation="wave"
      height="4rem"
    />
  ));

  // Loading
  if (availableProcessFlowsState === 0) {
    return <SkeletonsContainer>{skeletonLoaders}</SkeletonsContainer>;
  }

  // Good Data show table
  if (availableProcessFlowsState === 1) {
    return (
      <FullWidthContainer>
        <TableContainer sx={{ width: '100%' }}>
          <Table stickyHeader aria-label="RKE Process Flows Table">
            <ProcessFlowSelectionTableHeader
              columns={columns}
              orderProp={order}
              orderByProp={orderBy}
              handleRequestSort={handleRequestSort}
            />
            <ProcessFlowSelectionTableBody
              columns={columns}
              visibleRowsProp={visibleRows}
              handleExistingProcessFlowClick={handleExistingProcessFlowClick}
              setProcessFlowToDeleteProp={setProcessFlowToDelete}
              setProcessFlowToRunProp={setProcessFlowToRun}
              setSelectedErrorMsgProp={setSelectedErrorMsg}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <ProcessFlowSelectionModals
          selectedErrorMsgProp={selectedErrorMsg}
          processFlowToDeleteProp={processFlowToDelete}
          processFlowToRunProp={processFlowToRun}
          setProcessFlowToDelete={setProcessFlowToDelete}
          setProcessFlowToRun={setProcessFlowToRun}
          setSelectedErrorMsg={setSelectedErrorMsg}
        />
      </FullWidthContainer>
    );
  }

  // Good Data but empty
  if (availableProcessFlowsState === 2) {
    return (
      <WarningMessageBox>
        <Typography
          variant="h5"
          textAlign="center"
          color={theme.palette.primary.main}
        >
          There were no saved Process Flows found.
        </Typography>
      </WarningMessageBox>
    );
  }

  // Error with the data
  return (
    <WarningMessageBox>
      <Typography
        variant="h5"
        textAlign="center"
        color={theme.palette.primary.main}
      >
        There was a problem getting your saved process flows. Please refresh the
        page to try again.
      </Typography>
    </WarningMessageBox>
  );
}
