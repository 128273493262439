export interface ColorTypes {
  primaryRed: string;
  notificationRed: string;
  surfaceGray: string;
  disabledGray: string;
  text: string;
  warningYellow: string;
  successGreen: string;
  salmon: string;
  highlightBlue: string;
  dividerGray: string;
  classyGray: string;
  tertiaryGreen: string;
  secondaryBlue: string;
  lightPrimaryRed: string;
  fadedPrimaryRed: string;
}

const colors: ColorTypes = {
  primaryRed: '#670202',
  lightPrimaryRed: '#C55C4C',
  fadedPrimaryRed: '#FFB29C',
  tertiaryGreen: '#00722E',
  secondaryBlue: '#0059C5',
  salmon: '#F55F4C',
  highlightBlue: '#00E7F4',
  surfaceGray: '#e5e5e5',
  disabledGray: '#BDBDBD',
  notificationRed: '#E81F2E',
  warningYellow: '#FFE900',
  successGreen: '#00C9A7',
  text: '#000000',
  dividerGray: '#B9B9B9',
  classyGray: '#B0A8B9',
};

export default colors;
