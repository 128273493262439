import { createTheme } from '@mui/material/styles';
import colors from './colors';

const lightTheme = createTheme({
  borderRadiusRem: '0.25rem',
  defaultPadding: 16,
  defaultPaddingRem: '1rem',
  palette: {
    mode: 'light',
    primary: {
      main: colors.primaryRed,
    },
    secondary: {
      main: colors.secondaryBlue,
    },
    error: {
      main: colors.notificationRed,
    },
    warning: {
      main: colors.warningYellow,
    },
    success: {
      main: colors.tertiaryGreen,
    },
    divider: colors.dividerGray,
    info: {
      main: colors.highlightBlue,
    },
    background: {
      default: '#eeeeee',
    },
    surface: {
      default: '#ffffff',
    },
  },
  // borderRadiusRem: '0.25rem',
  // colors: {
  //   primary: colors.primaryRed, //main dark red, this is used for the large majority of components
  //   secondary: colors.secondaryGreen,
  //   tertiary: colors.tertiaryBlue,
  //   accent: colors.salmon, //this should only be used in small amounts and/or for important items
  //   background: colors.surfaceGray, //the primary background color for our app
  //   surface: '#FFFFFF', //Used as the background color for components that rest on top of our primary background color.  Things like Cards, the Bottom Tabs, etc.
  //   text: '#000000',
  //   disabled: colors.disabledGray, //color for disabled components
  //   headerText: '#000000', //text color for component headers or section headers
  //   titleText: '#FFFFFF', //text color for page titles or anything within the blue bar header at the top of the app
  //   notification: colors.notificationRed, //color for notification bubbles
  //   error: colors.notificationRed, //color for any error text or anything we want to highlight as 'bad check this out'
  //   fadedPrimary: colors.fadedPrimaryRed, //faded versions of the CPG primary dark blue
  //   lightPrimary: colors.lightPrimaryRed, //both of these are used for our content loading indicators
  //   warning: colors.warningYellow,
  //   success: colors.successGreen,
  //   dividerGray: '#B9B9B9', //color for dividers between components
  //   highlight: colors.highlightBlue,
  //   classyGray: colors.classyGray,
  // },
});

export const theme: typeof lightTheme = lightTheme;
