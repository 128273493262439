import {
  AddConstantNodeType,
  AlterColumnsNodeType,
  AppendTablesNodeType,
  CustomNodeTypes,
  FilterNodeType,
  FunctionNodeType,
  GroupByNodeType,
  InputDatasetNodeType,
  JoinNodeType,
  KnowledgeGraphNodeType,
  MultiCovariantAnalysisNodeType,
  OutputDatasetNodeType,
  SelectCategoriesNodeType,
  SelectColumnsNodeType,
} from '../../types/nodes';
import {
  ConnectToAppendTablesNodeData,
  connectNodeToAppendTables,
} from './appendTablesNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

export type ConnectToJoinNodeData = {
  objId: string;
  nodeName: string;
  processId: number;
  fields: string[];
};
// ====================== JOIN AS TARGET ======================

export const disconnectNodeFromJoin = (
  sourceNode: CustomNodeTypes,
  targetNode: JoinNodeType,
): [CustomNodeTypes, JoinNodeType] => {
  let updatedTargetNode: JoinNodeType = targetNode;
  let updatedProcessId = targetNode.data.processId;
  const isLeftTable =
    sourceNode.data.objId === targetNode.data?.leftTableNameObjId;

  // the left table is the one being disconnected;
  if (isLeftTable) {
    // remove the left table specific data
    updatedTargetNode.data = {
      ...targetNode.data,
      leftTableNameObjId: undefined,
      leftTableName: undefined,
      leftTableFieldList: undefined,
      leftTableKeyFields: undefined,
    };

    // if there there is still right table data, we want to shift that to the left table now
    if (
      updatedTargetNode.data?.rightTableNameObjId != null &&
      updatedTargetNode.data?.rightTableName != null &&
      updatedTargetNode.data?.rightTableKeyFields != null &&
      updatedTargetNode.data?.rightTableFieldList != null
    ) {
      // grab the data from the right table
      const rightTableNameObjId = updatedTargetNode.data.rightTableNameObjId;
      const rightTableName = updatedTargetNode.data.rightTableName;
      const rightTableFieldList = [
        ...updatedTargetNode.data.rightTableFieldList,
      ];
      const rightTableKeyFields = [
        ...updatedTargetNode.data.rightTableKeyFields,
      ];

      // set the right table data to the left table and clear the right table
      updatedTargetNode.data = {
        ...targetNode.data,
        leftTableNameObjId: rightTableNameObjId,
        leftTableName: rightTableName,
        leftTableFieldList: rightTableFieldList,
        leftTableKeyFields: rightTableKeyFields,
        rightTableNameObjId: undefined,
        rightTableName: undefined,
        rightTableFieldList: undefined,
        rightTableKeyFields: undefined,
      };
    }
  } else {
    // it was the right table
    // remove the right table specific data
    updatedTargetNode.data = {
      ...targetNode.data,
      rightTableNameObjId: undefined,
      rightTableName: undefined,
      rightTableFieldList: undefined,
      rightTableKeyFields: undefined,
    };
  }

  // if there are no more inputs connected, reset the process id to -1
  if (
    updatedTargetNode.data?.rightTableNameObjId == null &&
    updatedTargetNode.data?.leftTableNameObjId == null
  ) {
    updatedProcessId = -1;
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: updatedProcessId,
    outputFieldList: [],
    columnsToFix: [],
    tableToFix: undefined,
    joinType: undefined,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectNodeToJoin = (
  sourceNodeData: ConnectToJoinNodeData,
  targetNode: JoinNodeType,
): JoinNodeType => {
  let updatedTargetNode: JoinNodeType = targetNode;
  const leftInputIsEmpty = targetNode.data?.leftTableNameObjId == null;
  const rightInputIsEmpty = targetNode.data?.rightTableNameObjId == null;

  // There are already two inputs connected so just return the unmodified join node
  if (!leftInputIsEmpty && !rightInputIsEmpty) {
    return targetNode;
  }

  // check the left input first because this is the default
  if (leftInputIsEmpty) {
    // update the left input specific data
    updatedTargetNode.data = {
      ...targetNode.data,
      leftTableNameObjId: sourceNodeData.objId,
      leftTableName: sourceNodeData.nodeName,
      leftTableFieldList: [...sourceNodeData.fields],
      leftTableKeyFields: [],
    };
  } else {
    // update the right input specific data
    updatedTargetNode.data = {
      ...targetNode.data,
      rightTableNameObjId: sourceNodeData.objId,
      rightTableName: sourceNodeData.nodeName,
      rightTableFieldList: [...sourceNodeData.fields],
      rightTableKeyFields: [],
    };
  }

  // this is the data that will be updated regardless of which input
  updatedTargetNode.data = {
    ...targetNode.data,
    processId:
      targetNode.data.processId === -1
        ? sourceNodeData.processId + 1
        : targetNode.data.processId,
    outputFieldList: [],
    columnsToFix: [],
    tableToFix: undefined,
  };

  return updatedTargetNode;
};

// ====================== JOIN AS SOURCE ======================

export const connectJoinToSelectCategories = (
  sourceNode: JoinNodeType,
  targetNode: SelectCategoriesNodeType,
): [JoinNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: sourceNode.data.outputFieldList,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToSelectColumns = (
  sourceNode: JoinNodeType,
  targetNode: SelectColumnsNodeType,
): [JoinNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: sourceNode.data.outputFieldList,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToKnowledgeGraph = (
  sourceNode: JoinNodeType,
  targetNode: KnowledgeGraphNodeType,
): [JoinNodeType, KnowledgeGraphNodeType] => {
  //Format the Join Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedJoinNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: undefined,
      fields: sourceNode.data.outputFieldList,
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.nodeName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedJoinNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToMultiCovariantAnalysis = (
  sourceNode: JoinNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [JoinNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.outputFieldList,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToFunctionNode = (
  sourceNode: JoinNodeType,
  targetNode: FunctionNodeType,
): [JoinNodeType, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    functions: [],
    fields: sourceNode.data.outputFieldList,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToAddConstant = (
  sourceNode: JoinNodeType,
  targetNode: AddConstantNodeType,
): [JoinNodeType, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.outputFieldList,
    outputColumnNames: sourceNode.data.outputFieldList,
    columnsToAdd: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToOutputDataset = (
  sourceNode: JoinNodeType,
  targetNode: OutputDatasetNodeType,
): [JoinNodeType, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.outputFieldList,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToFilter = (
  sourceNode: JoinNodeType,
  targetNode: FilterNodeType,
): [JoinNodeType, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.outputFieldList,
    filterRules: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToGroupBy = (
  sourceNode: JoinNodeType,
  targetNode: GroupByNodeType,
): [JoinNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.outputFieldList,
    groupByCalcs: [],
    groupByCols: [],
    outputFields: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToAlterColumns = (
  sourceNode: JoinNodeType,
  targetNode: AlterColumnsNodeType,
): [JoinNodeType, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    alterColumns: [],
    outputFields: [],
    fields: sourceNode.data.outputFieldList,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectJoinToAppendTables = (
  sourceNode: JoinNodeType,
  targetNode: AppendTablesNodeType,
): [JoinNodeType, AppendTablesNodeType] => {
  const sourceNodeDataForAppendTablesNode: ConnectToAppendTablesNodeData = {
    objId: sourceNode.data.objId,
    fields: sourceNode.data.outputFieldList,
    processId: sourceNode.data.processId,
  };

  return [
    sourceNode,
    connectNodeToAppendTables(sourceNodeDataForAppendTablesNode, targetNode),
  ];
};

export const connectJoinToJoin = (
  sourceNode: JoinNodeType,
  targetNode: JoinNodeType,
): [JoinNodeType, JoinNodeType] => {
  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    nodeName: sourceNode.data.nodeName,
    objId: sourceNode.data.objId,
    fields: sourceNode.data.outputFieldList,
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};
