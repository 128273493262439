import { Box, styled } from '@mui/material';
import { theme } from '../styles/theme';
import PlotPageBody from '../components/plots/pageBody';
import SetupReportsData from '../components/reports/setupReportsData';

const PlotPageContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: theme.defaultPaddingRem,
}) as typeof Box;

export default function PlotPage() {
  return (
    <PlotPageContainer>
      <SetupReportsData>
        <PlotPageBody />
      </SetupReportsData>
    </PlotPageContainer>
  );
}
