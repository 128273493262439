import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import AWS from 'aws-sdk';
import { useCallback, useMemo } from 'react';
import { FaCheckCircle, FaEdit, FaPlay, FaTrash } from 'react-icons/fa';
import { FaCircleExclamation, FaCloudArrowDown } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { getSingleProcessFlowService } from '../../services/processFlowServices';
import { useModelingState } from '../../state/modelingState';
import { useReportsState } from '../../state/reportsState';
import { CenterAlignedRowFlex } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import { DatalakeColumn, RowData } from '../../types/datalake';
import { ProcessFlowType } from '../../types/processFlows';
import { formateDateWithoutTimezone } from '../../utils/generalFunctions';
import { toast } from 'react-toastify';

interface DatalakeTableBodyProps {
  columns: readonly DatalakeColumn[];
  visibleRowsProp: RowData[];
  setSelectedErrorMsg: (errorMsg: string | undefined) => void;
  setProcessFlowToRun: (processFlow: RowData | undefined) => void;
  setFileToDelete: (file: RowData | undefined) => void;
}

export default function DatalakeTableBody({
  columns,
  visibleRowsProp,
  setFileToDelete,
  setProcessFlowToRun,
  setSelectedErrorMsg,
}: DatalakeTableBodyProps) {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_SYS_REGION,
  });
  const s3 = new AWS.S3();

  const navigate = useNavigate();
  const { setSelectedProcessFlow, setNodes, setEdges } = useModelingState();
  const { setSelectedFileName, setSelectedFileInfoForS3 } = useReportsState();

  const visibleRows = useMemo(() => visibleRowsProp, [visibleRowsProp]);

  const handleDownloadIconClick = (
    fileLocation: string,
    clientName: string,
  ) => {
    const toastId = toast.loading('Downloading your file...');

    const formattedClientName = clientName.toLowerCase();
    const fileKey = fileLocation
      .replace(`s3://rke-${formattedClientName ?? 'defaultclient'}`, '')
      .slice(1);

    console.log('========== DOWNLOAD FILE LOGS ==========');
    console.log('my key: ', fileKey);
    console.log('raw client: ', clientName);
    console.log('formatted client: ', formattedClientName);

    // Get a pre-signed URL for downloading the selected file
    const params = {
      Bucket: `rke-${formattedClientName ?? 'defaultclient'}`,
      Key: fileKey,
      Expires: 600, // URL expires in 60 seconds
    };

    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        console.error('Error getting pre-signed URL:', err);
        toast.update(toastId, {
          type: 'error',
          render: 'There was an error downloading your file',
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });
      } else {
        // Initiate the download using the pre-signed URL
        window.location.href = url;
        toast.update(toastId, {
          type: 'success',
          render: 'File Downloaded',
          isLoading: false,
          autoClose: 3000,
          closeButton: true,
        });
      }
    });
  };

  const handleEditProcessFlow = useCallback(
    (processFlowClicked: ProcessFlowType) => {
      setSelectedProcessFlow(processFlowClicked);
      setNodes(
        processFlowClicked?.nodes != null ? [...processFlowClicked.nodes] : [],
      );
      setEdges(
        processFlowClicked?.edges != null ? [...processFlowClicked.edges] : [],
      );
      navigate(`/modeling/${processFlowClicked.objId}`);
    },
    [navigate, setEdges, setNodes, setSelectedProcessFlow],
  );

  const formatFileType = (type: string): string => {
    if (type === 'upload') return 'Upload';
    if (type === 'processFlow') return 'Process Flow';

    return type;
  };

  const handleEditIconClick = async (
    clientName: string,
    processFlowName: string,
  ) => {
    const getProcessFlowResponse = await getSingleProcessFlowService(
      clientName,
      processFlowName,
    );

    if (typeof getProcessFlowResponse !== typeof '') {
      handleEditProcessFlow(getProcessFlowResponse as ProcessFlowType);
    }
  };

  return (
    <TableBody>
      {visibleRows.map((rowData, index) => {
        const hasError = rowData.lastRunStatus.toLowerCase().includes('failed');

        return (
          <TableRow
            tabIndex={-1}
            key={rowData.fileName}
            sx={{
              backgroundColor:
                index % 2 === 0 ? 'none' : theme.palette.grey[100],
            }}
          >
            {/* Origin (Type) Column */}
            <TableCell
              key={columns[0].id}
              align={columns[0].align}
              sx={{ flexDirection: 'column' }}
            >
              <Typography>{formatFileType(rowData.type)}</Typography>
              {rowData.type === 'processFlow' && (
                <Typography variant="caption">
                  {rowData.processFlowName}
                </Typography>
              )}
            </TableCell>

            {/* Client Name Column */}
            <TableCell key={columns[1].id} align={columns[1].align}>
              {rowData.client}
            </TableCell>

            {/* Filename Column */}
            <TableCell key={columns[2].id} align={columns[2].align}>
              <Link
                to={`${rowData.fileName}/reports`}
                // the file name and info is used in many different components so we tie it to a state variable
                onClick={() => {
                  setSelectedFileName(rowData.fileName);
                  setSelectedFileInfoForS3({
                    fileData: rowData.fileDataLocation,
                    fileClient: rowData.client,
                    fileSK: rowData.SK,
                  });
                }}
              >
                {rowData.fileName}
              </Link>
            </TableCell>

            <TableCell key={columns[3].id} align={columns[3].align}>
              {rowData.lastRunDate === ''
                ? '-'
                : formateDateWithoutTimezone(
                    new Date(rowData.lastRunDate.split(' ')[0]),
                    'MMM dd, yyyy',
                  )}
            </TableCell>

            <Tooltip
              title="Click to view error message"
              placement="top-start"
              arrow
              disableHoverListener={!hasError}
            >
              <TableCell
                key={columns[4].id}
                align={columns[4].align}
                onClick={() => {
                  if (hasError) setSelectedErrorMsg(rowData.lastRunErrorMsg);
                }}
                sx={{
                  cursor: hasError ? 'pointer' : 'default',
                }}
              >
                <CenterAlignedRowFlex>
                  <Typography fontSize="0.875rem">
                    {rowData.lastRunStatus === '' ? '-' : rowData.lastRunStatus}
                  </Typography>
                  {rowData.lastRunStatus === 'Successful' && (
                    <FaCheckCircle
                      size="1rem"
                      color={theme.palette.success.main}
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  )}
                  {hasError && (
                    <FaCircleExclamation
                      size="1rem"
                      color={theme.palette.error.main}
                      style={{ marginLeft: theme.spacing(1) }}
                    />
                  )}
                </CenterAlignedRowFlex>
              </TableCell>
            </Tooltip>

            {/* Actions Column */}
            <TableCell
              key={columns[5].id}
              align={columns[5].align}
              width="fit-content"
              sx={{ justifyContent: 'center' }}
            >
              {rowData.type === 'processFlow' && (
                <Tooltip title="Run Process Flow" placement="top" arrow>
                  <IconButton
                    aria-label="run process flow"
                    onClick={() => setProcessFlowToRun(rowData)}
                  >
                    <FaPlay size="1rem" />
                  </IconButton>
                </Tooltip>
              )}

              {rowData.type === 'processFlow' && (
                <Tooltip title="Edit Process Flow" placement="top" arrow>
                  <IconButton
                    aria-label="edit process flow"
                    onClick={() =>
                      handleEditIconClick(
                        rowData.client,
                        rowData.processFlowName,
                      )
                    }
                  >
                    <FaEdit size="1rem" />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Download File" placement="top" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    handleDownloadIconClick(
                      rowData.fileDataLocation,
                      rowData.client,
                    )
                  }
                >
                  <FaCloudArrowDown size="1rem" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete File" placement="top" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() => setFileToDelete(rowData)}
                >
                  <FaTrash size="1rem" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
