import { AppBar, Box, Divider, Skeleton, styled } from '@mui/material';
import { theme } from './theme';

// ========== EXAMPLE STYLED COMPONENT WITH PROPS ==========
// const StyledComp = styled("div", {
//   shouldForwardProp: (prop) => prop !== "color" && prop !== 'myProp',
// })<{ myProp?: boolean; color?: string }>(({ theme, myProp, color }) => ({
//   backgroundColor: myProp ? "aliceblue" : "red",
//   color,
//   padding: theme.spacing(1)
// }));

export const StyledAppBar = styled(AppBar)({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}) as typeof AppBar;

export const BasicModalContainer = styled(Box)({
  height: 'fit-content',
  width: 'min(40vw, auto)',
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '24',
  backgroundColor: theme.palette.surface.default,
  padding: theme.defaultPaddingRem,
  borderRadius: theme.borderRadiusRem,
}) as typeof Box;

// ========== CONTAINERS ==========

export const FullWidthContainer = styled(Box)({
  width: '100%',
}) as typeof Box;

export const WarningMessageBox = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'column',
}) as typeof Box;

// ========== FLEX CONTAINERS ==========

export const FlexBox = styled(Box)({
  display: 'flex',
}) as typeof Box;

export const FullWidthFlexBox = styled(FlexBox)({
  width: '100%',
}) as typeof FlexBox;

export const CenterAlignedFlexBox = styled(FlexBox)({
  alignItems: 'center',
});

export const CenterJustifiedFlexBox = styled(FlexBox)({
  justifyContent: 'center',
});

// ---------- Rows ----------
export const RowFlex = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
}) as typeof Box;

export const CenterAlignedRowFlex = styled(RowFlex)({
  alignItems: 'center',
}) as typeof RowFlex;

export const CenterJustifiedRowFlex = styled(RowFlex)({
  justifyContent: 'center',
}) as typeof RowFlex;

export const CenteredRowFlex = styled(RowFlex)({
  alignItems: 'center',
  justifyContent: 'center',
}) as typeof RowFlex;

export const FlexGrowCenteredRow = styled(CenteredRowFlex)({
  flex: 1,
}) as typeof CenteredRowFlex;

// ---------- Columns ----------

export const ColumnFlex = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
}) as typeof Box;

export const FullWidthColumnFlex = styled(ColumnFlex)({
  width: '100%',
}) as typeof ColumnFlex;

export const CenterAlignedColumnFlex = styled(ColumnFlex)({
  alignItems: 'center',
}) as typeof ColumnFlex;

export const CenterJustifiedColumnFlex = styled(ColumnFlex)({
  justifyContent: 'center',
}) as typeof ColumnFlex;

export const CenteredColumnFlex = styled(ColumnFlex)({
  alignItems: 'center',
  justifyContent: 'center',
}) as typeof ColumnFlex;

export const FlexGrowCenteredColumn = styled(CenteredColumnFlex)({
  flex: 1,
}) as typeof CenteredColumnFlex;

export const FullSizeColumnFlex = styled(ColumnFlex)({
  width: '100%',
  height: '100%',
}) as typeof ColumnFlex;

// ========== DIVIDERS ==========

export const MarginBottomDivider = styled(Divider)({
  marginBottom: theme.spacing(1),
});

export const TwoMarginBottomDivider = styled(Divider)({
  marginBottom: theme.spacing(2),
});

// ========== LOADING ==========

export const StyledSkeleton = styled(Skeleton)({
  borderRadius: theme.borderRadiusRem,
}) as typeof Skeleton;

export const BreadcrumbsSkeletonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}) as typeof Box;
