import { Button, Typography, styled } from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useModelingState } from '../../state/modelingState';
import { MarginRightButton } from '../../styles/inputStyles';
import {
  NodeMenuContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
  NodeMenuHeaderContainer,
  NodeMenuListItemButton,
  NodeMenuListItemText,
  NodeMenuSelectionList,
  NodeNameTextField,
} from '../../styles/nodeStyles';
import { SelectCategoriesNodeType } from '../../types/nodes';

const EndButtonsContainer = styled(NodeMenuEndButtonsContainer)({
  justifyContent: 'space-between',
  width: '100%',
});

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedSelectCategoriesNode = useMemo(
    () => selectedNode as SelectCategoriesNodeType | undefined,
    [selectedNode],
  );

  const availableCategories = useMemo(
    () =>
      selectedSelectCategoriesNode != null &&
      selectedSelectCategoriesNode.data?.fields != null
        ? selectedSelectCategoriesNode.data.fields.sort()
        : undefined,
    [selectedSelectCategoriesNode],
  );
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    selectedSelectCategoriesNode?.data?.selectedCategories || [],
  );
  const [nodeName, setNodeName] = useState(
    selectedSelectCategoriesNode?.data.nodeName || '',
  );

  const handleListItemClick = useCallback(
    (clickedCategory: string) => {
      let updatedSelectedCategories = [...selectedCategories];

      if (updatedSelectedCategories.length > 0) {
        const clickedCategoryIndex = updatedSelectedCategories.findIndex(
          category => category === clickedCategory,
        );

        //The category has not been selected yet and is not in the list
        if (clickedCategoryIndex === -1) {
          updatedSelectedCategories.push(clickedCategory);
        } else {
          //The category was already selected and should be removed from the list
          updatedSelectedCategories.splice(clickedCategoryIndex, 1);
        }
      } else {
        updatedSelectedCategories.push(clickedCategory);
      }

      setSelectedCategories(updatedSelectedCategories);
    },
    [setSelectedCategories, selectedCategories],
  );

  const handleOnSave = useCallback(
    (savedSelectedNode: SelectCategoriesNodeType) => {
      // UPDATE SELECTED NODE DATA
      if (nodes != null) {
        const selectedNodeIndex = nodes.findIndex(
          node => node.id === savedSelectedNode.id,
        );

        if (selectedNodeIndex >= 0 && selectedCategories.length > 0) {
          let updatedNodes = [...nodes];
          let updatedSelectedNode = nodes[
            selectedNodeIndex
          ] as SelectCategoriesNodeType;

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            selectedCategories: [...selectedCategories],
            nodeName: nodeName.trim(),
          };

          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
          // UPDATE IMPACTED STATE INFORMATION
          onNodeSave(savedSelectedNode.id);
          setNodeName('');
          setSelectedCategories([]);
        }
      }
      setSelectedNode(undefined);
    },
    [
      setSelectedNode,
      setNodes,
      onNodeSave,
      nodes,
      selectedCategories,
      nodeName,
    ],
  );

  const handleSelectAll = useCallback(() => {
    setSelectedCategories(
      availableCategories != null
        ? [...availableCategories]
        : selectedCategories,
    );
  }, [selectedCategories, availableCategories, setSelectedCategories]);

  const handleDeselectAll = useCallback(() => {
    setSelectedCategories([]);
  }, [setSelectedCategories]);

  if (selectedSelectCategoriesNode != null && availableCategories != null) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Select Categories
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <Typography variant="h6" gutterBottom>
          Available Categories:
        </Typography>
        {/* TODO: Add logic to handle if select categories list is empty or errored */}
        {/* Maybe make this a virtualized list if dataset count get to be huge */}
        {/* Maybe add a second list to the right with the selected categories */}
        <NodeMenuSelectionList>
          {availableCategories.map(category => {
            const isSelected =
              selectedCategories.findIndex(
                categoryName => category === categoryName,
              ) !== -1;

            return (
              <NodeMenuListItemButton
                key={`${category}}`}
                selected={isSelected}
                onClick={() => handleListItemClick(category)}
              >
                <NodeMenuListItemText
                  primary={category}
                  isSelected={isSelected}
                />
              </NodeMenuListItemButton>
            );
          })}
        </NodeMenuSelectionList>

        <EndButtonsContainer>
          <div>
            <MarginRightButton
              variant="contained"
              color="secondary"
              onClick={handleSelectAll}
            >
              Select All
            </MarginRightButton>
            <Button onClick={handleDeselectAll}>Deselect All</Button>
          </div>
          <div>
            <MarginRightButton onClick={() => setSelectedNode(undefined)}>
              Cancel
            </MarginRightButton>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleOnSave(selectedSelectCategoriesNode)}
            >
              Save Selections
            </Button>
          </div>
        </EndButtonsContainer>
      </NodeMenuContainer>
    );
  } else {
    return (
      <NodeMenuErrorContainer>
        <Typography variant="h5" gutterBottom>
          There are no available categories to select. Did you connect an input
          node?
        </Typography>
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
