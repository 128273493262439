import {
  QueryParamConfig,
  encodeBoolean,
  encodeNumber,
  encodeString,
} from 'use-query-params';
import { decodeBooleanQueryParam, decodeNumberQueryParam } from './tables';

export interface Dataset {
  parquetLocation: string;
  fileNm: string; //file name
  fldFormat: string[]; // list of the column types
  fldList: string[]; // list of the column names
  fldType: string; //file type
  SK: string;
  PK: string;
  fileLocation: string;
  processFlow: string;
  processType: string;
  csvLocation: string;
}

export interface DatalakeFileInfo {
  ChecksumAlgorithm: [];
  ETag: string;
  Key: string;
  LastModified: string;
  Size: number;
  StorageClass: string;
}

export interface RowData {
  client: string;
  fileName: string;
  type: string;
  processFlowName: string;
  fileDataLocation: string;
  lastRunDate: string;
  lastRunStatus: string;
  lastRunErrorMsg: string;
  SK: string;
  folder: string | undefined;
}

export interface DatalakeFolder {
  name: string;
  files: RowData[];
  client: string;
}

export interface DatalakeColumn {
  id: keyof RowData | 'action';
  label: string;
  minWidth?: number | string;
  align?: 'right' | 'left';
}

export interface DatalakeFolderResponse {
  PK: string;
  SK: string;
  bucket: string;
  folder: string;
  subfolder: string;
}

// used for asserting the correct Typescript type when decoding query params on the Datalake table
type RowDataKeysArrayType = Array<keyof RowData>;

// used for asserting the correct Typescript type when decoding query params on the Datalake table
export const RowDataKeysArray: RowDataKeysArrayType = [
  'client',
  'fileName',
  'type',
  'processFlowName',
  'fileDataLocation',
  'lastRunDate',
  'lastRunErrorMsg',
  'lastRunStatus',
];

// ========== Query Param Utils ==========

// defaults for the various filters and sorting values
// if the datalake page url is loaded with no params, the table will use these values
export const defaultRowsPerPage = 15;
export const defaultOrderBy: keyof RowData = 'fileName';
export const defaultShowProcessFlows = true;
export const defaultShowUploads = true;

// We have these custom decoder functions because the user-query-param library doesn't know how to handle default, null, undefined, or unexpected values so handle them here

// custom function telling use-query-params how to decode the orderBy param so typescript is happy
function decodeOrderByQueryParam(
  orderBy: string | (string | null)[] | null | undefined,
): keyof RowData {
  if (orderBy == null) return defaultOrderBy;

  if (typeof orderBy === typeof ['']) return defaultOrderBy;

  // this array contains all of the keys of RowData so we check and and see if the orderBy param is in that array
  if (RowDataKeysArray.includes(orderBy as keyof RowData)) {
    return orderBy as keyof RowData;
  }

  return defaultOrderBy;
}

export const RowsPerPageDatalakeParam: QueryParamConfig<number> = {
  encode: (rowsPerPage: number | null | undefined) => encodeNumber(rowsPerPage),
  decode: (rowsPerPage: string | (string | null)[] | null | undefined) =>
    decodeNumberQueryParam(rowsPerPage, defaultRowsPerPage),
};

export const OrderByDatalakeParam: QueryParamConfig<keyof RowData> = {
  encode: (orderBy: keyof RowData | null | undefined) => encodeString(orderBy),
  decode: (orderBy: string | (string | null)[] | null | undefined) =>
    decodeOrderByQueryParam(orderBy),
};

export const ShowProcessFlowsParam: QueryParamConfig<boolean> = {
  encode: (showProcessFlows: boolean | null | undefined) =>
    encodeBoolean(showProcessFlows),
  decode: (showProcessFlows: string | (string | null)[] | null | undefined) =>
    decodeBooleanQueryParam(showProcessFlows, defaultShowProcessFlows),
};

export const ShowUploadsParam: QueryParamConfig<boolean> = {
  encode: (showUploads: boolean | null | undefined) =>
    encodeBoolean(showUploads),
  decode: (showUploads: string | (string | null)[] | null | undefined) =>
    decodeBooleanQueryParam(showUploads, defaultShowUploads),
};
