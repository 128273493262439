import { Button, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { MdAddCircle } from 'react-icons/md';
import { useModelingState } from '../../../state/modelingState';
import { theme } from '../../../styles/theme';
import { AlterColumnsNodeType, AlterColumnsType } from '../../../types/nodes';

import { v4 as uuidv4 } from 'uuid';
import AlterColumnsForm from './AlterColumnsForm';
import {
  NodeMenuContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
} from '../../../styles/nodeStyles';
import {
  FitContentButton,
  MarginRightButton,
} from '../../../styles/inputStyles';

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedAlterColumnsNode = useMemo(
    () => selectedNode as AlterColumnsNodeType | undefined,
    [selectedNode],
  );

  const [alteredColumns, setAlteredColumns] = useState<AlterColumnsType[]>(
    selectedAlterColumnsNode != null
      ? selectedAlterColumnsNode.data.alterColumns
      : [],
  );
  const [nodeName, setNodeName] = useState(
    selectedAlterColumnsNode?.data.nodeName || '',
  );

  const handleValueUpdate = useCallback(
    (id: string, type: 'name' | 'format' | 'rename', newValue: string) => {
      let updatedAlteredColumns = [...alteredColumns];
      const indexToUpdate = updatedAlteredColumns.findIndex(
        (alteredColumn: AlterColumnsType) => alteredColumn.id === id,
      );

      if (indexToUpdate >= 0) {
        let alteredColumnToUpdate = updatedAlteredColumns[indexToUpdate];

        if (type === 'name') {
          alteredColumnToUpdate.columnName = newValue;
        }
        if (type === 'format') {
          alteredColumnToUpdate.columnFormat = newValue;
        }
        if (type === 'rename') {
          alteredColumnToUpdate.columnRename = newValue;
        }

        updatedAlteredColumns[indexToUpdate] = alteredColumnToUpdate;
      }
      setAlteredColumns([...updatedAlteredColumns]);
    },
    [alteredColumns, setAlteredColumns],
  );

  const handleDeleteAlteredColumn = useCallback(
    (id: string) => {
      const indexToDelete = alteredColumns.findIndex(
        alteredColumns => alteredColumns.id === id,
      );

      if (indexToDelete >= 0) {
        let updatedAlteredColumns = [...alteredColumns];
        updatedAlteredColumns.splice(indexToDelete, 1);
        setAlteredColumns([...updatedAlteredColumns]);
      }
    },
    [alteredColumns, setAlteredColumns],
  );

  const handleAddNewAlteredColumn = useCallback(() => {
    const newConstant: AlterColumnsType = {
      columnName: '',
      //default to No Change
      columnFormat: 'no-change',
      columnRename: '',
      id: uuidv4(),
    };
    setAlteredColumns([...alteredColumns].concat(newConstant));
  }, [alteredColumns, setAlteredColumns]);

  // If there are no constants, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedAlterColumnsNode != null && alteredColumns.length === 0) {
      handleAddNewAlteredColumn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlterColumnsNode, alteredColumns]);

  // TODO: add error handling here like if an input is invalid or there was an error updating the nodes
  const handleOnSave = useCallback(
    (selectedNode: AlterColumnsNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === selectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          //only grab alterColumn that are valid
          const updatedAlteredColumns = alteredColumns.filter(
            alterColumn =>
              alterColumn.columnFormat.trim() !== '' &&
              alterColumn.columnName.trim() !== '' &&
              alterColumn.columnRename.trim() !== '',
          );
          const selectedNodeFields = selectedNode.data?.fields;
          let updatedFields: string[] = [];

          if (selectedNodeFields != null) {
            updatedFields = [...selectedNodeFields];
            // update each of the modified column names (fields) with their new column rename
            updatedAlteredColumns.forEach(alteredColumn => {
              const fieldIndex = updatedFields.findIndex(
                field => field === alteredColumn.columnName,
              );

              if (fieldIndex >= 0) {
                updatedFields[fieldIndex] = alteredColumn.columnRename;
              }
            });
          }

          let updatedSelectedNode = { ...selectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            alterColumns: [...updatedAlteredColumns],
            outputFields: [...updatedFields],
            nodeName: nodeName.trim(),
          };
          updatedNodes[selectedNodeIndex] = updatedSelectedNode;

          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(selectedNode.id);
      setSelectedNode(undefined);
      setAlteredColumns([]);
      setNodeName('');
    },
    [alteredColumns, nodes, nodeName, setNodes, onNodeSave, setSelectedNode],
  );

  if (
    selectedAlterColumnsNode != null &&
    selectedAlterColumnsNode.data?.fields != null &&
    selectedAlterColumnsNode.data?.inputObjId != null
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer>
          <Typography variant="h4" gutterBottom>
            Alter a Column
          </Typography>
          <NodeNameTextField
            variant="outlined"
            placeholder="node name"
            label="Node Name"
            autoFocus
            value={nodeName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <ScrollableContentContainer>
          {alteredColumns.map(alteredColumn => {
            return (
              <AlterColumnsForm
                name={alteredColumn.columnName}
                // default to no-change if no option is already selected
                format={alteredColumn.columnFormat}
                rename={alteredColumn.columnRename}
                fields={selectedAlterColumnsNode.data?.fields ?? []}
                id={alteredColumn.id}
                handleDelete={handleDeleteAlteredColumn}
                handleUpdate={handleValueUpdate}
              />
            );
          })}
          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewAlteredColumn}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
          >
            Add Altered Column
          </FitContentButton>
        </ScrollableContentContainer>
        <NodeMenuEndButtonsContainer>
          <MarginRightButton onClick={() => setSelectedNode(undefined)}>
            Cancel
          </MarginRightButton>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleOnSave(selectedAlterColumnsNode)}
          >
            Save Selection
          </Button>
        </NodeMenuEndButtonsContainer>
      </NodeMenuContainer>
    );
  }

  // if there is something wrong with the input data and/or there is no input node connected
  return (
    <NodeMenuErrorContainer>
      <Typography variant="h5" gutterBottom>
        There are no input columns found. Did you connect an input node?
      </Typography>
      <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
        Cancel
      </Button>
    </NodeMenuErrorContainer>
  );
});
