import { toast } from 'react-toastify';
import { ResponseTypes } from '../types/api';
import { BASE_URL } from '../utils/constants';
import axios from 'axios';
import {
  GetAllReportsResponse,
  Report,
  ReportsResponseData,
} from '../types/reports';

export const getAllReportsService = async (
  clientName: string,
  fileSK: string,
): Promise<string | Report[]> => {
  try {
    const pk = clientName.toLocaleUpperCase();
    const getAllReportsURL = `${BASE_URL}/getallreports`;

    console.log('========== getAvailableReportsService() ==========');
    const response: GetAllReportsResponse = await axios.get(getAllReportsURL, {
      params: {
        pk: pk,
        filesk: fileSK,
      },
    });

    // good response with data
    if (
      response != null &&
      response.status === 200 &&
      response?.data != null &&
      response.data.length >= 0
    ) {
      const reportsData: ReportsResponseData[] = await response.data;

      // ensure we only attempt to map reports with valid data
      const filteredReportsData = reportsData.filter(
        reportData =>
          reportData?.ReportData != null &&
          reportData.ReportData?.S != null &&
          reportData.ReportData.S.length > 0,
      );

      return filteredReportsData.map(responseData => {
        const reportData = JSON.parse(responseData.ReportData.S);
        return { ...reportData, PK: responseData.PK, SK: responseData.SK };
      });
    } else {
      // something wrong with data
      return ResponseTypes.BadResponse;
    }

    // console.log('File Config Data: ', response.data); // Handle the response data here
  } catch (error) {
    console.error('getAvailableReportsService Error: ', error);
    return ResponseTypes.ErrorResponse;
  }
};

export const saveReportService = async (report: Report) => {
  console.log('==== saveReportService ======');
  const toastId = toast.loading('Report is saving...');

  try {
    const bodyData = {
      ...report,
      filesk: report.fileSK,
      client: report.client.toUpperCase(), // ensure client name is uppercase
    };
    const dynamoAPI = `${BASE_URL}/savereport`;
    const response = await axios.post(dynamoAPI, bodyData);

    if (
      response.status === 200 &&
      response?.data?.body != null &&
      response.data.body.includes('Item uploaded successfully')
    ) {
      toast.update(toastId, {
        type: 'success',
        render: 'Report saved!',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem saving your report',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
  } catch (error) {
    console.log('++++++++++ ERROR WITH saveReportService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem saving your report',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};

export const deleteReportService = async (
  reportPK: string,
  reportSK: string,
): Promise<string> => {
  console.log('==== deleteReportService ======');
  const toastId = toast.loading('Deleting the report..');
  const pk = reportPK;
  const sk = reportSK;
  try {
    const bodyData = { pk, sk };

    const deleteReportResponse = await axios.post(
      `${BASE_URL}/deleteareport`,
      bodyData,
    );

    if (
      deleteReportResponse?.data?.body ===
      'File and record deleted successfully.'
    ) {
      toast.update(toastId, {
        type: 'success',
        render: 'Report deleted',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem deleting your report',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.ErrorResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log('++++++++++ ERROR WITH deleteReportService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem deleting your report',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};
