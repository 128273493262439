import {
  CustomNodeTypes,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  MultiCovariantAnalysisNodeType,
} from '../../types/nodes';

// ====================== KNOWLEDGE GRAPH AS TARGET ======================

export const disconnectNodeFromKnowledgeGraph = (
  sourceNode: CustomNodeTypes,
  targetNode: KnowledgeGraphNodeType,
): [CustomNodeTypes, KnowledgeGraphNodeType] => {
  let updatedTargetNode: KnowledgeGraphNodeType = targetNode;
  let updatedDatabaseNames = [...targetNode.data.inputDatasetNames];
  let updatedDatasetNodes = [...targetNode.data.inputDatasetNodes];
  let updatedProcessId = targetNode.data.processId;

  // need to handle if the knowledge graph has multiple inputs, so find the deleted input indexes
  const sourceNodeDatabaseNameIndex =
    targetNode.data.inputDatasetNames.findIndex(
      name => name === sourceNode.data?.outputDatasetName,
    );

  const sourceNodeIndex = targetNode.data.inputDatasetNodes.findIndex(
    datasetNode => datasetNode.data.objId === sourceNode.data.objId,
  );

  // remove the inputs from the input list
  if (sourceNodeDatabaseNameIndex > -1) {
    updatedDatabaseNames.splice(sourceNodeDatabaseNameIndex, 1);
  }
  if (sourceNodeIndex > -1) {
    updatedDatasetNodes.splice(sourceNodeIndex, 1);
  }

  // if these arrays both no longer have any items, then this node has no more inputs
  // so we need to reset the process id to -1
  if (updatedDatabaseNames.length === 0 && updatedDatasetNodes.length === 0) {
    updatedProcessId = -1;
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: updatedProcessId,
    inputDatasetNames: [...updatedDatabaseNames],
    inputDatasetNodes: [...updatedDatasetNodes],
    triples: [],
    fields: updatedDatasetNodes.flatMap(
      datasetNode => datasetNode?.data?.fields ?? [],
    ),
  };

  return [sourceNode, updatedTargetNode];
};

export const updateKnowledgeGraphWithInputNodeData = (
  sourceNode: InputDatasetNodeType,
  targetNode: KnowledgeGraphNodeType,
): KnowledgeGraphNodeType => {
  let updatedTargetNode: KnowledgeGraphNodeType = targetNode;
  const inputDatasetNames = [...targetNode.data.inputDatasetNames].concat(
    sourceNode.data.nodeName,
  );

  const inputDatasetNodes: InputDatasetNodeType[] = [
    ...targetNode.data.inputDatasetNodes,
  ].concat({ ...sourceNode });

  const targetFields = targetNode.data?.fields ?? [];
  const sourceFields = sourceNode.data?.fields ?? [];
  const combinedFields = targetFields.concat(sourceFields);

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    //TODO: add error handling to check inputs?
    inputDatasetNames,
    inputDatasetNodes,
    fields: combinedFields,
    objId: targetNode.data.objId,
  };

  return updatedTargetNode;
};

// ====================== KNOWLEDGE GRAPH AS SOURCE ======================

export const connectKnowledgeGraphToMultiCovariantAnalysis = (
  sourceNode: KnowledgeGraphNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [KnowledgeGraphNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

// Leaving these connection functions for now

// export const connectKnowledgeGraphToOutputDataset = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: OutputDatasetNodeType,
// ): [KnowledgeGraphNodeType, OutputDatasetNodeType] => {
//   let updatedTargetNode: OutputDatasetNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data.outputDatasetName,
//     inputObjId: sourceNode.data.objId,
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToSelectCategories = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: SelectCategoriesNodeType,
// ): [KnowledgeGraphNodeType, SelectCategoriesNodeType] => {
//   let updatedTargetNode: SelectCategoriesNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...targetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputName: sourceNode.data.outputDatasetName,
//     inputDatasetName: sourceNode.data.outputDatasetName,
//     inputObjId: sourceNode.data.objId,
//     selectedCategories: undefined,
//     fields: sourceNode.data.primaryFields ?? [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToAddConstant = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: AddConstantNodeType,
// ): [KnowledgeGraphNodeType, AddConstantNodeType] => {
//   let updatedTargetNode: AddConstantNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     outputColumnNames: sourceNode.data?.primaryFields ?? [],
//     columnsToAdd: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToFunctionNode = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: FunctionNodeType,
// ): [KnowledgeGraphNodeType, FunctionNodeType] => {
//   let updatedTargetNode: FunctionNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     functions: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToAlterColumns = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: AlterColumnsNodeType,
// ): [KnowledgeGraphNodeType, AlterColumnsNodeType] => {
//   let updatedTargetNode: AlterColumnsNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     outputFields: [],
//     alterColumns: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToFilter = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: FilterNodeType,
// ): [KnowledgeGraphNodeType, FilterNodeType] => {
//   let updatedTargetNode: FilterNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     filterRules: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };
