import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from '@mui/material';
import { memo, useMemo } from 'react';
import { theme } from '../../../styles/theme';

const FormContainer = styled(Box)({
  gridTemplateColumns: '4fr 1fr',
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

export interface FixColumnFormProps {
  rename: string;
  fixType: 'drop' | 'rename';
  name: string;
  handleUpdate: (
    name: string,
    type: 'type' | 'rename',
    newValue: string,
  ) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({ rename, fixType, name, handleUpdate }: FixColumnFormProps) => {
    const type = useMemo(() => fixType, [fixType]);
    const columnRename = useMemo(() => rename, [rename]);

    return (
      <FormContainer>
        <StyledFormControl>
          <TextField
            variant="outlined"
            label="Column Name"
            placeholder="Column Name"
            value={columnRename}
            disabled={type === 'drop'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdate(name, 'rename', event.target.value);
            }}
          />
        </StyledFormControl>
        <StyledFormControl>
          <InputLabel id="fix-type-select-label">Fix Type</InputLabel>
          <Select
            labelId="fix-type-select-label"
            id="fix-type-select"
            label="Fix Type"
            value={type}
            onChange={(event: SelectChangeEvent) => {
              handleUpdate(name, 'type', event.target.value);
            }}
          >
            <MenuItem value={'drop'}>Drop</MenuItem>
            <MenuItem value={'rename'}>Rename</MenuItem>
          </Select>
        </StyledFormControl>
      </FormContainer>
    );
  },
);
