import {
  IconButton,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  styled,
  Box,
} from '@mui/material';
import { memo, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { theme } from '../../../styles/theme';

const FormContainer = styled(Box)({
  gridTemplateColumns: 'repeat(3, minmax(9.375rem, 1fr)) 3rem',
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  minWidth: '2rem',
  maxWidth: '3rem',
}) as typeof IconButton;

export interface AddColumnConstantFormProps {
  name: string;
  rename: string;
  format: string;
  fields: string[];
  id: string;
  handleUpdate: (
    id: string,
    type: 'name' | 'format' | 'rename',
    newValue: string,
  ) => void;
  handleDelete: (id: string) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({
    name,
    rename,
    format,
    fields,
    id,
    handleUpdate,
    handleDelete,
  }: AddColumnConstantFormProps) => {
    const columnName = useMemo(() => name, [name]);
    const columnFormat = useMemo(() => format, [format]);
    const columnRename = useMemo(() => rename, [rename]);

    return (
      <FormContainer>
        <StyledFormControl>
          <InputLabel id="column-format-select-label">Column Name</InputLabel>
          <Select
            labelId="column-name-select-label"
            id="column-name-select"
            label="Column Name"
            value={columnName}
            onChange={(event: SelectChangeEvent) => {
              handleUpdate(id, 'name', event.target.value);
            }}
          >
            {fields.sort().map(field => (
              <MenuItem value={field}>{field}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <TextField
            variant="outlined"
            label="Column Rename"
            placeholder="Column Rename"
            value={columnRename}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdate(id, 'rename', event.target.value);
            }}
          />
        </StyledFormControl>

        <StyledFormControl>
          <InputLabel id="column-format-select-label">Column Format</InputLabel>
          <Select
            labelId="column-format-select-label"
            id="column-format-select"
            label="Column Format"
            value={columnFormat}
            onChange={(event: SelectChangeEvent) => {
              handleUpdate(id, 'format', event.target.value);
            }}
          >
            <MenuItem value={'no-change'}>No Change</MenuItem>
            <MenuItem value={'string'}>String</MenuItem>
            <MenuItem value={'integer'}>Integer</MenuItem>
            <MenuItem value={'floating-decimal'}>Floating Decimal</MenuItem>
            <MenuItem value={'fixed-decimal'}>Fixed Decimal</MenuItem>
            <MenuItem value={'date'}>Date</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledIconButton
          aria-label="delete"
          className="delete-node-button"
          onClick={() => {
            handleDelete(id);
          }}
        >
          <FaTrash
            size="2rem"
            color={theme.palette.error.main}
            style={{ display: 'inherit' }}
          />
        </StyledIconButton>
      </FormContainer>
    );
  },
);
