import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from '@mui/material';
import { memo, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa6';
import { theme } from '../../../styles/theme';

const FormContainer = styled(Box)({
  gridTemplateColumns: 'repeat(3, minmax(9.375rem, 1fr)) 3rem',
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'start',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  minWidth: '2rem',
  maxWidth: '3rem',
}) as typeof IconButton;

export interface AddCalculationFormProps {
  name: string;
  rename: string;
  calculationType: 'count' | 'count distinct' | 'sum' | 'average' | string;
  availableColumns: string[];
  id: string;
  handleUpdate: (
    id: string,
    type: 'name' | 'calculation' | 'rename',
    newValue: string,
  ) => void;
  handleDelete: (id: string) => void;
}

//TODO: add error checking and form validation here (confirm all forms have values, and confirm the value field matches the selected format)
export default memo(
  ({
    name,
    rename,
    calculationType,
    availableColumns,
    id,
    handleUpdate,
    handleDelete,
  }: AddCalculationFormProps) => {
    const columnName = useMemo(() => name, [name]);
    const columnCalculation = useMemo(() => calculationType, [calculationType]);
    const columnRename = useMemo(() => rename, [rename]);
    const availableColumnNames = useMemo(
      () => availableColumns,
      [availableColumns],
    );

    return (
      <FormContainer>
        <StyledFormControl>
          <InputLabel id="selected-column-select-label">
            Selected Column
          </InputLabel>
          <Select
            labelId="selected-column-select-label"
            id="selected-column-select"
            label="Selected Column"
            value={columnName}
            onChange={(event: SelectChangeEvent) => {
              handleUpdate(id, 'name', event.target.value);
            }}
          >
            {availableColumnNames.map(columnName => (
              <MenuItem value={columnName}>{columnName}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledFormControl>
          <TextField
            variant="outlined"
            label="Column Rename"
            placeholder="Column Rename"
            value={columnRename}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdate(id, 'rename', event.target.value);
            }}
          />
        </StyledFormControl>

        <StyledFormControl>
          <InputLabel id="column-calculation-select-label">
            Calculation
          </InputLabel>
          <Select
            labelId="column-calculation-select-label"
            id="column-calculation-select"
            label="Calculation"
            value={columnCalculation}
            onChange={(event: SelectChangeEvent) => {
              handleUpdate(id, 'calculation', event.target.value);
            }}
          >
            <MenuItem value={'count'}>Count</MenuItem>
            <MenuItem value={'count distinct'}>Count Distinct</MenuItem>
            <MenuItem value={'sum'}>Sum</MenuItem>
            <MenuItem value={'average'}>Average</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledIconButton
          aria-label="delete"
          className="delete-node-button"
          onClick={() => {
            handleDelete(id);
          }}
        >
          <FaTrash
            size="2rem"
            color={theme.palette.error.main}
            style={{ display: 'inherit' }}
          />
        </StyledIconButton>
      </FormContainer>
    );
  },
);
