import { Box, Modal, styled } from '@mui/material';
import { useModelingState } from '../../state/modelingState';
import { theme } from '../../styles/theme';
import { NodeTypeNames } from '../../types/nodes';
import { getNodeType } from '../../utils/nodeFunctions/generalNodeFunctions';
import AddConstantNodeMenu from './addConstant/AddConstantNodeMenu';
import AlterColumnsNodeMenu from './alterColumns/AlterColumnsNodeMenu';
import MultiCovariantAnalysisNodeMenu from './MultiCovariantAnalysisNodeMenu';
import InputDatasetNodeMenu from './InputDatasetNodeMenu';
import FunctionNodeMenu from './functionNode/FunctionNodeMenu';
import KnowledgeGraphNodeMenu from './knowledgeGraph/KnowledgeGraphNodeMenu';
import SelectCategoriesNodeMenu from './SelectCategoriesNodeMenu';
import OutputDatasetNodeMenu from './OutputDatasetNodeMenu';
import { useMemo } from 'react';
import FilterNodeMenu from './FilterNodeMenu';
import GroupByNodeMenu from './groupBy/GroupByNodeMenu';
import JoinNodeMenu from './join/JoinNodeMenu';
import SelectColumnsNodeMenu from './SelectColumnsNodeMenu';
import AppendTablesNodeMenu from './AppendTablesNodeMenu';

const NodeModalContainer = styled(Box)<{
  showSmallMenuHeight: boolean;
  showSmallMenuWidth: boolean;
}>(({ showSmallMenuHeight, showSmallMenuWidth }) => ({
  backgroundColor: theme.palette.surface.default,
  height: showSmallMenuHeight ? '' : '60vh',
  //TODO: update this to not be so hacky. Maybe different nodes need different menus
  width: showSmallMenuWidth ? '40vw' : '70vw',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '24',
  padding: theme.defaultPaddingRem,
  borderRadius: theme.shape.borderRadius,
}));

export default function NodeMenuHandler() {
  const { selectedNode, setSelectedNode } = useModelingState();

  const showSmallMenuHeight = useMemo(
    () =>
      getNodeType(selectedNode) === NodeTypeNames.outputDatasetNode ||
      getNodeType(selectedNode) === NodeTypeNames.appendTablesNode ||
      getNodeType(selectedNode) === NodeTypeNames.multiCovariantAnalysisNode,
    [selectedNode],
  );
  const showSmallMenuWidth = useMemo(
    () =>
      getNodeType(selectedNode) === NodeTypeNames.outputDatasetNode ||
      getNodeType(selectedNode) === NodeTypeNames.appendTablesNode,
    [selectedNode],
  );

  return (
    <Modal
      aria-labelledby={`selected-node-modal`}
      aria-describedby="This modal is used to edit the selected node"
      open={selectedNode != null}
      onClose={() => setSelectedNode(undefined)}
    >
      <NodeModalContainer
        showSmallMenuHeight={showSmallMenuHeight}
        showSmallMenuWidth={showSmallMenuWidth}
      >
        {getNodeType(selectedNode) === NodeTypeNames.inputDatasetNode && (
          <InputDatasetNodeMenu />
        )}
        {getNodeType(selectedNode) ===
          NodeTypeNames.multiCovariantAnalysisNode && (
          <MultiCovariantAnalysisNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.outputDatasetNode && (
          <OutputDatasetNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.selectCategoriesNode && (
          <SelectCategoriesNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.selectColumnsNode && (
          <SelectColumnsNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.knowledgeGraphNode && (
          <KnowledgeGraphNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.addConstantNode && (
          <AddConstantNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.functionNode && (
          <FunctionNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.alterColumnsNode && (
          <AlterColumnsNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.filterNode && (
          <FilterNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.groupByNode && (
          <GroupByNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.joinNode && (
          <JoinNodeMenu />
        )}
        {getNodeType(selectedNode) === NodeTypeNames.appendTablesNode && (
          <AppendTablesNodeMenu />
        )}
      </NodeModalContainer>
    </Modal>
  );
}
