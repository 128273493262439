import { Box, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import ReportsHeader from '../../components/reports/header';
import { useReportsState } from '../../state/reportsState';
import {
  BreadcrumbsSkeletonContainer,
  StyledSkeleton,
  WarningMessageBox,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import BreadCrumbNavigator from '../Navigation/breadcrumbNav';
import ReportsContent, { ReportsGridContainer } from './content';
import { CARD_HEIGHT, CARD_WIDTH } from './reportTile';

const ReportsBodyContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}) as typeof Box;

export default function ReportsPageBody() {
  const { state } = useLocation();

  const {
    selectedFileName,
    selectedFileInfoForS3,
    availableReports,
    getAllReportsIsLoading,
    selectedData,
    selectedDataColumns,
    reportsErrorMessage,
    reportsIsLoading,
    selectedFileCSVKey,
    getAvailableReports,
    setReportsIsLoading,
  } = useReportsState();

  // this is set as a state variable when the link is clicked
  // we use the state instead of a query param because formatting the file data into a valid query param can be tricky
  const fileDataFromState = useMemo(
    () => selectedFileInfoForS3?.fileData ?? undefined,
    [selectedFileInfoForS3],
  );

  const fileName = useMemo(
    () => selectedFileName ?? 'Data Grid',
    [selectedFileName],
  );
  const fileSK = useMemo(
    () => selectedFileInfoForS3?.fileSK ?? undefined,
    [selectedFileInfoForS3],
  );
  const fileClient = useMemo(
    () => selectedFileInfoForS3?.fileClient ?? undefined,
    [selectedFileInfoForS3],
  );

  // this variable is true when the user navigates back to this page using the breadcrumb menu
  // this blocks the data from refreshing and making unnecessary API calls
  const navigatedFromBreadcrumb = useMemo(
    () => state?.navigatedFromBreadcrumb ?? false,
    [state],
  );

  const updateReportsState = useCallback((): number => {
    if (
      selectedDataColumns != null &&
      selectedData != null &&
      availableReports != null
    ) {
      setReportsIsLoading(false);
      // clean up the nav state once the page loads
      window.history.replaceState({}, '');
      return selectedDataColumns.length > 0 ? 1 : 2;
    }

    if (reportsErrorMessage != null && reportsErrorMessage.length > 0) {
      setReportsIsLoading(false);
      return -1;
    }

    if (
      reportsIsLoading ||
      fileDataFromState == null ||
      selectedData == null ||
      selectedDataColumns == null ||
      availableReports == null ||
      getAllReportsIsLoading
    ) {
      return 0;
    }

    return -1;
  }, [
    selectedDataColumns,
    selectedData,
    availableReports,
    reportsErrorMessage,
    reportsIsLoading,
    fileDataFromState,
    getAllReportsIsLoading,
    setReportsIsLoading,
  ]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const reportsState = useMemo(
    () => updateReportsState(),
    [updateReportsState],
  );

  // grab the available reports once the file info from the nav state is ready
  useEffect(() => {
    if (
      fileClient != null &&
      fileSK != null &&
      fileClient !== '' &&
      fileSK !== '' &&
      !navigatedFromBreadcrumb
    ) {
      getAvailableReports(fileClient.toLocaleUpperCase(), fileSK);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileClient, fileSK, navigatedFromBreadcrumb]);

  const reportTilesSkeletons = Array.from({ length: 12 }, (_, index) => (
    <StyledSkeleton
      key={index}
      variant="rectangular"
      animation="wave"
      height={`${CARD_HEIGHT}rem`}
      width={`${CARD_WIDTH}rem`}
    />
  ));

  // Loading
  if (reportsState === 0) {
    return (
      <ReportsBodyContainer>
        <BreadcrumbsSkeletonContainer>
          <StyledSkeleton
            variant="rectangular"
            height="1rem"
            animation="wave"
            width="6rem"
          />
          <StyledSkeleton
            variant="rectangular"
            height="1rem"
            animation="wave"
            width="5rem"
          />
        </BreadcrumbsSkeletonContainer>
        <StyledSkeleton
          variant="rectangular"
          animation="wave"
          height="3rem"
          width="40vw"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <ReportsGridContainer>{reportTilesSkeletons}</ReportsGridContainer>
      </ReportsBodyContainer>
    );
  }

  // Good data
  if (
    selectedDataColumns != null &&
    selectedData != null &&
    availableReports != null &&
    reportsState === 1
  )
    return (
      <ReportsBodyContainer>
        <BreadCrumbNavigator
          breadcrumbs={[
            { text: 'Datalake', href: '/datalake' },
            { text: 'Reports', href: '' },
          ]}
        />
        <ReportsHeader
          fileName={fileName}
          showAddButton
          fileClient={selectedFileInfoForS3?.fileClient}
          fileCSVKey={selectedFileCSVKey}
          fileSK={selectedFileInfoForS3?.fileSK}
        />
        <ReportsContent />
      </ReportsBodyContainer>
    );

  // Data is valid but empty
  if (reportsState === 2) {
    return (
      <WarningMessageBox>
        <Typography
          variant="h5"
          textAlign="center"
          color={theme.palette.primary.main}
        >
          There was no data found for this dataset.
        </Typography>
      </WarningMessageBox>
    );
  }

  // Error with the data
  return (
    <WarningMessageBox>
      <Typography
        variant="h5"
        textAlign="center"
        color={theme.palette.primary.main}
      >
        There was an error getting your data. Refresh to try again.
      </Typography>
      {reportsErrorMessage != null && (
        <Typography
          textAlign="center"
          color={theme.palette.text.primary}
          sx={{ marginTop: theme.spacing(1) }}
        >
          {`Error Message: ${reportsErrorMessage}`}
        </Typography>
      )}
    </WarningMessageBox>
  );
}
