import { Box, Button, FormLabel, TextField, styled } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { theme } from '../styles/theme';
import { ColumnFlex } from '../styles/commonStyles';
import { GutteredFormLabel } from '../styles/textStyles';

const StyledContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: 'fit-content',
}) as typeof Box;

const NewPasswordContainer = styled(ColumnFlex)({
  marginBottom: theme.spacing(3),
}) as typeof Box;

const ConfirmPasswordContainer = styled(ColumnFlex)({
  width: '100%',
}) as typeof Box;

export function SetNewPassword() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');

  const passwordFormatValid = useMemo(() => {
    const reg = new RegExp('^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})');
    return reg.test(password);
  }, [password]);
  const passwordsMatch = useMemo(
    () => confirmPassword === password,
    [confirmPassword, password],
  );
  const isValid = useMemo(
    () =>
      password.length > 7 &&
      confirmPassword.length > 7 &&
      passwordFormatValid &&
      passwordsMatch,
    [passwordFormatValid, passwordsMatch, password, confirmPassword],
  );

  const executeSetNewPassword = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    const result = await auth.setNewPassword(password);

    if (result.success) {
      navigate(result.navigateTo);
    } else {
      alert(result.message);
    }
  };

  return (
    <StyledContainer>
      <NewPasswordContainer>
        <GutteredFormLabel htmlFor="password">New Password</GutteredFormLabel>
        {/* Requirements: 8 characters, 1 uppercase, 1 special character */}
        <TextField
          // type="password"
          placeholder="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          error={!passwordFormatValid}
          helperText="Requirements: 8+ characters, 1+ uppercase, 1+ special character"
          size="medium"
          variant="outlined"
        />
      </NewPasswordContainer>

      <ConfirmPasswordContainer>
        <FormLabel htmlFor="confirm-password">Confirm Password</FormLabel>
        <TextField
          // type="password"
          placeholder="confirm password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          error={!passwordsMatch}
          size="medium"
          variant="outlined"
          sx={{ width: '100%' }}
        />
      </ConfirmPasswordContainer>
      <div>
        <Button
          variant="contained"
          size="large"
          onClick={executeSetNewPassword}
          disabled={!isValid}
        >
          Set Password
        </Button>
      </div>
    </StyledContainer>
  );
}
