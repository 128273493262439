import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface NavBarButtonProps {
  buttonText: string;
  startIcon?: React.ReactNode;
  pathnames: string[];
  exactPathnameMatch: boolean;
  onClick: () => void;
}

export default function NavBarButton({
  buttonText,
  startIcon,
  pathnames,
  exactPathnameMatch,
  onClick,
}: NavBarButtonProps) {
  const location = useLocation();

  return (
    <Button
      variant="contained"
      color={`${
        pathnames.some(name =>
          exactPathnameMatch
            ? location.pathname === name
            : location.pathname.includes(name),
        )
          ? 'secondary'
          : 'primary'
      }`}
      size="large"
      disableElevation
      onClick={onClick}
      startIcon={startIcon ?? <></>}
    >
      {buttonText}
    </Button>
  );
}
