import { useMemo } from 'react';
import { FaPlay, FaTrash } from 'react-icons/fa';
import { useUserState } from '../../state/userState';
import { RowData } from '../../types/datalake';
import ConfirmationModal from '../common/confirmationModal';
import ViewInformationModal from '../common/viewInformationModal';
import { deleteFileService } from '../../services/fileService';
import { useFileConfigState } from '../../state/fileconfigState';
import {
  getSingleProcessFlowService,
  runProcessFlowService,
} from '../../services/processFlowServices';
import { ProcessFlowType } from '../../types/processFlows';

interface DatalakeModalsProps {
  processFlowToRunProp: RowData | undefined;
  fileToDeleteProp: RowData | undefined;
  selectedErrorMsgProp: string | undefined;
  setProcessFlowToRun: (processFlowToRun: RowData | undefined) => void;
  setFileToDelete: (fileToDelete: RowData | undefined) => void;
  setSelectedErrorMsg: (errorMsg: string | undefined) => void;
}

export default function DatalakeModals({
  fileToDeleteProp,
  processFlowToRunProp,
  selectedErrorMsgProp,
  setProcessFlowToRun,
  setFileToDelete,
  setSelectedErrorMsg,
}: DatalakeModalsProps) {
  const { selectedClient } = useUserState();
  const { getFileConfigIsLoading, getAvailableFileConfigs } =
    useFileConfigState();

  const selectedErrorMsg = useMemo(
    () => selectedErrorMsgProp,
    [selectedErrorMsgProp],
  );
  const fileToDelete = useMemo(() => fileToDeleteProp, [fileToDeleteProp]);
  const processFlowToRun = useMemo(
    () => processFlowToRunProp,
    [processFlowToRunProp],
  );

  const handleDelete = async (fileData: RowData | undefined) => {
    // closes the modal
    setFileToDelete(undefined);

    if (fileData != null) {
      await deleteFileService(
        fileData.client.toLowerCase(),
        fileData.type === 'upload' ? 'UPLOAD' : fileData.processFlowName,
        fileData.fileName,
        fileData.fileDataLocation,
      );
    }

    if (selectedClient != null && !getFileConfigIsLoading) {
      getAvailableFileConfigs(selectedClient.clientNameFormattedDynamo);
    }
  };

  const handleRunIconClick = async (
    clientName: string,
    processFlowName: string,
  ) => {
    setProcessFlowToRun(undefined);
    const getProcessFlowResponse = await getSingleProcessFlowService(
      clientName,
      processFlowName,
    );

    if (typeof getProcessFlowResponse !== typeof '') {
      await runProcessFlowService(getProcessFlowResponse as ProcessFlowType);
    }
  };

  return (
    <>
      <ConfirmationModal
        ariaLabelledBy="confirm-file-delete-modal"
        ariaDescribedBy="This modal is used to confirm the deletion of a file"
        displayText={`Are you sure you want to delete ${
          fileToDelete?.fileName ?? 'Error selecting file'
        }?`}
        confirmButtonText="Delete File"
        confirmButtonColor="error"
        confirmButtonIcon={<FaTrash />}
        showModal={fileToDelete != null}
        onClose={() => setFileToDelete(undefined)}
        handleCancel={() => setFileToDelete(undefined)}
        handleConfirm={() => handleDelete(fileToDelete)}
      />

      <ConfirmationModal
        ariaLabelledBy="confirm-process-flow-run-modal"
        ariaDescribedBy="This modal is used to confirm the running of a process flow"
        displayText={`Are you sure you want to run this process flow: ${
          processFlowToRun?.processFlowName ?? 'error selecting process flow'
        }`}
        confirmButtonText={`Run ${
          processFlowToRun?.processFlowName ?? 'Process Flow'
        }`}
        showModal={processFlowToRun != null}
        onClose={() => setProcessFlowToRun(undefined)}
        handleConfirm={() => {
          if (processFlowToRun != null)
            handleRunIconClick(
              processFlowToRun.client,
              processFlowToRun.processFlowName,
            );
        }}
        handleCancel={() => setProcessFlowToRun(undefined)}
        confirmButtonIcon={<FaPlay />}
      />

      <ViewInformationModal
        ariaLabelledBy="view-error-message-modal"
        ariaDescribedBy="This modal is used to view the selected object's error message"
        displayText={
          selectedErrorMsg ?? 'There was a problem grabbing the error message.'
        }
        showModal={selectedErrorMsg != null}
        handleCloseClick={() => setSelectedErrorMsg(undefined)}
        onClose={() => setSelectedErrorMsg(undefined)}
      />
    </>
  );
}
