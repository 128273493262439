import AWS from 'aws-sdk';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ResponseTypes } from '../types/api';
import { BASE_URL } from '../utils/constants';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_SYS_REGION,
});

const s3 = new AWS.S3();

export const fileUploadService = async (
  file: File,
  s3ClientName?: string,
  folderName?: string,
): Promise<ResponseTypes.GoodResponse | ResponseTypes.ErrorResponse> => {
  const toastId = toast.loading('Uploading your file...');

  try {
    const filename = `Raw/${folderName ?? ''}` + file.name;

    console.log('file upload key: ', filename);

    const params = {
      Bucket: `rke-${s3ClientName ?? 'defaultclient'}`,
      Key: filename,
      Body: file,
    };

    const options = {
      partSize: 100 * 1024 * 1024 * 1024, // 10 Gb
      queueSize: 1,
    };

    const upload = s3.upload(params, options);

    const uploadResponse = upload.send(err => {
      //TODO: this error handling does not actually throw an error
      if (err) {
        console.log('Error uploading file:', err);
        toast.update(toastId, {
          type: 'error',
          render: 'There was an error uploading your file',
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        return ResponseTypes.ErrorResponse;
      }
      //this just returns the response of the upload method
      return null;
    });

    if (uploadResponse != null) {
      return uploadResponse;
    }

    toast.update(toastId, {
      type: 'success',
      render: 'File Uploaded',
      isLoading: false,
      autoClose: 3000,
      closeButton: true,
    });
    toast.warning(
      "The file is being processed to ensure it's useable for modeling. Check back in a few minutes if you don't see the file right away.",
      { autoClose: 10000 },
    );

    return ResponseTypes.GoodResponse;
  } catch (error) {
    console.log('Caught an error uploading the file:', error);
    toast.update(toastId, {
      type: 'error',
      render: 'There was an error uploading your file',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};

export const deleteFileService = async (
  clientName: string,
  processFlowName: string,
  fileName: string,
  filePath: string,
): Promise<string> => {
  console.log('==== deleteFileService ======');
  const toastId = toast.loading('Deleting the file...');
  try {
    const bodyData = {
      PK: clientName.toLowerCase(),
      pfName: processFlowName,
      fileNm: fileName,
      filePath,
    };

    console.log('delete file body data');
    console.log(bodyData);
    const deleteFileResponse = await axios.post(
      `${BASE_URL}/deleteafile`,
      bodyData,
    );

    if (
      deleteFileResponse?.data?.body === 'File and record deleted successfully.'
    ) {
      toast.update(toastId, {
        type: 'success',
        render: 'File deleted',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem deleting your file',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.ErrorResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log('++++++++++ ERROR WITH deleteFileService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem deleting your file',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};

export const createFolderService = async (
  clientName: string,
  folderName: string,
) => {
  console.log('==== createFolderService ======');
  const toastId = toast.loading('Folder is saving...');

  try {
    const bodyData = {
      clientNm: clientName.toLowerCase(), // all lowercase
      folder: 'Raw/',
      subDir: `${folderName}/`, // folder name with a trailing '/'
    };
    const dynamoAPI = `${BASE_URL}/createsubdir`;
    const createFolderResponse = await axios.post(dynamoAPI, bodyData);

    console.log({ createFolderResponse });

    if (
      createFolderResponse.status === 200 &&
      createFolderResponse?.data?.body != null &&
      createFolderResponse.data.body.includes('Item uploaded successfully')
    ) {
      toast.update(toastId, {
        type: 'success',
        render: 'Folder saved!',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.GoodResponse;
    } else {
      toast.update(toastId, {
        type: 'error',
        render: 'There was a problem saving your folder',
        isLoading: false,
        autoClose: 5000,
        closeButton: true,
      });

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
  } catch (error) {
    console.log('++++++++++ ERROR WITH createFolderService ++++++++++');
    console.log(error);

    toast.update(toastId, {
      type: 'error',
      render: 'There was a problem saving your folder',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};
