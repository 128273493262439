import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  styled,
} from '@mui/material';
import { useMemo, useState } from 'react';
import {
  HighlightOnMatchSelectLabel,
  HighlightOnMatchSelect,
} from '../../styles/inputStyles';
import ViewInformationModal from '../common/viewInformationModal';

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
}) as typeof FormControl;

const StyledFormHelperText = styled(FormHelperText)({
  position: 'absolute',
  bottom: '-1.25rem',
  right: '-1rem',
  textDecoration: 'underline',
  cursor: 'pointer',
}) as typeof FormHelperText;

interface ReducerSelectorProps {
  reducer: string;
  setReducer: (reducer: string) => void;
  setAxis?: (axis: string) => void;
  selectLabel?: string;
  matchHighlight?: boolean;
}

const reducers = [
  'frequency',
  'distinct',
  'sum',
  'min',
  'max',
  'mode',
  'mean',
  'median',
];

export default function ReducerSelector({
  reducer,
  selectLabel,
  matchHighlight,
  setReducer,
  setAxis,
}: ReducerSelectorProps) {
  const selectedReducer = useMemo(() => reducer, [reducer]);
  const label = useMemo(() => selectLabel ?? 'Reducer', [selectLabel]);
  const isHighlighted = useMemo(() => matchHighlight, [matchHighlight]);

  const [showModal, setShowModal] = useState(false);

  return (
    <StyledFormControl>
      <HighlightOnMatchSelectLabel
        id={`${label}-select-label`}
        isHightlighted={isHighlighted}
      >
        {label}
      </HighlightOnMatchSelectLabel>
      <HighlightOnMatchSelect
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        label={label}
        value={selectedReducer}
        isHightlighted={isHighlighted}
      >
        {reducers.map(reducerName => (
          <MenuItem
            value={reducerName}
            onClick={() => {
              setReducer(reducerName);
              if (setAxis != null) {
                setAxis(reducerName);
              }
            }}
          >
            {reducerName}
          </MenuItem>
        ))}
      </HighlightOnMatchSelect>
      <Box onClick={() => setShowModal(true)}>
        <StyledFormHelperText id="reducer-helper-text">
          What is a reducer?
        </StyledFormHelperText>
      </Box>
      <ViewInformationModal
        showModal={showModal}
        handleCloseClick={() => setShowModal(false)}
        onClose={() => setShowModal(false)}
        displayText="A reducer is a function that calculates using the other axis's data. For example, if I pick an X Axis that has three different unique values 'A', 'B', and 'C', and then I pick the frequency reducer as my Y Axis, the X Axis would have each of the three values and the Y axis would container the number of occurrences for each of those values. Other reducers are similar, if I picked mean, it would calculate the average of each unique value. Lastly, since a reducer needs other data to calculate on, you cannot use a reducer for both axes."
      />
    </StyledFormControl>
  );
}
