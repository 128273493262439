import { Box, Button, Toolbar } from '@mui/material';
import { useCallback } from 'react';
import { FaBriefcase, FaSnowflake } from 'react-icons/fa';
import { SiMusicbrainz } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useModelingState } from '../../../state/modelingState';
import {
  CenterAlignedFlexBox,
  StyledAppBar,
} from '../../../styles/commonStyles';
import { theme } from '../../../styles/theme';
import ClientSelector from './clientSelector';
import NavBarButton from './navBarButton';

export default function NavBar() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { selectedProcessFlow } = useModelingState();

  const handleModelingClick = useCallback(() => {
    if (selectedProcessFlow != null) {
      navigate(`/modeling/${selectedProcessFlow.objId}`);
    } else {
      navigate('/modeling');
    }
  }, [selectedProcessFlow, navigate]);

  return (
    <Box>
      <StyledAppBar position="static">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <CenterAlignedFlexBox>
            <Button
              variant="text"
              size="large"
              onClick={() => navigate('')}
              sx={{ color: theme.palette.primary.contrastText }}
              startIcon={<></>}
            >
              RyAILiti
              <SiMusicbrainz />
            </Button>

            <ClientSelector />
          </CenterAlignedFlexBox>

          <Box sx={{ gap: 16 }}>
            <NavBarButton
              buttonText="Datalake"
              pathnames={['/datalake']}
              startIcon={<FaBriefcase />}
              onClick={() => navigate('/datalake')}
              exactPathnameMatch={false}
            />
            <NavBarButton
              buttonText="Modeling"
              pathnames={['/modeling']}
              onClick={handleModelingClick}
              startIcon={<FaSnowflake />}
              exactPathnameMatch={false}
            />
            <NavBarButton
              buttonText="Logout"
              pathnames={['/logout']}
              onClick={() => auth.signOut()}
              exactPathnameMatch
            />
          </Box>
        </Toolbar>
      </StyledAppBar>
    </Box>
  );
}
