import {
  AddConstantNodeType,
  AppendTablesNodeType,
  MultiCovariantAnalysisNodeType,
  FunctionNodeType,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  OutputDatasetNodeType,
  SelectCategoriesNodeType,
  FilterNodeType,
  GroupByNodeType,
  JoinNodeType,
  SelectColumnsNodeType,
  CustomNodeTypes,
  AlterColumnsNodeType,
} from '../../types/nodes';
import { ConnectToJoinNodeData, connectNodeToJoin } from './joinNodeFunctions';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

export type ConnectToAppendTablesNodeData = {
  objId: string;
  processId: number;
  fields: string[];
};

const areFieldsEqual = (
  fieldsArrayA: string[] | undefined,
  fieldsArrayB: string[] | undefined,
): boolean => {
  if (fieldsArrayA == null || fieldsArrayB == null) return false;
  if (fieldsArrayA.length !== fieldsArrayB.length) return false;

  fieldsArrayA.sort();
  fieldsArrayB.sort();

  for (let i = 0; i < fieldsArrayA.length; i++) {
    if (fieldsArrayA[i] !== fieldsArrayB[i]) return false;
  }

  return true;
};

// ====================== APPEND TABLES AS TARGET ======================

export const connectNodeToAppendTables = (
  sourceNodeData: ConnectToAppendTablesNodeData,
  targetNode: AppendTablesNodeType,
): AppendTablesNodeType => {
  let updatedTargetNode: AppendTablesNodeType = targetNode;
  const formattedInput = {
    inputDatasetObjId: sourceNodeData.objId,
    fields: sourceNodeData.fields,
  };

  // there are no inputs currently connected
  if (
    targetNode.data.inputDatasetObjIds.length === 0 &&
    targetNode.data.inputs.length === 0
  ) {
    updatedTargetNode.data = {
      ...updatedTargetNode.data,
      inputDatasetObjIds: [sourceNodeData.objId],
      inputs: [formattedInput],
      fields: [...sourceNodeData.fields],
      inputsMatch: false,
    };
  } else {
    //there are already inputs connected, compare them
    updatedTargetNode.data = {
      ...updatedTargetNode.data,
      inputDatasetObjIds: targetNode.data.inputDatasetObjIds.concat(
        sourceNodeData.objId,
      ),
      inputs: targetNode.data.inputs.concat(formattedInput),
      inputsMatch: areFieldsEqual(targetNode.data?.fields, [
        ...sourceNodeData.fields,
      ]),
    };
  }

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId:
      targetNode.data.processId === -1
        ? sourceNodeData.processId + 1
        : targetNode.data.processId,
  };

  return updatedTargetNode;
};

export const disconnectNodeFromAppendTables = (
  sourceNode: CustomNodeTypes,
  targetNode: AppendTablesNodeType,
): [CustomNodeTypes, AppendTablesNodeType] => {
  let updatedTargetNode: AppendTablesNodeType = targetNode;
  let updatedDatabaseObjIds = [...targetNode.data.inputDatasetObjIds];
  let updatedInputsList = [...targetNode.data.inputs];
  let updatedProcessId = targetNode.data.processId;
  let updatedFields = targetNode.data?.fields;
  let updatedInputsMatch = targetNode.data.inputsMatch;

  // need to handle if the append tables has multiple inputs, so find the deleted input indexes
  const sourceNodeDatabaseObjIdIndex =
    targetNode.data.inputDatasetObjIds.findIndex(
      objId => objId === sourceNode.data.objId,
    );

  const sourceNodeInputObjIdIndex = targetNode.data.inputs.findIndex(
    input => input.inputDatasetObjId === sourceNode.data.objId,
  );

  // remove the inputs from the input list
  if (sourceNodeDatabaseObjIdIndex > -1) {
    updatedDatabaseObjIds.splice(sourceNodeDatabaseObjIdIndex, 1);
  }
  // remove the inputs from the input list
  if (sourceNodeInputObjIdIndex > -1) {
    updatedInputsList.splice(sourceNodeInputObjIdIndex, 1);
  }

  // if the updated database obj ids array length is 0, there are no more inputs
  // so we need to reset the process id to -1, and the fields to undefined
  if (updatedDatabaseObjIds.length === 0 && updatedInputsList.length === 0) {
    updatedProcessId = -1;
    updatedFields = undefined;
  }

  // inputs cannot match if there are 0 inputs or 1 input
  if (updatedDatabaseObjIds.length <= 1) {
    updatedInputsMatch = false;
  }

  // there are still inputs left, so update the fields list
  if (updatedInputsList.length >= 1) {
    updatedFields = [...updatedInputsList[0].fields];
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: updatedProcessId,
    inputs: [...updatedInputsList],
    inputDatasetObjIds: [...updatedDatabaseObjIds],
    fields: updatedFields,
    inputsMatch: updatedInputsMatch,
  };

  return [sourceNode, updatedTargetNode];
};

// ====================== APPEND TABLES AS SOURCE ======================

export const connectAppendTablesToSelectCategories = (
  sourceNode: AppendTablesNodeType,
  targetNode: SelectCategoriesNodeType,
): [AppendTablesNodeType, SelectCategoriesNodeType] => {
  let updatedTargetNode: SelectCategoriesNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedCategories: undefined,
    fields: sourceNode.data.fields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToSelectColumns = (
  sourceNode: AppendTablesNodeType,
  targetNode: SelectColumnsNodeType,
): [AppendTablesNodeType, SelectColumnsNodeType] => {
  let updatedTargetNode: SelectColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    selectedColumns: undefined,
    fields: sourceNode.data.fields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToKnowledgeGraph = (
  sourceNode: AppendTablesNodeType,
  targetNode: KnowledgeGraphNodeType,
): [AppendTablesNodeType, KnowledgeGraphNodeType] => {
  //Format the Alter Columns Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedAppendTablesNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: undefined,
      fields: sourceNode.data.fields,
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.nodeName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedAppendTablesNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToMultiCovariantAnalysis = (
  sourceNode: AppendTablesNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [AppendTablesNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToFunctionNode = (
  sourceNode: AppendTablesNodeType,
  targetNode: FunctionNodeType,
): [AppendTablesNodeType, FunctionNodeType] => {
  let updatedTargetNode: FunctionNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    functions: [],
    fields: sourceNode.data.fields,
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToAddConstant = (
  sourceNode: AppendTablesNodeType,
  targetNode: AddConstantNodeType,
): [AppendTablesNodeType, AddConstantNodeType] => {
  let updatedTargetNode: AddConstantNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    outputColumnNames: sourceNode.data.fields,
    columnsToAdd: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToAlterColumns = (
  sourceNode: AppendTablesNodeType,
  targetNode: AlterColumnsNodeType,
): [AppendTablesNodeType, AlterColumnsNodeType] => {
  let updatedTargetNode: AlterColumnsNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    alterColumns: [],
    outputFields: [],
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToOutputDataset = (
  sourceNode: AppendTablesNodeType,
  targetNode: OutputDatasetNodeType,
): [AppendTablesNodeType, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToFilter = (
  sourceNode: AppendTablesNodeType,
  targetNode: FilterNodeType,
): [AppendTablesNodeType, FilterNodeType] => {
  let updatedTargetNode: FilterNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    filterRules: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToGroupBy = (
  sourceNode: AppendTablesNodeType,
  targetNode: GroupByNodeType,
): [AppendTablesNodeType, GroupByNodeType] => {
  let updatedTargetNode: GroupByNodeType = targetNode;

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
    groupByCalcs: [],
    groupByCols: [],
    outputFields: [],
    inputNodeName: sourceNode.data.nodeName,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectAppendTablesToJoin = (
  sourceNode: AppendTablesNodeType,
  targetNode: JoinNodeType,
): [AppendTablesNodeType, JoinNodeType] => {
  const sourceNodeDataForJoinNode: ConnectToJoinNodeData = {
    objId: sourceNode.data.objId,
    nodeName: sourceNode.data.nodeName,
    fields: sourceNode.data?.fields ?? [],
    processId: sourceNode.data.processId,
  };

  return [sourceNode, connectNodeToJoin(sourceNodeDataForJoinNode, targetNode)];
};
