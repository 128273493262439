import {
  MultiCovariantAnalysisNodeType,
  OutputDatasetNodeType,
} from '../../types/nodes';

// ====================== OUTPUT DATASET AS TARGET ======================

export const disconnectNodeFromOutputDataset = <Type>(
  sourceNode: Type,
  targetNode: OutputDatasetNodeType,
): [Type, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
  };

  return [sourceNode, updatedTargetNode];
};

// ====================== OUTPUT DATASET AS SOURCE ======================

export const connectOutputDatasetToMultiCovariantAnalysis = (
  sourceNode: OutputDatasetNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [OutputDatasetNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data?.fields,
  };

  return [sourceNode, updatedTargetNode];
};
