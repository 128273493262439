import { FormControl, IconButton, TextField, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaFolderPlus } from 'react-icons/fa6';
import { StringParam, useQueryParam } from 'use-query-params';
import { CenterAlignedRowFlex } from '../../styles/commonStyles';
import SwipeOutIconButton from '../common/swipeOutIconButton';
import ConfirmationModal from '../common/confirmationModal';
import { createFolderService } from '../../services/fileService';
import { useUserState } from '../../state/userState';
import { ResponseTypes } from '../../types/api';
import { useFileConfigState } from '../../state/fileconfigState';

const transitionLength = 300; //animation length in ms

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'visible',
})<{ visible: boolean }>(({ visible }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: visible ? '12rem' : '2rem', // creates the wipe out effect
  opacity: visible ? 1 : 0,
  transition: `all ${transitionLength}ms ease-in`, // smooth transition
}));

export default function CreateFolderComponent() {
  const { selectedClient } = useUserState();
  const { getAvailableFileConfigs } = useFileConfigState();

  const [selectedFolder] = useQueryParam('selectedFolder', StringParam);

  const [showTextInput, setShowTextInput] = useState(false);
  const [showFolderButton, setShowFolderButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [folderName, setFolderName] = useState('');

  const handleShowTextInput = useCallback(() => {
    setShowTextInput(true);
    setShowFolderButton(false);
  }, []);

  const handleHideTextInput = useCallback(() => {
    setShowTextInput(false);

    setTimeout(() => {
      setShowFolderButton(true);
    }, transitionLength + 1);
  }, []);

  const handleConfirmFolderNameClick = useCallback(() => {
    handleHideTextInput();
    setShowModal(true);
  }, [handleHideTextInput]);

  // allows user to press enter to confirm the folder name
  const handleKeyDownEvent = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') handleConfirmFolderNameClick();
      if (event.key === 'Escape') handleHideTextInput();
    },
    [handleConfirmFolderNameClick, handleHideTextInput],
  );

  const handleModalCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleConfirmCreateFolder = useCallback(async () => {
    setShowModal(false);
    if (selectedClient != null && folderName !== '') {
      const response = await createFolderService(
        selectedClient.clientName,
        folderName,
      );

      if (response === ResponseTypes.GoodResponse)
        getAvailableFileConfigs(selectedClient.clientNameFormattedDynamo);
    }
  }, [folderName, getAvailableFileConfigs, selectedClient]);

  return (
    <CenterAlignedRowFlex>
      {/* We don't currently allow the user to create a folder within a folder, so we hide this button while they have a folder selected */}
      {selectedFolder == null && showFolderButton && (
        <SwipeOutIconButton
          label="create folder"
          Icon={FaFolderPlus}
          onClick={handleShowTextInput}
        />
      )}
      <StyledFormControl
        visible={showTextInput}
        disabled={!showTextInput}
        size="small"
      >
        <TextField
          variant="outlined"
          size="small"
          label="Folder Name"
          placeholder="Folder Name"
          value={folderName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFolderName(event.target.value);
          }}
          onKeyDown={handleKeyDownEvent}
          disabled={!showTextInput}
        />
        <IconButton
          aria-label="confirm Folder Name"
          color="success"
          onClick={handleConfirmFolderNameClick}
          disabled={!showTextInput}
        >
          <FaCheckCircle size="2rem" />
        </IconButton>
      </StyledFormControl>
      <ConfirmationModal
        showModal={showModal}
        confirmButtonText="Create Folder"
        onClose={handleModalCancel}
        handleCancel={handleModalCancel}
        handleConfirm={handleConfirmCreateFolder}
        displayText={`You are creating a folder named ${folderName} in the /home directory under the client ${selectedClient?.clientName.toUpperCase()}.`}
        titleText="Confirm Folder Creation"
      />
    </CenterAlignedRowFlex>
  );
}
