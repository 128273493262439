import { Box, Button, FormLabel, TextField, styled } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { theme } from '../styles/theme';
import { SetNewPassword } from './SetNewPassword';
import { ColumnFlex, FlexGrowCenteredColumn } from '../styles/commonStyles';
import { GutteredFormLabel } from '../styles/textStyles';

const MarginColumnFlex = styled(ColumnFlex)({
  marginBottom: theme.spacing(3),
}) as typeof ColumnFlex;

export function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [needsToSetNewPassword, setNeedsToSetNewPassword] = useState(false);

  const executeSignIn = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const result = await auth.signIn(username, password);

    if (result.message === 'NEW_PASSWORD_REQUIRED') {
      setNeedsToSetNewPassword(true);
    } else {
      if (result.success) {
        navigate(result.navigateTo);
      } else {
        alert(result.message);
      }
    }
  };

  if (needsToSetNewPassword) {
    return <SetNewPassword />;
  }

  return (
    <FlexGrowCenteredColumn>
      <MarginColumnFlex>
        <GutteredFormLabel htmlFor="username">User ID</GutteredFormLabel>
        <TextField
          type="text"
          placeholder="UserID"
          value={username}
          onChange={e => setUsername(e.target.value)}
          size="medium"
          variant="outlined"
        />
      </MarginColumnFlex>
      <MarginColumnFlex>
        <FormLabel htmlFor="password">Password</FormLabel>
        <TextField
          type="password"
          placeholder="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          size="medium"
          variant="outlined"
        />
      </MarginColumnFlex>
      <Box>
        <Button variant="contained" size="large" onClick={executeSignIn}>
          Login
        </Button>
      </Box>
    </FlexGrowCenteredColumn>
  );
}
