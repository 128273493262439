import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useQueryParam } from 'use-query-params';
import {
  DatalakeColumn,
  OrderByDatalakeParam,
  RowData,
} from '../../types/datalake';
import { OrderParam } from '../../types/tables';

interface DatalakeTableHeaderProps {
  columns: readonly DatalakeColumn[];
}

export default function DatalakeTableHeader({
  columns,
}: DatalakeTableHeaderProps) {
  const [order, setOrder] = useQueryParam('order', OrderParam);
  const [orderBy, setOrderBy] = useQueryParam('orderBy', OrderByDatalakeParam);

  const handleRequestSort = (property: keyof RowData | 'action') => {
    if (property !== 'action') {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.id === 'action' ? 'center' : column.align}
            style={{
              minWidth: column.minWidth,
              fontWeight: 'bold',
            }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={() => handleRequestSort(column.id)}
              disabled={column.id === 'action'}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
